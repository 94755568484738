import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//MUI
import { Button } from '@mui/material'

//helpers
import { useQuery } from 'helpers/url'
import api from 'helpers/api'

//hooks
import useFormValidationHook from 'hooks/useFormValidationHook'
import useLoadingHook from 'hooks/useLoadingHook'

//constants
import { API_URL } from 'constants/apiUrls'

//providers
import { useAppContext } from 'providers/App'

//components
import Brand from '../components/Brand'

const Register = () => {
    const app = useAppContext()
    const query = useQuery()
    const validationHook = useFormValidationHook()
    const loadingHook = useLoadingHook()
    const navigate = useNavigate()

    /**
     * Дали формата е за ученик или не
     * @returns {Boolean}
     */
    const isStudentLogin = () => {
        if (query.get('type') === '2') {
            return false
        }

        return true
    }

    useEffect(() => {
        validationHook.handleValidations()._clear()
    }, [isStudentLogin()])

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.target)
        validationHook.handleValidations()._clear()
        loadingHook.handleLoading()._start()
        api.post(API_URL.AUTH.STUDENTS.REGISTER, data)
            .then(() => {
                navigate('/login?type=1')
                app.handleMessage()._success()._show('Вашата регистрация беше завършена успешно!')
            })
            .catch(err => {
                const { errors } = err.response.data
                validationHook.handleValidations()._set()._fields(errors)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <section id="register">
            <div className="wrapper">
                <form
                    className="with-inputs"
                    autoComplete="off"
                    role="presentation"
                    onSubmit={handleSubmit}
                >
                    <h1>
                        Добре дошли!
                    </h1>
                    <p>
                        За да получите достъп до системата, въведете коректно всички данни.
                    </p>
                    <div className="fields">
                        <div className="row">
                            <div className="col">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Име и Фамилия"
                                    autoComplete="off"
                                    className={validationHook.handleValidations()._check('name') ? 'validate' : ''}
                                />
                            </div>
                            <div className="col small">
                                <select
                                    name="class_id"
                                    className={validationHook.handleValidations()._check('class_id') ? 'validate' : ''}
                                    defaultValue="c"
                                >
                                    <option disabled value="c">
                                        Клас
                                    </option>
                                    {app.classesList.map(c =>
                                        <option key={c.id} value={c.id}>
                                            {c.name}
                                        </option>
                                    )}
                                </select>
                            </div>
                            <div className="col small">
                                <select
                                    name="form_id"
                                    className={validationHook.handleValidations()._check('form_id') ? 'validate' : ''}
                                    defaultValue="f"
                                >
                                    <option disabled value="f">
                                        Форма
                                    </option>
                                    {app.formsList.map(c =>
                                        <option key={c.id} value={c.id}>
                                            {c.name}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col">
                            <input
                                type="text"
                                name="email"
                                placeholder="Е-поща"
                                autoComplete="off"
                                className={validationHook.handleValidations()._check('email') ? 'validate' : ''}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="password"
                                name="password"
                                placeholder="Парола"
                                autoComplete="off"
                                role="presentation"
                                className={validationHook.handleValidations()._check('password') ? 'validate' : ''}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="password"
                                name="password_confirmation"
                                placeholder="Повторете паролата"
                                autoComplete="off"
                                role="presentation"
                                className={validationHook.handleValidations()._check('password') ? 'validate' : ''}
                            />
                        </div>
                        <Button
                            type="submit"
                            className={loadingHook.handleLoading()._isLoading() ? 'loading' : ''}
                        >
                            Регистрация
                        </Button>
                    </div>
                    <div className="no-profile">
                        <span>
                            Вече имам акаунт. <Link to={`/login?type=${query.get('type') || 1}`}>Към вход.</Link>
                        </span>
                    </div>
                </form>
            </div>
            <Brand />
        </section>
    )
}

export default Register