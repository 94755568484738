import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { createPortal } from 'react-dom'

//MUI
import { Button, Tooltip } from "@mui/material"

//images
import moveIcon from 'assets/images/admin/students/icons/move.svg'

//helpers
import api from 'helpers/api'

//constants
import { API_URL } from 'constants/apiUrls'

//providers
import { useStudentsDataContext } from '../_providers/Students'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//modals
import Question from 'components/modals/Question'

const MoveStudents = () => {
    const loadingHook = useLoadingHook()
    const studentsData = useStudentsDataContext()
    const location = useLocation()
    const questionModalRef = useRef()

    const handleMoveAll = () => {
        loadingHook.handleLoading()._start()
        api.get(`${API_URL.STATISTICS.STUDENTS.MOVE_ALL}${location.search}`)
            .then(() => {
                studentsData.handleStudents()._refresh()
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <>
            {createPortal(
                <Question
                    ref={questionModalRef}
                    elementsContent={{
                        heading: 'Потвърждавате ли преместването на всички ученици от списъка към по-горен клас?',
                        text: 'Всички ученици от списъка ще бъдат преместени в следващия клас, а тези от 12-ти клас ще бъдат маркирани като завършили.',
                        buttons: {
                            accept: 'Потвърждавам'
                        }
                    }}
                    accept={handleMoveAll}
                    cancel={() => {

                    }}
                />,
                document.body
            )}
            <Tooltip title="Преместване на учениците в следващ клас">
                <Button
                    className={`move left-auto ${loadingHook.handleLoading()._isLoading() ? 'loading' : ''}`}
                    onClick={() => {
                        questionModalRef.current.open()
                    }}
                >
                    <img src={moveIcon} />
                </Button>
            </Tooltip>
        </>
    )
}

export default MoveStudents