import { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//components
import CreateNew from "components/teacher/components/CreateNew"
import TeacherStats from "../../components/TeacherStats"
import List from "./components/List"

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//constats
import { API_URL } from 'constants/apiUrls'

//helpers
import api from 'helpers/api'

//modals
import RemoveTopicModal from 'components/modals/Remove'
import RemoveTestModal from 'components/modals/Remove'

//providers
import { useEducationDataContext } from 'components/teacher/_providers/Education'
import { useAppContext } from 'providers/App'

const Tests = () => {
    const app = useAppContext()
    const removeTopicModalRef = useRef()
    const removeTestModalRef = useRef()
    const loadingHook = useLoadingHook()
    const navigate = useNavigate()
    const params = useParams()
    const educationData = useEducationDataContext()
    const { topicId } = params
    const [state, setState] = useState({
        data: {}
    })

    useEffect(() => {
        getData()
    }, [educationData.refresh])

    useEffect(() => {
        if (Object.keys(educationData.topic.remove).length) removeTopicModalRef.current.open()
        else removeTopicModalRef.current.close()
    }, [educationData.topic.remove])

    useEffect(() => {
        if (Object.keys(educationData.test.remove).length) removeTestModalRef.current.open()
        else removeTestModalRef.current.close()
    }, [educationData.test.remove])

    const getData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.get(`${API_URL.TESTS.GET}?topic_id=${topicId}`)
            .then(res => {
                const { data } = res

                setState(prev => ({
                    ...prev,
                    data
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
                else navigate('/')
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }
    return (
        <section id="teacher-tests">
            <RemoveTopicModal
                ref={removeTopicModalRef}
                elementsContent={{
                    heading: `Искате ли да изтриете "${educationData.topic.remove.name}"?`,
                    buttons: {
                        accept: 'Изтрий темата'
                    }
                }}
                accept={async () => {
                    await educationData.handleTopic()._remove()._process()
                    removeTopicModalRef.current.close()
                    educationData.handleTopic()._remove()._reset()
                    navigate(`/education/${params.educationId}`)
                    app.handleMessage()._success()._show('Темата беше изтрита успешно!')
                }}
                cancel={educationData.handleTopic()._remove()._reset}
            />
            <RemoveTestModal
                ref={removeTestModalRef}
                elementsContent={{
                    heading: `Искате ли да изтриете "${educationData.test.remove.name}"?`,
                    buttons: {
                        accept: 'Изтрий теста'
                    }
                }}
                accept={async () => {
                    await educationData.handleTest()._remove()._process()
                    removeTopicModalRef.current.close()
                    educationData.handleTest()._remove()._reset()
                    app.handleMessage()._success()._show('Теста беше изтрит успешно!')
                }}
                cancel={educationData.handleTest()._remove()._reset}
            />
            {loadingHook.handleLoading()._isLoading() ?
                <>
                    <div className="top-skeleton">
                        <Skeleton />
                    </div>
                    <div className="wrapper bottom">
                        <ul>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </ul>
                    </div>
                </>
                :
                <>
                    <TeacherStats
                        data={state.data}
                        inner={true}
                    />
                    <div className="wrapper bottom">
                        <List
                            data={state.data}
                        />
                    </div>
                </>
            }
            <CreateNew mainIndex={2} />
        </section>
    )
}

export default Tests