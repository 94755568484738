import { useState } from "react"

//providers
import { useStudentsEducationContext } from "components/student/_providers/Education"

const useChangeCourseHook = () => {
    const studentsEducationData = useStudentsEducationContext()
    const [courseId, setCourseId] = useState(studentsEducationData.handleEducation()._get()._selectedId())

    const handleChange = e => {
        const { value } = e.target

        setCourseId(value)
        studentsEducationData.handleEducation()._set()._selectedId(value)
        localStorage.setItem('selectedId', value)
    }
    return ({
        handleChange,
        courseId
    })
}

export default useChangeCourseHook