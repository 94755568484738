//MUI
import { Button } from '@mui/material'

//images
import hideIcon from 'assets/images/teachers/topics/icons/hide.svg'
import showIcon from 'assets/images/teachers/topics/icons/hide.svg'

//hooks
import useVisibilityHook from '../topics/hooks/useVisibilityHook'

const Visibility = ({ currentStatus }) => {
    const {
        visible,
        handleVisibility
    } = useVisibilityHook(currentStatus)

    return (
        <Button
            className="hide"
            onClick={() => {
                handleVisibility()
            }}
        >
            <img src={visible ? hideIcon : showIcon} />
            {visible ? 'Скрий' : 'Покажи'} за учениците
        </Button>
    )
}

export default Visibility