//plugins
import axios from 'axios'

//helpers
import { isProd } from './mode'

const api = axios.create({
    baseURL: `${process.env[isProd ? 'REACT_APP_URL' : 'REACT_APP_URL_DEV']}/api/`
})

api.interceptors.request.use(config => {
    let token
    const { params, headers } = config

    try {
        token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).data?.access_token : ''
    } catch (e) {
        console.error(e)
    }
    if (!params) {
        config.params = {}
    }

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }
    
    return config
})

export default api