import { createContext, useContext, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"

//constants
import { SORT_TYPE } from '../_constants/sortType'

//plugins
import { cloneDeep } from 'lodash'

//constants
import { TEST_TEMPLATES } from "constants/testTemplates"

//helpers
import api from "helpers/api"

//constants
import { API_URL } from "constants/apiUrls"

//providers
import { useAppContext } from "providers/App"
import { MAX_ALLOWED_TABLE_VALUES } from "constants/maxValues"

export const TestDataContext = createContext({})

const EMPTY_TABLE_EXAMPE = {
    rows: {
        count: 0,
    },
    cols: {
        count: 0,
    },
    data: {}
}
const ANSWER_EXAMPLE = {
    id: new Date().getTime(),
    isCorrect: false,
    text: '',
}
const SLIDE_EXAMPLE = {
    id: new Date().getTime(),
    typeId: 1,
    duplicated: false,
    activeTemplateId: Object.keys(TEST_TEMPLATES)[0],
    activeSortTypeId: 1,
    points: 1,
    content: {
        question: '',
        answers: [cloneDeep(ANSWER_EXAMPLE)],
        openAnswer: '',
        table: cloneDeep(EMPTY_TABLE_EXAMPE)
    },
}

const TestDataProvider = props => {
    const params = useParams()
    const navigate = useNavigate()
    const app = useAppContext()
    const [state, setState] = useState({
        name: '',
        topic: '',
        topicId: null,
        questionsCount: 1,
        pointsCount: 1,
        timeInSeconds: null,
        slides: {
            list: [cloneDeep(SLIDE_EXAMPLE)],
            activeIndex: 0
        },
    })

    const handleTest = () => {
        const _setSlides = slides => {
            const list = []
            slides.map(s => {
                //Взима се [0], защото въпроса в нашия случай въпроса не може да има повече от 1 съдърание
                const { content_type_id, value } = s.contents[0]
                if (Array.isArray(value.data)) {
                    s.contents[0].value.data = {}
                }

                list.push({
                    id: s.id,
                    activeTemplateId: s.template_id,
                    activeSortTypeId: s.list_style_type_id,
                    points: s.points,
                    content: {
                        table: content_type_id === 2 ? value : cloneDeep(EMPTY_TABLE_EXAMPE),
                        question: /**когато е таблица, value е object */value?.question || /**когато не е таблица, value е string */value,
                        answers: s.answers.map(a => {
                            a.isCorrect = a.is_correct
                            a.text = a.name

                            return a
                        })
                    }
                })
            })

            handleSlide()._save(list)
        }

        /**
         * 
         * @param {String} name 
         * @param {String} topic 
         * @param {Number} topicId 
         * @param {Number} pointsCount 
         * @param {Number} questionsCount 
         * @param {Number|Null} timeInSeconds
         */
        const _set = (name, topic = '', topicId = null, pointsCount = state.pointsCount, questionsCount = state.questionsCount, timeInSeconds = 0, scoring = []) => {
            setState(prev => ({
                ...prev,
                name,
                topic,
                topicId,
                pointsCount,
                questionsCount,
                timeInSeconds,
                scoring
            }))
        }

        const _setGrades = scoring => {
            setState(prev => ({
                ...prev,
                scoring
            }))
        }

        const _setTimer = timeInSeconds => {
            setState(prev => ({
                ...prev,
                timeInSeconds
            }))
        }

        const _save = async (grade = _get()._grades()) => {
            let slides = []
            getClonedSlidesList().map((s, i) => {
                slides.push({
                    list_style_type_id: s.activeSortTypeId,
                    template_id: s.activeTemplateId,
                    points: s.points,
                    id: s.id,
                    contents: [{
                        type_id: handleActiveTemplate()._get(i).type === 'table-question-open-answer' ? 2 : 1,
                        value: handleActiveTemplate()._get(i)?.type === 'table-question-open-answer' ? { ...s.content.table, question: s.content.question } : s.content.question
                    }],
                    answers: s.content.answers.map(a => {
                        a.is_correct = a.isCorrect
                        a.name = a.text

                        return a
                    })
                })
            })

            let data = {
                max_time: Number(state.timeInSeconds) || null,
                topic_id: params.topicId,
                name: state.name,
                questions: slides,
                test_id: Number(params.testId),
                scoring_type_id: 2,
                scoring: grade,
            }

            await api.post(API_URL.TESTS[Number(params.testId) ? 'EDIT' : 'ADD'], data)
                .then(res => {
                    const { id, scoring } = res.data
                    navigate(`/admin/test/${params.educationId}/${params.topicId}/${id}`)
                    app.handleMessage()._success()._show('Теста беше запазен успешно!')
                    _setGrades(scoring)
                })
                .catch(() => {
                    app.handleMessage()._error()._show('Нещо се обърка!')
                })
        }

        const _get = () => {
            /**
             * 
             * @returns {Number}
             */
            const _questionsCount = () => getClonedSlidesList().length || 0

            /**
             * 
             * @returns {Number}
             */
            const _pointsCount = () => {
                let points = 0

                getClonedSlidesList().map(s => {
                    points += Number(s.points)
                })

                return points
            }

            /**
             * 
             * @returns {Object}
             */

            const _grades = () => {
                let grades = {};

                (state.scoring || []).map(s => {
                    if (Number(s.score) !== 2) {
                        grades = {
                            ...grades,
                            [s.score]: s.points
                        }
                    }
                })

                return grades
            }

            /**
            * @param {Object} grades
            * @returns {Boolean}
            */
            const _areGradesMissing = (grades = _grades()) => {
                const scoring = Object.values(grades)
                if (!scoring.length) {
                    return true
                }
                let missing = false
                scoring.map(s => {
                    if (!String(s).length) {
                        missing = true
                    }
                })

                return missing
            }

            return {
                _questionsCount,
                _pointsCount,
                _grades,
                _areGradesMissing
            }
        }

        return {
            _set,
            _save,
            _setSlides,
            _get,
            _setTimer,
            _setGrades
        }
    }

    /**
     * Връща клониран списък на слайдовете.
     * Използва се, за да не се променя state при директна мнипулация със масива 
     * @returns {Array}
     */
    const getClonedSlidesList = () => cloneDeep(state.slides.list)

    /**
     * Връща функции манипулиращи активния слайд
     * @returns {Object}
     */
    const handleActiveSlide = () => {
        /**
         * Задава активния слайд
         * @param {Number} activeIndex 
         * @returns {undefined|VoidFunction}
         */
        const _set = activeIndex => {
            if (state.slides.activeIndex === activeIndex) return
            setState(prev => ({
                ...prev,
                slides: {
                    ...prev.slides,
                    activeIndex
                }
            }))
        }

        /**
         * Съдъжа _data и _index
         * @param {Number|Null} index
         * @returns {Object}
         */
        const _get = () => {
            /**
             * Връща данните на активния слайд
             * @returns {Object}
             */
            const _data = (index = null) => getClonedSlidesList()[index !== null ? String(index) : _index()]

            /**
             * Връща индекса на активняи слайд
             * @returns {Number}
             */
            const _index = () => state.slides.activeIndex

            /**
             * Връща номерацията на въпроса спрямо избрания тип
             * @param {Number} i 
             * @returns {String}
             */
            const _sortType = i => SORT_TYPE[handleActiveSortType()._get()].code[i]

            return {
                _data,
                _index,
                _sortType
            }
        }

        return {
            _set,
            _get
        }
    }
    /**
     * Връща функции манипулиращи типа шаблони
     * @returns {Object}
     */
    const handleActiveTemplate = () => {
        /**
        * @param {String|Number}
        * @returns {Object}
        */
        const _get = (slideId = handleActiveSlide()._get()._index()) => TEST_TEMPLATES[getClonedSlidesList()[slideId].activeTemplateId]

        /**
         * 
         * @param {Number} templateId 
         */
        const _set = templateId => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (handleActiveSlide()._get()._index() === i) {
                    s.activeTemplateId = templateId
                }

                return s
            })

            handleSlide()._save(updatedList)
        }

        return {
            _set,
            _get
        }
    }

    /**
     * Връща функции манипулиращи типа номерация на отговорите
     * @returns {Object}
     */
    const handleActiveSortType = () => {
        /**
         * Връща активния тип номеиране на отговорите
         * @param {String|Number} slideId 
         * @returns {Number}
         */
        const _get = (slideId = handleActiveSlide()._get()._index()) => getClonedSlidesList()[slideId].activeSortTypeId

        /**
         * 
         * @param {String|Number} sortTypeId 
         */
        const _set = sortTypeId => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (handleActiveSlide()._get()._index() === i) {
                    s.activeSortTypeId = sortTypeId
                }

                return s
            })

            handleSlide()._save(updatedList)
        }

        return {
            _get,
            _set
        }
    }

    /**
     * Всички опрации за слайдовете
     * @returns {Object}
     */
    const handleSlide = () => {
        /**
        * 
        * @param {Array} list 
        */
        const _save = list => {
            setState(prev => ({
                ...prev,
                slides: {
                    ...prev.slides,
                    list
                }
            }))
        }

        const _add = () => {
            setState(prev => ({
                ...prev,
                slides: {
                    ...prev.slides,
                    list: [
                        ...prev.slides.list,
                        {
                            ...cloneDeep(SLIDE_EXAMPLE),
                            //Да се махне, когато се работи с реални данни
                            id: new Date().getTime()
                        }
                    ],
                }
            }))
        }

        /**
         * 
         * @param {Number} index 
         */
        const _delete = index => {
            handleActiveSlide()._set(index === 0 ? index : index - 1)
            const updatedList = getClonedSlidesList().filter((_, i) => Number(index) != Number(i))

            _save(updatedList)
        }

        /**
         * 
         * @param {Number} index 
         */
        const _duplicate = index => {
            //[!] Ако някога някъде се счпуи някой масив ДА СЕ ПРОВЕРИ ТУК [!]
            Array.prototype.insert = function (index, ...items) {
                this.splice(index, 0, ...items)
            }
            //[!] КРАЙ [!]

            const updatedList = getClonedSlidesList()
            const element = {
                ...updatedList[index],
                id: new Date().getTime(),
                duplicated: true
            }
            updatedList.insert(index + 1, element)

            _save(updatedList)
        }

        /**
         * 
         * @param {Object} e 
         */
        const _setPoints = e => {
            const { value } = e.target
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (i === handleActiveSlide()._get()._index()) {
                    s.points = value <= 0 ? 1 : value
                }

                return s
            })

            _save(updatedList)
        }

        /**
         * Запазва данните на слайда в контекст
         * @param {String} name 
         * @param {String} value 
         */
        const _set = (name, value) => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (handleActiveSlide()._get()._index() === i) {
                    s.content[name] = value
                }

                return s
            })

            _save(updatedList)
        }

        return {
            _add,
            _delete,
            _duplicate,
            _set,
            _save,
            _setPoints
        }
    }

    /**
     * 
     * @returns {Boolean}
     */
    const isSlideDeletable = () => {
        if (getClonedSlidesList().length > 1) {
            return true
        }

        return false
    }

    const handleAnswer = () => {
        const _add = () => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (handleActiveSlide()._get()._index() === i) {
                    s.content.answers.push({
                        ...cloneDeep(ANSWER_EXAMPLE),
                        id: new Date().getTime(),
                    })
                }

                return s
            })

            handleSlide()._save(updatedList)
        }

        /**
         * Премахва отговор от списъка
         * @param {Number} index 
         */
        const _delete = index => {
            const updatedAnswers = handleActiveSlide()._get()._data().content.answers.filter((_, i) => i != index)
            _set(updatedAnswers)
        }

        /**
         * Задава верен/грешен въпрос
         * @param {Boolean} value 
         * @param {Number} index
         */
        const _setValue = (value, index) => {
            const updatedAnswers = handleActiveSlide()._get()._data().content.answers.map((a, i) => {
                if (i === index) {
                    a.isCorrect = value
                }

                return a
            })

            _set(updatedAnswers)
        }

        /**
         * Задава съдържание на отговора
         * @param {String} value 
         * @param {Number} index 
         */
        const _setContent = (value, index) => {
            const updatedAnswers = handleActiveSlide()._get()._data().content.answers.map((a, i) => {
                if (i === index) {
                    a.text = value
                }

                return a
            })

            _set(updatedAnswers)
        }

        /**
         * Замества актуализирания списък с отговори с текущия
         * @param {Array} answers 
         */
        const _set = answers => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (i === handleActiveSlide()._get()._index()) {
                    s.content.answers = answers
                }

                return s
            })

            handleSlide()._save(updatedList)
        }

        return {
            _add,
            _delete,
            _setValue,
            _setContent
        }
    }

    const handleTable = () => {
        const _get = () => {
            /**
             * Преобразува броя добавени колони в масив с празни елементи, за да моге да бъде обходен в таблицата
             * @returns {Array}
             */
            const _cols = index => {
                let arr = []
                for (let i = 0; i < _colsCount(index); i++) {
                    arr = [...arr, '']
                }

                return arr
            }

            /**
             * Връща броя добавени колони
             * @returns {Number}
             */
            const _colsCount = index => handleActiveSlide()._get()._data(index).content.table.cols.count || 0

            /**
             * Преобразува броя добавени редове в масив с празни елементи, за да моге да бъде обходен в таблицата
             * @returns {Array}
             */
            const _rows = index => {
                let arr = []
                for (let i = 0; i < _rowsCount(index); i++) {
                    arr = [...arr, '']
                }

                return arr
            }

            /**
             * Връща броя добавени редове
             * @returns {Number}
             */
            const _rowsCount = index => handleActiveSlide()._get()._data(index).content.table.rows.count || 0

            /**
             * Връща данните за съответната клетка в таблицата
             * @param {String} key 
             * @returns {String}
             */
            const _data = (key, index) => handleActiveSlide()._get()._data(index).content.table.data[key] || ''

            return {
                _cols,
                _colsCount,
                _rows,
                _rowsCount,
                _data
            }
        }

        const _set = () => {
            const _matrix = e => {
                const { name, value } = e.target
                if (value > MAX_ALLOWED_TABLE_VALUES.rows) return
                const { data } = handleActiveSlide()._get()._data().content.table
                let allowUpdate = true
                let dataExeptRemoved = {}
                let hasDataInRows, hasDataInCols = false

                //Проверява дали има данни в ред/колна, който ще бъде премахната
                //Задава въпрос дали да се продължи с действието
                //Премахва/не премахва редове колони
                //Данните се записват и проверяват - n редове * m колони
                if (_get()._rowsCount() >= value || _get()._colsCount() >= value) {
                    switch (name) {
                        case 'rows':
                            Object.keys(data).map((d, i) => {
                                if (d.includes(`${value}-`) && Object.values(data)[i].length) {
                                    hasDataInRows = true
                                } else {
                                    dataExeptRemoved = {
                                        ...dataExeptRemoved,
                                        [d]: Object.values(data)[i]
                                    }
                                }
                            })
                            if (hasDataInRows) {
                                if (!window.confirm(`Внимание! Данните от ред A${_get()._rowsCount()} ще бъдат изгубени`)) {
                                    allowUpdate = false
                                }
                            }
                            break

                        case 'cols':
                            Object.keys(data).map((d, i) => {
                                if (d.includes(`-${value}`) && Object.values(data)[i].length) {
                                    hasDataInCols = true
                                } else {
                                    dataExeptRemoved = {
                                        ...dataExeptRemoved,
                                        [d]: Object.values(data)[i]
                                    }
                                }
                            })
                            if (hasDataInCols) {
                                if (!window.confirm(`Внимание! Данните от колона B${_get()._colsCount()} ще бъдат изгубени`)) {
                                    allowUpdate = false
                                }
                            }
                            break
                    }
                }

                //Проверява дали е дадено съгласие за промяна на редове/колони, ако в тях има данни
                if (allowUpdate) {
                    const updatedList = getClonedSlidesList().map((s, i) => {
                        if (i === handleActiveSlide()._get()._index()) {
                            s.content.table[name].count = value <= 0 ? 0 : value
                            if (hasDataInRows || hasDataInCols) {
                                s.content.table.data = dataExeptRemoved
                            }

                        }

                        return s
                    })

                    handleSlide()._save(updatedList)
                }
            }

            const _data = e => {
                const { name, value } = e.target

                const updatedList = getClonedSlidesList().map((s, i) => {
                    if (i === handleActiveSlide()._get()._index()) {
                        s.content.table.data[name] = value
                    }

                    return s
                })

                handleSlide()._save(updatedList)
            }

            return {
                _matrix,
                _data
            }
        }

        return {
            _get,
            _set
        }
    }

    const exportedData = {
        ...state,
        handleActiveTemplate,
        handleSlide,
        isSlideDeletable,
        handleActiveSlide,
        handleActiveSortType,
        handleAnswer,
        handleTable,
        handleTest
    }

    return <TestDataContext.Provider value={exportedData} {...props} />
}

export const useTestDataContext = () => useContext(TestDataContext)

export default TestDataProvider