import { Link, useParams } from "react-router-dom"

//components
import TestMenu from "./TestMenu"

//images
import correctIcon from 'assets/images/students/tests/icons/correct.svg'
import testIcon from 'assets/images/teachers/education/icons/test.svg'
import timeIcon from 'assets/images/students/tests/icons/time.svg'

//helpers
import { convertSecondsToMinutes } from "helpers/convertSeconds"
import { cutText } from "helpers/test"

const Item = ({ data, index }) => {
    const params = useParams()
    const {
        name,
        max_time,
        questions_count,
        id
    } = data

    return (
        <li>
            <TestMenu
                topicId={params.topicId}
                educationId={params.educationId}
                testId={id}
                data={data}
            />
            <Link to={`/test/${id}`}>
                <b>
                    Тест {index + 1}
                </b>
                <div className="test-icon">

                </div>
                <p>
                    {cutText(name, 25)}
                </p>
                {/* Когато теста не е решаван, трябва да се скрие списъка с резултати */}
                <ul className="results">
                    <li>
                        <span>
                            <img src={testIcon} />
                        </span>
                        {questions_count}
                    </li>
                    {max_time ?
                        <li>
                            <span>
                                <img src={timeIcon} />
                            </span>
                            {convertSecondsToMinutes(max_time).fullMinutes}:{convertSecondsToMinutes(max_time).fullSeconds} мин.
                        </li>
                        :
                        <>
                        </>
                    }
                </ul>
            </Link>
        </li>
    )
}

export default Item