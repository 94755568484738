import { useState, useEffect } from 'react'

//MUI
import { Skeleton } from '@mui/material'

//plugins
import { PieChart } from 'react-minimal-pie-chart'

//constants
import { RED_COLOR, GREEN_COLOR } from '../../topics/constants/colors'

//components
import EducationContentIndicator from 'components/teacher/education/components/EducationContentIndicator'

//helpers
import api from 'helpers/api'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//constants
import { API_URL } from 'constants/apiUrls'

const EducationsList = () => {
    const loadingHook = useLoadingHook(true)
    const [state, setState] = useState({
        data: []
    })

    useEffect(() => {
        getData()
    }, [])

    const getData = (counter = 1) => {
        api.get(API_URL.STUDENTS.SUBJECTS2)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }
    return (
        <ul className="educations">
            {loadingHook.handleLoading()._isLoading()
                ?
                <Skeleton />
                :
                !state.data.length ?
                    <p className="no-data">
                        Все още не сте част от обучение.
                    </p>
                    :
                    state.data.map(d =>
                        <li key={`educations-${d.id}`}>
                            <div className="top">
                                <div className="left">
                                    <EducationContentIndicator content="both" />
                                    <h4>
                                        {d.name}
                                    </h4>
                                </div>
                                <div className="right">
                                    <PieChart
                                        animate={true}
                                        data={[
                                            { value: d.result?.percent_tests_quality || 0, color: GREEN_COLOR },
                                            { value: 100 - d.result?.percent_tests_quality || 0.1, color: RED_COLOR },
                                        ]}
                                        startAngle={90}
                                        lineWidth={40}
                                    />
                                    <div className="results">
                                        <b>
                                            {d.result?.percent_tests_quality || 0}%
                                        </b>
                                        <div className="indicator">
                                            <span style={{ backgroundColor: GREEN_COLOR }}>

                                            </span>
                                            Успешни резултати
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="progress" style={{ width: `${d.result?.percent_lectures_completed || 0}%` }}>
                                <span>
                                    {d.result?.percent_lectures_completed || 0}%
                                </span>
                            </div>
                        </li>
                    )
            }
        </ul>
    )
}

export default EducationsList