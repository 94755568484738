import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useParams } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//components
import Aside from "./components/Aside"
import Header from "./components/Header"
import Main from "./components/Main"

//modals
import CreateModal from "../modals/topic/Create"

//helpers
import api from 'helpers/api'

//providers
import { useTopicDataContext } from './_providers/Topic'

//constants
import { API_URL } from 'constants/apiUrls'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

const Topic = () => {
    const params = useParams()
    const createModalRef = useRef()
    const topicData = useTopicDataContext()
    const topicId = Number(params.topicId)
    const loadingHook = useLoadingHook()

    useEffect(() => {
        if (!topicId) createModalRef.current.open()
        else getData()
    }, [])

    const getData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.post(`${API_URL.LECTURES.SHOW}`, {
            id: topicId
        })
            .then(res => {
                const { name, no, image_url, lectures } = res.data
                topicData.handleTopic()._set(name, no, image_url)
                topicData.handleTopic()._setSlides(lectures)
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <section id="admin-topic">
            {!topicId ?
                createPortal(<CreateModal ref={createModalRef} />, document.body)
                :
                <>
                </>
            }
            {loadingHook.handleLoading()._isLoading() ?
                <div className="full-screen">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton className="big" />
                </div>
                :
                <>
                    <Header />
                    <div className="content">
                        <Aside />
                        <Main />
                    </div>
                </>
            }
        </section>
    )
}

export default Topic