//MUI
import { Button, MenuItem, Select, Tooltip } from '@mui/material'

//constatns
import { TEST_TEMPLATES } from 'constants/testTemplates'
import { SORT_TYPE } from '../_constants/sortType'
import { MAX_ALLOWED_TABLE_VALUES } from 'constants/maxValues'

//images
import viewIcon from 'assets/images/admin/topic/icons/view.svg'
import duplicateIcon from 'assets/images/admin/topic/icons/duplicate-dark.svg'
import deleteIcon from 'assets/images/admin/topic/icons/delete.svg'

//providers
import { useTestDataContext } from '../_providers/Test'

//components
import Question from './Question'
import Answers from './Answers'
import Table from './Table'

const Main = () => {
    const testData = useTestDataContext()
    const {
        handleSlide,
        isSlideDeletable,
        handleActiveSortType,
        handleActiveTemplate,
        handleActiveSlide,
        handleTable
    } = testData

    /**
     * Връща всички шаблони като масив
     * @returns {Array}
     */
    const getTopicTemplats = () => Object.values(TEST_TEMPLATES)

    /**
     * Връща всички типове номериране на отговорите
     * @returns {Array}
     */
    const getSortTypes = () => Object.values(SORT_TYPE)

    const handleChangeSortType = e => {
        handleActiveSortType()._set(e.target.value)
    }

    const getTemplate = () => {
        switch (handleActiveTemplate()._get().type) {
            case 'question-answers':
                return <>
                    <div className="question">
                        <Question />
                    </div>
                    <Answers />
                </>
            case 'question-open-answer':
                return <>
                    <div className="question">
                        <Question />
                    </div>
                </>
            case 'table-question-open-answer':
                return <>
                    <div className="question">
                        <Question />
                    </div>
                    <div className="bottom">
                        <div className="table">
                            <Table />
                        </div>
                        <div className="answers">
                            <Answers />
                        </div>
                    </div>
                </>
        }
    }

    return (
        <div className="main">
            <div className="options">
                <div className="left">
                    <p>
                        Шаблони на въпроса
                    </p>
                    <ul className="templates">
                        {getTopicTemplats().map((t, i) =>
                            <li key={`template-mini-${i}`}>
                                <Tooltip title={t.description}>
                                    <Button
                                        className={handleActiveTemplate()._get()?.type === t?.type ? 'active' : ''}
                                        onClick={() => {
                                            handleActiveTemplate()._set(Object.keys(TEST_TEMPLATES)[i])
                                        }}
                                    >
                                        <img src={t.icon} />
                                    </Button>
                                </Tooltip>
                            </li>
                        )}
                    </ul>
                    <ul className="additional-options">
                        <li>
                            <label htmlFor="points">
                                Точки
                            </label>
                            <input
                                type="number"
                                id="points"
                                min="1"
                                value={handleActiveSlide()._get()._data().points}
                                pattern="[0-9]"
                                onChange={handleSlide()._setPoints}
                            />
                        </li>
                        <li>
                            <label htmlFor="sort-type">
                                Номерация
                            </label>
                            <Select
                                value={handleActiveSortType()._get()}
                                name="sort-type"
                                id="sort-type"
                                onChange={handleChangeSortType}
                            >
                                {getSortTypes().map((s, i) =>
                                    <MenuItem
                                        key={`sort-type-${i}`}
                                        value={Object.keys(SORT_TYPE)[i]}
                                    >
                                        {s.sample}
                                    </MenuItem>
                                )}
                            </Select>
                        </li>
                        {handleActiveTemplate()._get().type === 'table-question-open-answer' ?
                            <li className="size">
                                <Tooltip title={`*Максималния брой редове е ${MAX_ALLOWED_TABLE_VALUES.rows}`}>
                                    <div className="col">
                                        <label htmlFor="rows">
                                            Редове
                                        </label>
                                        <input
                                            type="number"
                                            name="rows"
                                            id="rows"
                                            onChange={handleTable()._set()._matrix}
                                            value={handleTable()._get()._rowsCount()}
                                            step="1"
                                        />
                                    </div>
                                </Tooltip>
                                <span>
                                    x
                                </span>
                                <Tooltip title={`*Максималния брой колони е ${MAX_ALLOWED_TABLE_VALUES.cols}`}>
                                    <div className="col">
                                        <label htmlFor="cols">
                                            Колони
                                        </label>

                                        <input
                                            type="number"
                                            name="cols"
                                            id="cols"
                                            onChange={handleTable()._set()._matrix}
                                            value={handleTable()._get()._colsCount()}
                                            step="1"
                                        />
                                    </div>
                                </Tooltip>
                            </li>
                            :
                            <>
                            </>
                        }
                    </ul>
                </div>
                <ul className="right">
                    {/* <li>
                        <Tooltip title="Бърз преглед">
                            <Button>
                                <img src={viewIcon} />
                            </Button>
                        </Tooltip>
                    </li> */}
                    <li>
                        <Tooltip title="Дублиране на въпроса">
                            <Button
                                onClick={() => {
                                    handleSlide()._duplicate(handleActiveSlide()._get()._index())
                                }}
                            >
                                <img src={duplicateIcon} />
                            </Button>
                        </Tooltip>
                    </li>
                    {isSlideDeletable() ?
                        <li>
                            <Tooltip title="Премахване на въпроса">
                                <Button
                                    className="delete"
                                    onClick={() => {
                                        handleSlide()._delete(handleActiveSlide()._get()._index())
                                    }}
                                >
                                    <img src={deleteIcon} />
                                </Button>
                            </Tooltip>
                        </li>
                        :
                        <>
                        </>
                    }
                </ul>
            </div>
            <div className={`content ${handleActiveTemplate()._get().type}`}>
                {getTemplate()}
            </div>
        </div >
    )
}

export default Main