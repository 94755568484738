import { createContext, useContext, useState, useEffect, useMemo } from "react"
import { useLocation } from 'react-router-dom'

//helpers
import api from "helpers/api"
import { useQuery } from "helpers/url"

//constants
import { API_URL } from "constants/apiUrls"

//hooks
import useLoadingHook from "hooks/useLoadingHook"
import useSetParamsHook from "hooks/useSetParamsHook"

export const StudentsDataContext = createContext({})

const StudentsDataProvider = props => {
    const location = useLocation()
    const loadingHook = useLoadingHook()
    const query = useQuery()
    const useSetParams = useSetParamsHook()

    const [state, setState] = useState({
        data: {},
        filter: {
            search: query.get('search') || '',
            form_id: query.get('form_id') || '',
            class_id: query.get('class_id') || '',
            page: query.get('page') || '1',
        },
        refresh: null,
        remove: {},
        edit: {}
    })

    useEffect(() => {
        useSetParams.apply(state.filter)
    }, [state.filter, state.page])

    /**
     * 
     * @param {String} page 
     */
    const setPage = page => {
        setState(prev => ({
            ...prev,
            page
        }))
    }

    const handleStudents = () => {
        const _fetch = () => {
            loadingHook.handleLoading()._start()
            api.get(`${API_URL.STATISTICS.STUDENTS.GET}${location.search}`)
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        data: res.data
                    }))
                })
                .finally(() => {
                    loadingHook.handleLoading()._stop()
                })
        }

        const _set = () => {
            const _filter = (name, value) => {
                if (name !== 'page') {
                    setState(prev => ({
                        ...prev,
                        filter: {
                            ...prev.filter,
                            page: 1,
                        }
                    }))
                }
                setState(prev => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        [name]: value
                    }
                }))
            }

            const _clearFilter = () => {
                setState(prev => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        search: '',
                        form_id: '',
                        class_id: '',
                        page: '1',
                    }
                }))
                document.querySelector('input[name="search"]').value = ''
            }

            return {
                _filter,
                _clearFilter
            }
        }

        const _get = () => {
            const _list = () => state.data?.items || []

            return {
                _list
            }
        }

        const _remove = () => {
            /**
             *
             * @param {Object} remove 
             */
            const _prepare = remove => {
                setState(prev => ({
                    ...prev,
                    remove
                }))
            }

            const _process = async () => {
                const { id } = state.remove
                await api.post(API_URL.STATISTICS.STUDENTS.DELETE, {
                    id
                })
                    .then(() => {
                        _refresh()
                    })
            }

            const _reset = () => {
                _prepare({})
            }

            return {
                _prepare,
                _process,
                _reset
            }
        }

        const _refresh = () => {
            setState(prev => ({
                ...prev,
                refresh: new Date().getTime()
            }))
        }

        const _edit = () => {
            /**
             *
             * @param {Object} remove 
             */
            const _prepare = edit => {
                setState(prev => ({
                    ...prev,
                    edit
                }))
            }

            const _reset = () => {
                _prepare({})
            }

            return {
                _prepare,
                _reset
            }
        }

        return {
            _fetch,
            _get,
            _set,
            _remove,
            _edit,
            _refresh
        }
    }

    const exportedData = {
        ...state,
        setPage,
        handleStudents,
        isLoading: loadingHook.handleLoading()._isLoading()
    }

    return <StudentsDataContext.Provider value={exportedData} {...props} />
}

export const useStudentsDataContext = () => useContext(StudentsDataContext)

export default StudentsDataProvider