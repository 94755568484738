import { useNavigate, useParams } from 'react-router-dom'

//MUI
import { Menu, MenuItem } from '@mui/material'

//providers
import { useEducationDataContext } from '../_providers/Education'

//hooks
import useMenuHook from 'hooks/useMenuHook'

//images
import editIcon from 'assets/images/teachers/education/icons/edit.svg'
import topicIcon from 'assets/images/teachers/education/icons/topic.svg'
import testIcon from 'assets/images/teachers/education/icons/test.svg'
import deleteIcon from 'assets/images/teachers/education/icons/delete.svg'

const EducationMenu = ({ data }) => {
    const navigate = useNavigate()
    const params = useParams()
    const educationData = useEducationDataContext()
    const educationController = educationData.handleEducation()
    const topicId = Number(params.topicId)
    const {
        opened,
        anchorEl,
        setAnchorEl
    } = useMenuHook()

    const handleOpenMenu = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        if (anchorEl) setAnchorEl(null)
    }
    const handleEdit = () => {
        educationController._edit()._prepare(data)
        handleCloseMenu()
    }

    const handleRemove = () => {
        educationController._remove()._prepare(data)
        handleCloseMenu()
    }

    const handleAddTest = () => {
        // navigate(`/admin/test/${data.id}/null/null`)
        educationData.handleTopic()._addTest(data.id, null)
        handleCloseMenu()
    }

    const handleAddTopic = () => {
        navigate(`/admin/topic/${data.id}/${null}`)
        handleCloseMenu()
    }

    return (
        <>
            <span
                className="education-menu"
                onClick={handleOpenMenu}
            >

            </span>
            <Menu
                anchorEl={anchorEl}
                open={opened}
                onClose={handleCloseMenu}
                className="education-menu"
            >
                <MenuItem
                    onClick={handleEdit}
                >
                    <img src={editIcon} />
                    Редакция
                </MenuItem>
                <MenuItem
                    onClick={handleAddTopic}
                >
                    <img src={topicIcon} />
                    Добави тема
                </MenuItem>
                {data.topics_count ?
                    <MenuItem
                        onClick={handleAddTest}
                    >
                        <img src={testIcon} />
                        Добави тест към тема
                    </MenuItem>
                    :
                    ''
                }
                <hr />
                <MenuItem
                    onClick={handleRemove}
                    className="delete"
                >
                    <img src={deleteIcon} />
                    Изтриване на обучението
                </MenuItem>
            </Menu>
        </>
    )
}

export default EducationMenu