import { forwardRef, useImperativeHandle, useState } from 'react'

//MUI
import { Button, Select, Tooltip, MenuItem, Checkbox, Radio, FormLabel, FormControlLabel } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'

//providers
import { useTestDataContext } from 'components/admin/test/_providers/Test'
import useLoadingHook from 'hooks/useLoadingHook'

const Grade = ({ }, ref) => {
    const loadingHook = useLoadingHook()
    const [state, setState] = useState({
        3: '',
        4: '',
        5: '',
        6: ''
    })
    const [saveTestMode, setSaveTestMode] = useState(false)

    const testData = useTestDataContext()
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()
    useImperativeHandle(ref, () => ({
        open: (saveTestMode = false) => {
            if (saveTestMode) setSaveTestMode(saveTestMode)
            handleModal()._show()
            setState(testData.handleTest()._get()._grades())
        },
        close: () => {
            handleModal()._hide()
        }
    }))

    const handleChange = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSave = () => {
        if (saveTestMode) {
            loadingHook.handleLoading()._start()
            testData.handleTest()._save(state)
                .finally(() => {
                    loadingHook.handleLoading()._stop()
                    handleModal()._hide()
                })
        } else {
            handleModal()._hide()
            let gradeAsArray = []

            Object.keys(state).map((s, i) => {
                gradeAsArray = [
                    ...gradeAsArray,
                    {
                        score: s,
                        points: Object.values(state)[i]
                    }
                ]
            })
            testData.handleTest()._setGrades(gradeAsArray)
        }
    }

    return (
        <div className={`overlay grade ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <div className={`modal ${modal.box ? 'show' : ''}`}>
                <div className="body">
                    <b>
                        Критерии за оценка
                    </b>
                    <div className="row">
                        <span>Среден 3</span>: от <input type="number" name="3" onChange={handleChange} value={state['3']} autoFocus /> точки
                    </div>
                    <div className="row">
                        <span>Добър 4</span>: от <input type="number" name="4" onChange={handleChange} value={state['4']} /> точки
                    </div>
                    <div className="row">
                        <span>Мн. добър 5</span>: от <input type="number" name="5" onChange={handleChange} value={state['5']} /> точки
                    </div>
                    <div className="row">
                        <span>Отличен 6</span>: над <input type="number" name="6" onChange={handleChange} value={state['6']} /> точки
                    </div>
                    <div className="bottom">
                        Общо точки в теста: <span>{testData.handleTest()._get()._pointsCount()}</span>
                    </div>
                </div>
                <div className="footer">
                    <Button
                        className="secondary"
                        onClick={handleModal()._hide}
                    >
                        Назад
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={testData.handleTest()._get()._areGradesMissing(state)}
                        className={`${testData.handleTest()._get()._areGradesMissing(state) ? 'disabled' : ''} ${loadingHook.handleLoading()._isLoading() ? 'loading' : ''}`}
                    >
                        Продължете
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Grade)