//images
import template1Image from 'assets/images/admin/topic/icons/template-1.svg'
import template2Image from 'assets/images/admin/topic/icons/template-2.svg'
import template3Image from 'assets/images/admin/topic/icons/template-3.svg'
import template4Image from 'assets/images/admin/topic/icons/template-4.svg'
import template5Image from 'assets/images/admin/topic/icons/template-5.svg'

export const TOPIC_TEMPLATES = {
    1: {
        type: 'heading-text',
        icon: template1Image,
        description: 'Заглавие и текст',
    },
    2: {
        type: 'heading-images-text',
        icon: template2Image,
        description: 'Заглавие, изображения и текст',
    },
    3: {
        type: 'heading-image-text',
        icon: template3Image,
        description: 'Изображение, заглавие и текст',
    },
    4: {
        type: 'images-text',
        icon: template4Image,
        description: 'Изображения и текст',
    },
    5: {
        type: 'text',
        icon: template5Image,
        description: 'Текст',
    }
}