import { forwardRef, useImperativeHandle } from 'react'

//MUI
import { Button } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'
import useLoadingHook from 'hooks/useLoadingHook'

//images
import deleteIcon from 'assets/images/modals/icons/delete.svg'

const RemoveModal = ({ accept, cancel, elementsContent }, ref) => {
    const loadingHook = useLoadingHook()
    const {
        buttons,
        heading,
        text
    } = elementsContent || { buttons: {}, heading: null, text: null }
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()

    useImperativeHandle(ref, () => ({
        open: () => {
            handleModal()._show()
        },
        close: () => {
            handleModal()._hide()
            loadingHook.handleLoading()._stop()
        }
    }))

    const handleAccept = () => {
        loadingHook.handleLoading()._start()
        accept()
    }

    const handleCancel = () => {
        handleModal()._hide()
        cancel()
    }

    return (
        <div className={`overlay delete ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <div className={`modal ${modal.box ? 'show' : ''}`}>
                <div className="body">
                    <img src={deleteIcon} className="main" />
                    <b>
                        {heading || 'Изтриване'}
                    </b>
                    <p>
                        {text || 'Предприетите от Вас действия може да са необратими.'}
                    </p>
                </div>
                <div className="footer">
                    <Button
                        className="secondary"
                        onClick={handleCancel}
                    >
                        {buttons.cancel || 'Отказ'}
                    </Button>
                    <Button
                        onClick={handleAccept}
                        className={loadingHook.handleLoading()._isLoading() ? 'loading' : ''}
                    >
                        {buttons.accept || 'Изтриване'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(RemoveModal)