import { forwardRef, useImperativeHandle } from 'react'

//MUI
import { Button } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'

//constants
import { API_URL } from 'constants/apiUrls'

//helpers
import api from 'helpers/api'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'
import useFormValidationHook from 'hooks/useFormValidationHook'

//providers
import { useAppContext } from 'providers/App'
import { useTeachersDataContext } from '../teachers/_providers/Teachers'

const AddEditTeacher = ({ }, ref) => {
    const teacherData = useTeachersDataContext()
    const loadingHook = useLoadingHook()
    const validationHook = useFormValidationHook()
    const app = useAppContext()
    const data = teacherData.edit
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()

    useImperativeHandle(ref, () => ({
        open: () => {
            handleModal()._show()
        },
        close: () => {
            handleModal()._hide()
        }
    }))

    const handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        validationHook.handleValidations()._clear()
        loadingHook.handleLoading()._start()
        if (data.id) formData.append('id', data.id)

        api.post(API_URL.STATISTICS.TEACHERS[data.id ? 'EDIT' : 'ADD'], formData)
            .then(() => {
                teacherData.handleTeachers()._refresh()
                app.handleMessage()._success()._show('Преподавателят беше регистриан успешно!')
                handleModal()._hide()
            })
            .catch(err => {
                const { errors } = err.response?.data || {}
                if (Object.keys(errors || {}).length) validationHook.handleValidations()._set()._fields(errors)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <div className={`overlay add-edit-student ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <form
                className={`modal ${modal.box ? 'show' : ''}`}
                autoComplete="off"
                role="presentation"
                onSubmit={handleSubmit}
            >
                <div className="body with-inputs">
                    <b>
                        {data.id ? 'Редакция на преподавател' : 'Добавяне на нов преподавател'}
                    </b>
                    <p>
                        Управлявайте личните данни и данните за вход на преподавателите.
                    </p>
                    <div className="fields">
                        <h5>
                            Лични данни
                        </h5>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="name">
                                    Име
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    placeholder="Име и Фамилия"
                                    autoComplete="off"
                                    className={validationHook.handleValidations()._check('name') ? 'validate' : ''}
                                    defaultValue={data.name}
                                />
                            </div>
                        </div>
                        <h5>
                            Данни за вход
                        </h5>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="email">
                                    Е-поща
                                </label>
                                <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    placeholder="Е-поща"
                                    autoComplete="off"
                                    className={validationHook.handleValidations()._check('email') ? 'validate' : ''}
                                    defaultValue={data.email}
                                />
                                {validationHook.handleValidations()._check('email') ?
                                    <span className="message">
                                        *{validationHook.handleValidations()._getMessage('email')}
                                    </span>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                            <div className="col">
                                <label htmlFor="password">
                                    {data.id ? 'Нова парола' : 'Парола'}
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Парола"
                                    autoComplete="off"
                                    role="presentation"
                                    className={validationHook.handleValidations()._check('password') ? 'validate' : ''}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="password_confirmation">
                                    Повтаряне на {data.id ? 'Новата парола' : 'паролата'}
                                </label>
                                <input
                                    type="password"
                                    name="password_confirmation"
                                    placeholder="Повторете паролата"
                                    autoComplete="off"
                                    role="presentation"
                                    className={validationHook.handleValidations()._check('password') ? 'validate' : ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button
                        className="secondary"
                        onClick={() => {
                            handleModal()._hide()
                            if (data.id) {
                                setTimeout(() => {
                                    teacherData.handleTeachers()._edit()._reset()
                                }, 200)
                            }
                        }}
                        disabled={loadingHook.handleLoading()._isLoading()}
                    >
                        Отказ
                    </Button>
                    <Button
                        type="submit"
                        disabled={loadingHook.handleLoading()._isLoading()}
                        className={loadingHook.handleLoading()._isLoading() ? 'loading' : ''}
                    >
                        {data.id ? 'Редакция' : 'Добавяне'}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(AddEditTeacher)