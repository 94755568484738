import { useParams, useNavigate } from 'react-router-dom'

//MUI
import { Button, Tooltip } from '@mui/material'

//pugins
import { PieChart } from 'react-minimal-pie-chart'

//constants
import { RED_COLOR, GREEN_COLOR } from '../topics/constants/colors'

//components
import ChangeCoures from '../topics/components/ChangeCourse'

//images
import backIcon from 'assets/images/students/tests/icons/back.svg'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { useStudentsEducationContext } from '../_providers/Education'

const StudentStats = ({ data }) => {
    const {
        percent_lectures_completed,
        percent_tests_quality,
        result
    } = data

    const authData = useAuthDataContext()
    const userName = authData.handleAuth()._get()._data()?.user?.name || 'Потребител'
    const navigate = useNavigate()
    const params = useParams()
    const topicId = params?.topicId || null
    const studentsEducationData = useStudentsEducationContext()
    const percentTestsQuality = result?.percent_tests_quality || percent_tests_quality || 0
    const loaderPercent = result?.percent_tests_completed || percent_lectures_completed || 0

    return (
        <div className="top">
            <div className="left">
                <h1>
                    {topicId ?
                        <Tooltip title="Назад">
                            <Button
                                onClick={() => {
                                    navigate('/')
                                }}
                            >
                                <img src={backIcon} />
                            </Button>
                        </Tooltip>
                        :
                        <>
                        </>
                    }
                    Резултатите {topicId ? 'за тема 1' : `на ${userName}`}
                </h1>
                {topicId ?
                    <p>
                        Проследете своето развитие, като следите резултатите от направените тестове.
                    </p>
                    :
                    <ChangeCoures
                        data={studentsEducationData.handleEducation()._get()._all()}
                        selectedId={studentsEducationData.handleEducation()._get()._selectedId()}
                    />
                }
                <Tooltip title={`Процент ${topicId ? 'успешно решени тестове' : 'прочетени слайдове от всички теми'}`}>
                    <div className="progress" style={{ width: `${loaderPercent}%` }}>
                        <span>
                            {loaderPercent}%
                        </span>
                    </div>
                </Tooltip>
            </div>
            <div className="right">
                <PieChart
                    animate={true}
                    data={[
                        { value: percentTestsQuality, color: GREEN_COLOR },
                        { value: 100 - percentTestsQuality, color: RED_COLOR },
                    ]}
                    startAngle={90}
                    lineWidth={40}
                />
                <div className="results">
                    <b>
                        {percentTestsQuality || 0}%
                    </b>
                    <div className="indicator">
                        <span style={{ backgroundColor: GREEN_COLOR }}>

                        </span>
                        Успешни резултати
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentStats