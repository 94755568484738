import { useState } from 'react'
import { Link } from 'react-router-dom'

//MUI
import { ClickAwayListener } from '@mui/material'

//images
import testsIcon from 'assets/images/students/topics/icons/tests.svg'
import topicsIcon from 'assets/images/students/topics/icons/topics.svg'
import testIcon from 'assets/images/students/topics/icons/test.svg'
import topicIcon from 'assets/images/students/topics/icons/topic.svg'

//constants
import { TOPIC_STATUSES } from '../constants/topicStatuses'

//providers
import { useAuthDataContext } from 'providers/Auth'

//helpers
import { isMobile } from 'helpers/mobile'

const Item = ({ data }) => {
    const [state, setState] = useState({
        forceHover: false
    })
    const authData = useAuthDataContext()
    const {
        image_url,
        name,
        tests_count,
        lectures_count,
        no,
        result,
        subject_id,
        id
    } = data

    const TOPIC_STATUS_ID = result ? Number(result?.lectures_completed) ? 3 : 2 : 1


    const handleForceHover = forceHover => {
        setState(prev => ({
            ...prev,
            forceHover
        }))
    }

    const progressBar = (
        <div className="progress">
            <div className="bar">
                <span className="indicator" style={{ width: `${result?.percent_lectures_completed || 0}%` }}>

                </span>
            </div>
            <b>
                {result?.percent_lectures_completed || 0}%
            </b>
        </div>
    )

    return (
        <ClickAwayListener onClickAway={() => {
            isMobile() && handleForceHover(false)
        }}>
            <li
                onClick={() => {
                    isMobile() && handleForceHover(true)
                }}
            >
                <div className="top-part">
                    <i className="number">
                        {no}
                    </i>
                    <img src={image_url} className="main" />
                    <div className="right-part">
                        <span className="included">
                            Тема + тестове
                        </span>
                        <p>
                            {name}
                        </p>
                    </div>
                </div>
                <div className="footer">
                    <span>
                        <i>
                            <img src={testsIcon} />
                        </i>
                        {lectures_count} Слайда
                    </span>
                    <span>
                        <i>
                            <img src={topicsIcon} />
                        </i>
                        {tests_count} Теста
                    </span>
                </div>
                <div className={`hover ${isMobile() && state.forceHover ? 'force-hover' : ''}`}>
                    <span className={`status ${TOPIC_STATUSES[TOPIC_STATUS_ID].cssClass}`}>
                        <img src={TOPIC_STATUSES[TOPIC_STATUS_ID].icon} />
                        {TOPIC_STATUSES[TOPIC_STATUS_ID].name}
                    </span>
                    <ul>
                        {Number(lectures_count) ?
                            <li className="topic">
                                <Link to={`/topic/${id}`}>
                                    <span>
                                        <img src={topicIcon} />
                                    </span>
                                    Обучение
                                </Link>
                            </li>
                            :
                            <>
                            </>
                        }
                        {Number(tests_count) ?
                            <li className="test">
                                <Link to={`/${!authData.handleAuth()._get()._isStudent() ? 'education/' : ''}tests/${subject_id}/${id}`}>
                                    <span>
                                        <img src={testIcon} />
                                    </span>
                                    Тестове
                                </Link>
                            </li>
                            :
                            <>
                            </>
                        }
                    </ul>
                    {!isMobile() ? progressBar : <></>}
                </div>
                {isMobile() ? progressBar : <></>}
            </li>
        </ClickAwayListener>
    )
}

export default Item