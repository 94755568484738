//MUI
import { Button, Tooltip } from "@mui/material"

//images
import addIcon from 'assets/images/admin/topic/icons/add-purple.svg'
import duplicateIcon from 'assets/images/admin/topic/icons/duplicate.svg'
import deleteIcon from 'assets/images/admin/topic/icons/delete.svg'

//providers
import { useTestDataContext } from "../_providers/Test"

//components
import MiniQuestion from "./MiniQuestion"
import MiniAnswers from "./MiniAnswers"
import MiniTable from "./MiniTable"

const Aside = () => {
    const testData = useTestDataContext()
    const {
        slides,
        handleSlide,
        isSlideDeletable,
        handleActiveTemplate,
        handleActiveSlide
    } = testData

    const getTemplate = ({ question, answers, openAnswer, table }, i) => {
        switch (handleActiveTemplate()._get(i).type) {
            case 'question-answers':
                return <>
                    <MiniQuestion question={question} />
                    <MiniAnswers answers={answers} />
                </>
            case 'question-open-answer':
                return <>
                    <MiniQuestion question={question} />
                </>
            case 'table-question-open-answer':
                return <>
                    <MiniTable index={i} />
                </>
        }
    }

    return (
        <div className="aside">
            <ul className="list">
                {slides.list.map((s, i) =>
                    <li
                        key={`slide-mini-${s.id}-${i}`}
                        className={handleActiveSlide()._get()._index() === i ? 'active' : ''}
                    >
                        <div
                            className="content"
                            onClick={() => {
                                handleActiveSlide()._set(i)
                            }}
                        >
                            <span className="number">
                                {i + 1}
                            </span>
                            <div className={`mini-content ${handleActiveTemplate()._get(i).type}`}>
                                {getTemplate(s.content, i)}
                            </div>
                            {/* {s.duplicated ? 'Дубликат' : 'Оригинал'} */}
                        </div>

                        <ul className="options">
                            <li className="duplicate">
                                <Tooltip title="Дублиране на въпроса">
                                    <Button
                                        onClick={() => {
                                            handleSlide()._duplicate(i)
                                        }}
                                    >
                                        <img src={duplicateIcon} />
                                    </Button>
                                </Tooltip>
                            </li>
                            {isSlideDeletable() ?
                                <li className="delete">
                                    <Tooltip title="Премахване на въпроса">
                                        <Button
                                            onClick={() => {
                                                handleSlide()._delete(i)
                                            }}
                                        >
                                            <img src={deleteIcon} />
                                        </Button>
                                    </Tooltip>
                                </li>
                                :
                                <>
                                </>
                            }
                        </ul>
                    </li>
                )}
            </ul>
            <Button
                className="new-slide"
                onClick={handleSlide()._add}
            >
                <img src={addIcon} />
                Нов въпрос
            </Button>
        </div>
    )
}

export default Aside