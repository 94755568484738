//providers
import { useAppContext } from "providers/App"
import { useEducationModalDataContext } from '../_providers/EducationModal'

//MUI
import { MenuItem, Checkbox, Select } from '@mui/material'

const Name = ({ value, isEditMode }) => {
    const educationModalData = useEducationModalDataContext()
    const app = useAppContext()

    return (
        <div
            className={`slide-${value + 1} ${value === 0 ? 'show' : ''}`}
        >
            <h6>
                Стъпка 1
            </h6>
            <b>
                {isEditMode ? 'Редакция' : 'Създаване'} на обучение
            </b>
            <p>
                Тук редактирате името на обучението и класовете, за които са предназначени темите разработени в него.
            </p>

            <div className="row">
                <div className="col small">
                    <label htmlFor="number">
                        Клас
                    </label>
                    <Select
                        value={educationModalData.state.class_id}
                        onChange={educationModalData.handleModal()._change}
                        name="class_id"
                    >
                        {app.handleClassesList()._get()._list().map(l => (
                            <MenuItem
                                key={`class-list-${l.id}`}
                                value={l.id}
                            >
                                <Checkbox checked={educationModalData.state.class_id === l.id} />
                                {l.short_name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="col small">
                    <label htmlFor="number">
                        Форма
                    </label>
                    <Select
                        value={educationModalData.state.form_id}
                        onChange={educationModalData.handleModal()._change}
                        name="form_id"
                    >
                        {app.handleEducationFormsList()._get()._list().map(l => (
                            <MenuItem
                                key={`class-list-${l.id}`}
                                value={l.id}
                            >
                                <Checkbox checked={educationModalData.state.form_id === l.id} />
                                {l.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="col">
                    <label htmlFor="name">
                        Име на обучението
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Въведете име..."
                        onChange={educationModalData.handleModal()._change}
                        value={educationModalData.state.name}
                    />
                </div>
            </div>
        </div >
    )
}

export default Name