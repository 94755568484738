import { useState } from 'react'

const useVisibilityHook = currentStatus => {
    const [visible, setVisible] = useState(currentStatus)

    const handleVisibility = () => {
        setVisible(!visible)
    }

    return ({
        visible,
        handleVisibility
    })
}

export default useVisibilityHook