import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//MUI
import { Button, Tooltip } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'
import useDisplayUploadedImageHook from 'hooks/useDisplayUploadedImageHook'
import useLoadingHook from 'hooks/useLoadingHook'

//images
import imageImage from 'assets/images/admin/modals/education/image.svg'
import uploadIcon from 'assets/images/admin/modals/education/icons/upload.svg'
import closeIcon from 'assets/images/admin/modals/education/icons/close.svg'

//helpers
import api from 'helpers/api'

//constants
import { API_URL } from 'constants/apiUrls'

//providers
import { useTopicDataContext } from 'components/admin/topic/_providers/Topic'

const Success = ({ }, ref) => {
    const topicData = useTopicDataContext()
    const loadingHook = useLoadingHook()
    const params = useParams()
    const navigate = useNavigate()
    const inputFileRef = useRef()
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()
    const {
        image,
        handleChange,
        setImage
    } = useDisplayUploadedImageHook()
    const [state, setState] = useState({
        name: '',
        no: '',
    })

    const topicId = Number(params.topicId)

    useImperativeHandle(ref, () => ({
        open: () => {
            handleModal()._show()
            setData(topicData.name, topicData.no, topicData.image)
        },
        close: () => {
            handleModal()._hide()
        }
    }))

    /**
     * 
     * @param {String} name 
     * @param {String} no 
     * @param {String} image 
     */
    const setData = (name, no, image) => {
        setImage(image)
        setState(prev => ({
            ...prev,
            name,
            no
        }))
    }

    /**
     * Дали потребителя може да съхрани данните в provider
     * @returns {Boolean}
     */
    const isCreateAvailable = () => {
        if (state.no.length && image && state.name.length) {
            return true
        }

        return false
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (topicId) {
            const { educationId } = params
            const data = new FormData(e.target)
            loadingHook.handleLoading()._start()
            data.append('subject_id', educationId)
            data.append('id', topicId)

            api.post(API_URL.TOPICS.EDIT, data)
                .then(res => {
                    if (topicId) {
                        const { name, no, image_url } = res.data
                        topicData.handleTopic()._set(name, no, image_url)
                        handleModal()._hide()
                    }
                })
                .finally(() => {
                    loadingHook.handleLoading()._stop()
                })
        } else {
            topicData.handleTopic()._set(state.name, state.no, image)
            handleModal()._hide()
        }
    }

    const handleChangeField = e => {
        const { name, value } = e.target

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleBlob = e => {
        const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = function () {
            topicData.handleTopic()._setImageBlob(reader.result)
        }
    }

    return (
        <div className={`overlay create-topic ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <form
                className={`modal ${modal.box ? 'show' : ''}`}
                onSubmit={handleSubmit}
            >
                <div className="head">
                    <Button
                        className="close"
                        onClick={() => {
                            if (topicId || topicData.name.length && topicData.no.length && topicData.image.length) handleModal()._hide()
                            else navigate(-1)
                        }}
                    >
                        <img src={closeIcon} />
                    </Button>
                </div>
                <div className="body">
                    <b>
                        {topicId ? 'Редакция' : 'Създаване'} на тема
                    </b>
                    <p>
                        От тук може да смените името на темата и да зададете ново изображение към нея.
                    </p>
                    <input
                        type="file"
                        ref={inputFileRef}
                        hidden
                        onChange={e => {
                            handleBlob(e)
                            handleChange(e)
                        }}
                        accept="image/*"
                        name="image"
                    />
                    <Tooltip title={image ? 'Промяна на изображението' : 'Прикачване на изображение'}>
                        <Button
                            className="image"
                            onClick={() => {
                                inputFileRef.current.click()
                            }}
                        >
                            {!image ?
                                <div className="upload">
                                    <img src={imageImage} />
                                    <span>
                                        <img src={uploadIcon} />
                                        Качи изображение
                                    </span>
                                </div>
                                :
                                <img src={image} className="main" />
                            }
                        </Button>
                    </Tooltip>
                    <div className="row">
                        <div className="col small">
                            <label htmlFor="no">
                                №
                            </label>
                            <input
                                type="number"
                                name="no"
                                id="no"
                                placeholder="#"
                                onChange={handleChangeField}
                                value={state.no}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="name">
                                Име на темата
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Въведете име..."
                                onChange={handleChangeField}
                                value={state.name}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <Button
                        disabled={!isCreateAvailable()}
                        className={`${!isCreateAvailable() ? 'disabled' : ''} ${loadingHook.handleLoading()._isLoading() ? 'loading' : ''}`}
                        type="submit"
                    >
                        {topicId ? 'Запазване' : 'Напред'}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Success)