import { useState, useEffect } from 'react'

//images
import clockIcon from 'assets/images/students/test/icons/clock.svg'

//helpers
import { convertSecondsToMinutes } from 'helpers/convertSeconds'

let timelineInterval
const Timer = ({ totalTimeInSeconds }) => {
    const [state, setState] = useState(totalTimeInSeconds)
    const minutes = convertSecondsToMinutes(state).fullMinutes 
    const seconds = convertSecondsToMinutes(state).fullSeconds

    useEffect(() => {
        timelineInterval = setInterval(() => {
            setState(prev => (Number(prev) - 1))
        }, 1000)

        return () => {
            clearInterval(timelineInterval)
        }
    }, [])

    return (
        <div className={`mid ${(Number(minutes) === 0) && (Number(seconds) <= 10) ? 'timeout' : ''}`} key={state}>
            <img src={clockIcon} />
            <time>
                <span>
                    {minutes}
                </span>
                :
                <span>
                    {seconds}
                </span>
            </time>
            {/* <div className="of-total">
                    / 20 мин.
                </div> */}
        </div>
    )
}

export default Timer