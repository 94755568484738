import { useEffect, useState, useMemo } from "react"

//helpers
import api from "helpers/api"

//constants
import { API_URL } from "constants/apiUrls"

let timelineInterval
const Timeline = ({ totalTimeInSeconds, giveAction }) => {
    const [state, setState] = useState({
        seconds: totalTimeInSeconds
    })

    useEffect(() => {
        timelineInterval = setInterval(() => {
            setState(prev => ({
                ...prev,
                seconds: prev.seconds - 1 > 0 ? prev.seconds - 1 : 0,
            }))
        }, 1000)

        if (getProgress >= 100) clearTimeout(timelineInterval)

        return () => {
            clearInterval(timelineInterval)
        }
    }, [])


    /**
     * прицента на зареждане в индикатора.
     * @returns {Number}
     */
    const getProgress = useMemo(() => {
        const progress = ((totalTimeInSeconds - state.seconds) / totalTimeInSeconds) * 100
        if (progress >= 100) {
            clearInterval(timelineInterval)
            giveAction()
        }

        return progress
    })

    return (
        <div className="timeline">
            <span style={{ width: `${getProgress}%` }}>

            </span>
        </div>
    )
}

export default Timeline