import { useState, useEffect } from 'react'

//MUI
import { Skeleton, Tooltip } from '@mui/material'

//images
import viewIcon from 'assets/images/students/profile/icons/view.svg'
import downloadIcon from 'assets/images/students/profile/icons/download.svg'
import pdfIcon from 'assets/images/students/profile/icons/pdf.svg'

//helpers
import api from 'helpers/api'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//constants
import { API_URL } from 'constants/apiUrls'

const CertificatesList = () => {
    const loadingHook = useLoadingHook(true)
    const [state, setState] = useState({
        data: []
    })

    useEffect(() => {
        getData()
    }, [])

    const getData = (counter = 1) => {
        api.get(API_URL.STUDENTS.CERTIFICATES)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    data: res.data.items
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <ul className="certificates">
            {loadingHook.handleLoading()._isLoading() ?
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
                :
                !state.data.length ?
                    <p className="no-data">
                        Все още нямате издадени сертификати.
                    </p>
                    :
                    state.data.map(d =>
                        <li key={`certificate-${d.id}`}>
                            <div className="left">
                                <img src={pdfIcon} />
                                <div className="right-part">
                                    <h6>
                                        {d.name}.pdf
                                    </h6>
                                </div>
                            </div>
                            <div className="right">
                                <Tooltip title="Преглед на сертификата">
                                    <a href={d.url} target="_blank">
                                        <img src={viewIcon} />
                                    </a>
                                </Tooltip>
                                <Tooltip title="Сваляне на сертификата">
                                    <a href={d.download_url} target="_blank">
                                        <img src={downloadIcon} />
                                    </a>
                                </Tooltip>
                            </div>
                        </li>
                    )}
        </ul>
    )
}

export default CertificatesList