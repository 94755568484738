import { useLocation } from "react-router-dom"

const useExcludeHeaderHook = () => {
    const location = useLocation()

    /**
     * 
     * @param {String} part 
     * @returns {Boolean}
     */
    const pathIncludes = part => {
        if (location.pathname.includes(part)) {
            return true
        }

        return false
    }

    /**
     * дали хедъра да бъде изключен от layout
     * @returns {Boolean}
     */
    const excludeHeader = authGet => {
        if (
            pathIncludes('test/') ||
            (pathIncludes('topic/') && authGet._isStudent()) ||
            pathIncludes('result') ||
            pathIncludes('admin/topic') ||
            ((location.pathname === '/') && !authGet._isLogged()) ||
            pathIncludes('login') ||
            pathIncludes('register') ||
            pathIncludes('forgotten-password')
        ) {
            return true
        }

        return false
    }

    return ({
        excludeHeader
    })
}

export default useExcludeHeaderHook