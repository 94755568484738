//icons
import notStartedIcon from 'assets/images/students/topics/icons/in-progress.svg'
import startedIcon from 'assets/images/students/topics/icons/in-progress.svg'
import finishedIcon from 'assets/images/students/topics/icons/in-progress.svg'

export const TOPIC_STATUSES = {
    1: {
        id: 1,
        name: 'Незапочната',
        cssClass: 'not-started',
        icon: notStartedIcon
    },
    2: {
        id: 2,
        name: 'Започната',
        cssClass: 'started',
        icon: startedIcon
    },
    3: {
        id: 2,
        name: 'Завършена',
        cssClass: 'finished',
        icon: finishedIcon
    }
}