import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//components
import Head from "./components/Head"
import Table from "./components/Table"

//providers
import { useStudentsDataContext } from "./_providers/Students"

//components
import NoResultsFound from 'components/app/components/NoResultsFound'

const Teachers = () => {
    const location = useLocation()
    const studentsData = useStudentsDataContext()

    useEffect(() => {
        studentsData.handleStudents()._fetch()
    }, [location.search, studentsData.refresh])

    return (
        <section id="admin-students">
            <div className="wrapper">
                <Head />
                {studentsData.isLoading ?
                    <>
                        <Skeleton className="thead" />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
                    :
                    studentsData.handleStudents()._get()._list().length ?
                        <Table />
                        :
                        <NoResultsFound
                            text="Не бяха открити ученици в списъка."
                        />
                }
            </div>
        </section>
    )
}

export default Teachers