import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//components
import StudentStats from "../../components/StudentStats"
import List from "./components/List"

//helpers
import api from 'helpers/api'

//constants
import { API_URL } from 'constants/apiUrls'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'


const Tests = () => {
    const params = useParams()
    const { topicId } = params
    const loadingHook = useLoadingHook()
    const [state, setState] = useState({
        data: [],
        topic: {}
    })
    useEffect(() => {
        getData()
    }, [])

    const getData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.post(`${API_URL.SHARED.TESTS}`, {
            topic_id: topicId
        })
            .then(res => {
                const { items, topic } = res.data
                setState(prev => ({
                    ...prev,
                    data: items,
                    topic
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }
    return (
        <section id="student-tests">
            {loadingHook.handleLoading()._isLoading() ?
                <>
                    <div className="top-skeleton">
                        <Skeleton />
                    </div>
                    <div className="wrapper">
                        <div className="bottom">
                            <ul>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </ul>
                        </div>
                    </div>
                </>
                :
                <div className="wrapper">
                    <StudentStats
                        data={state.topic}
                    />
                    <div className="bottom">
                        <List
                            data={state.data}
                        />
                    </div>
                </div>
            }
        </section>
    )
}

export default Tests