import { useEffect, useState, useMemo } from "react"

//helpers
import api from "helpers/api"

//constants
import { API_URL } from "constants/apiUrls"

let timelineInterval
const Timeline = ({ totalTimeInSeconds, id, ready, setLocalyReadyIds }) => {
    const [state, setState] = useState({
        seconds: totalTimeInSeconds
    })

    useEffect(() => {
        if (ready) return

        timelineInterval = setInterval(() => {
            setState(prev => ({
                ...prev,
                seconds: prev.seconds - 1 > 0 ? prev.seconds - 1 : 0,
            }))
        }, 1000)

        if (getProgress >= 100) clearTimeout(timelineInterval)

        return () => {
            clearInterval(timelineInterval)
        }
    }, [])

    const handleComplete = (counter = 1) => {
        api.post(API_URL.STUDENTS.MARK_TOPIC, {
            lecture_id: id
        })
        .then(() => {
            setLocalyReadyIds(id)
        })
            .catch(() => {
                if (counter <= 3) handleComplete(counter + 1)
            })
    }

    /**
     * прицента на зареждане в индикатора.
     * @returns {Number}
     */
    const getProgress = useMemo(() => {
        if (ready) return 0

        const progress = ((totalTimeInSeconds - state.seconds) / totalTimeInSeconds) * 100
        if (progress >= 100) {
            clearInterval(timelineInterval)
            handleComplete()
        }

        return progress
    })

    return (
        <div className="timeline">
            <span style={{ width: `${ready ? 100 : getProgress}%` }}>

            </span>
        </div>
    )
}

export default Timeline