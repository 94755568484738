import educationIcon from 'assets/images/app/header/icons/education.svg'
import teachersIcon from 'assets/images/app/header/icons/teachers.svg'
import studentsIcon from 'assets/images/app/header/icons/students.svg'
import chartIcon from 'assets/images/app/header/icons/chart.svg'
import settingsIcon from 'assets/images/app/header/icons/settings.svg'

export const NAVIGATION_ITEMS = [
    {
        id: 1,
        name: 'Обучения',
        icon: educationIcon,
        path: '/',
        access: 'admin-teacher-student'
    },
    {
        id: 2,
        name: 'Преподаватели',
        icon: teachersIcon,
        path: '/admin/teachers',
        access: 'admin'
    },
    {
        id: 3,
        name: 'Ученици',
        icon: studentsIcon,
        path: '/education/students',
        access: 'admin-teacher'
    },
    // {
    //     id: 4,
    //     name: 'Статистики',
    //     icon: chartIcon,
    //     path: '/education/statistics',
    //     access: 'admin-teacher'
    // },
    // {
    //     id: 5,
    //     name: 'Настройки',
    //     icon: settingsIcon,
    //     path: '/settings',
    //     access: 'admin-teacher-student'
    // }
]