//MUI
import { Button, Menu, MenuItem, Tooltip } from '@mui/material'

//images
import optionsIcon from 'assets/images/admin/teachers/icons/options.svg'
import editIcon from 'assets/images/teachers/education/icons/edit.svg'
import deleteIcon from 'assets/images/teachers/education/icons/delete.svg'
import playIcon from 'assets/images/admin/students/icons/play.svg'

//hooks
import useMenuHook from 'hooks/useMenuHook'

//providers
import { useStudentsDataContext } from '../_providers/Students'

const Row = ({ data, index }) => {
    const studentsData = useStudentsDataContext()
    const {
        name,
        result,
    } = data

    const {
        opened,
        anchorEl,
        setAnchorEl
    } = useMenuHook()

    const handleOptions = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        if (anchorEl) setAnchorEl(null)
    }

    const handleEdit = () => {
        studentsData.handleStudents()._edit()._prepare(data)
        handleCloseMenu()
    }

    const handleDelete = () => {
        studentsData.handleStudents()._remove()._prepare(data)
        handleCloseMenu()
    }

    return (
        <tr>
            <td>
                <div>
                    {index + 1}
                </div>
            </td>
            <td>
                <div>
                    <img src={data.image_url} className="profile-picture" />
                    {name}
                </div>
            </td>
            <td>
                <div>
                    {data?.class?.name || 'Завършил'}
                </div>
            </td>
            <td>
                <div>
                    {data?.form?.name || 'Неизвестна'}
                </div>
            </td>
            <td>
                <Tooltip title="Процент прочетени слайдове от всички теми във всички предмети">
                    <div>
                        {/*Клас green се слага, когато прогреса е на 100% */}
                        <span
                            className={`progress ${result?.percent_lectures_completed === 100 ? 'green' : ''}`}
                            style={{ width: `${result?.percent_lectures_completed || 0}%` }}
                        >
                            <i>
                                <img src={playIcon} />
                                {result?.percent_lectures_completed || 0}%
                            </i>
                        </span>
                    </div>
                </Tooltip>
            </td>
            <td className="centered">
                <div>
                    <Button
                        onClick={handleOptions}
                    >
                        <img src={optionsIcon} />
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={opened}
                        onClose={handleCloseMenu}
                        className="education-menu"
                    >
                        <MenuItem
                            onClick={handleEdit}
                        >
                            <img src={editIcon} />
                            Редакция
                        </MenuItem>
                        <hr />
                        <MenuItem
                            className="delete"
                            onClick={handleDelete}
                        >
                            <img src={deleteIcon} />
                            Изтриване
                        </MenuItem>
                    </Menu>
                </div>
            </td>
        </tr>
    )
}

export default Row