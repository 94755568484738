//constants
import { UPLOAD_ACCEPT } from '../_constants/uploadRules'

//MUI
import { Tooltip, Button } from '@mui/material'

//providers
import { useTopicDataContext } from '../_providers/Topic'

//images
import deleteIcon from 'assets/images/admin/topic/icons/delete.svg'

const Gallery = ({ single = false }) => {
    const topicData = useTopicDataContext()
    const {
        handleSlide,
        handleActiveSlide,
        handleGallery
    } = topicData

    const handleUpload = e => {
        if (e.target.type === 'button') return
        document.querySelector('input[type="file"]').click()
    }

    const handleChange = e => {
        const { files } = e.target
        let images = []
        for (let i = 0; i < files.length; i++) {
            images = [...images, URL.createObjectURL(files[i])]

        }

        let blobImages = []
        const readBlobs = (counter = 0) => {
            const reader = new FileReader()
            if (files[counter]) {
                reader.readAsDataURL(files[counter])
                reader.onload = function () {
                    if (counter <= files.length - 1) {
                        blobImages = [...blobImages, reader.result]
                        readBlobs(counter + 1)
                    }
                }
            } else {
                handleSlide()._set('images', blobImages)
            }
        }
        readBlobs()
    }

    /**
     * 
     * @param {String|Number} itemToRemove 
     * @returns {Element}
     */
    const getDeleteButtonElement = itemToRemove => {
        return <Tooltip title="Премахване на изображението">
            <Button
                onClick={() => {
                    handleGallery()._delete(itemToRemove)
                }}
            >
                <img src={deleteIcon} />
            </Button>
        </Tooltip>
    }

    /**
     * 
     * @returns {Element}
     */
    const getEmptyImgElement = () => {
        return <Tooltip title={`Прикачване на ${single ? 'изображение' : 'изображения'}`}>
            <li
                className="non-uploaded-image"
                onClick={handleUpload}
            >
            </li>
        </Tooltip>
    }

    /**
     * 
     * @returns {Element}
     */
    const getGalleryElement = () => {
        const { images } = handleActiveSlide()._get()._data().content
        let gallery

        if (single) {
            gallery =
                images[0] ?
                    <Tooltip title="Смяна на изображението">
                        <li
                            onClick={handleUpload}
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            {getDeleteButtonElement('all')}
                            <img src={images[0]} />
                        </li>
                    </Tooltip>
                    :
                    getEmptyImgElement()
        } else {
            gallery = <>
                {images.map((image, i) =>
                    <li
                        key={`gallery-image-${i}`}
                    >
                        {getDeleteButtonElement(i)}
                        <img src={image} />
                    </li>
                )}
                {getEmptyImgElement()}
            </>
        }

        return gallery
    }

    return (
        <>
            <input
                type="file"
                hidden
                multiple={!single}
                accept={UPLOAD_ACCEPT}
                onChange={handleChange}
            />
            <ul className="gallery">
                {getGalleryElement()}
            </ul>
        </>
    )
}

export default Gallery