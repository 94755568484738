import { Link } from 'react-router-dom'

//components
import EducationMenu from 'components/teacher/components/EducationMenu'
import EducationContentIndicator from './EducationContentIndicator'

//images
import topicIcon from 'assets/images/teachers/education/icons/topic.svg'
import testIcon from 'assets/images/teachers/education/icons/test.svg'
import userIcon from 'assets/images/teachers/education/icons/user.svg'

const Item = ({ data }) => {
    const {
        name,
        image_url,
        id,
        tests_count,
        topics_count,
        students_count,
    } = data

    return (
        <li className="" style={{ backgroundImage: `url(${image_url})` }}>
            <Link to={`/education/${id}`}>
                <div className="body">
                    <div className="main">
                        <EducationContentIndicator content="both" />
                        <b>
                            {name} <span>{data?.class?.short_name}{Number(data?.class?.short_name) > 8 ? '-ти' : '-ми'} </span> клас
                        </b>
                    </div>
                    <ul className="footer">
                        <li>
                            <span>
                                <img src={topicIcon} />
                            </span>
                            {topics_count} теми
                        </li>
                        <li>
                            <span>
                                <img src={testIcon} />
                            </span>
                            {tests_count} теста
                        </li>
                        <li>
                            <span>
                                <img src={userIcon} />
                            </span>
                            {students_count} човека
                        </li>
                    </ul>
                </div>
            </Link>
            <EducationMenu data={data} />
        </li>
    )
}

export default Item