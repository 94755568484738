import { useState } from 'react'

const useLoadingHook = (init = false) => {
    const [loading, setLoading] = useState(init)

    const handleLoading = () => {
        const _start = () => {
            setLoading(true)
        }

        const _stop = () => {
            setLoading(false)
        }

        /**
         * 
         * @param {Number} timeout 
         */
        const _startWithAutoStop = timeout => {
            _start()

            setTimeout(() => {
                _stop()
            }, timeout)
        }

        /**
         * 
         * @returns {Boolean}
         */
        const _isLoading = () => loading

        return {
            _start,
            _stop,
            _startWithAutoStop,
            _isLoading
        }
    }
    return {
        handleLoading
    }
}

export default useLoadingHook