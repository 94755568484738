import { Link, NavLink, useNavigate } from "react-router-dom"

//MUI
import { Tooltip, Button } from '@mui/material'

//images
import brandImage from 'assets/images/app/brand.svg'

//constants
import { NAVIGATION_ITEMS } from './constants/navigation'

//hooks
import useActiveMenuItemHook from "./hooks/useActiveMenuItemHook"
import { useAuthDataContext } from "providers/Auth"

const Header = () => {
    const navigate = useNavigate()
    const authData = useAuthDataContext()
    const userName = authData.handleAuth()._get()._data()?.user?.name || 'Потребител'
    const isLogged = authData.handleAuth()._get()._isLogged()
    const {
        isActiveMenuItem
    } = useActiveMenuItemHook()

    /**
     * Връща навигацията на база полето access (достъп на потребителя)
     * @returns {Array}
     */
    const getNavigation = () => {
        return NAVIGATION_ITEMS.filter(n => {
            if (authData.handleAuth()._get()._isStudent()) {
                return n.access.includes('student')
            } else if (authData.handleAuth()._get()._isAdmin()) {
                return n.access.includes('admin')
            } else if (!authData.handleAuth()._get()._isStudent()) {
                return n.access.includes('teacher')
            } else {
                return true
            }
        })
    }

    return (
        <header>
            <div className="wrapper">
                <Tooltip title="Към начало">
                    <Link to="/" className="brand">
                        <img src={brandImage} />
                        <b>
                            {document.title.replace('-', '')}
                        </b>
                    </Link>
                </Tooltip>
                {isLogged ?
                    <>
                        <nav>
                            {getNavigation().length === 1 ? [] : getNavigation().map(n =>
                                <Tooltip
                                    title={!isActiveMenuItem(n.path) ? n.name : ''}
                                    key={`n-i-${n.id}`}
                                >
                                    <NavLink
                                        to={n.path}
                                        className={({ isActive }) => isActive || isActiveMenuItem(n.path) ? 'active' : ''}
                                        end
                                    >
                                        <img src={n.icon} />
                                        <span>
                                            {n.name}
                                        </span>
                                    </NavLink>
                                </Tooltip>
                            )}
                        </nav>
                        <div className="user">
                            <span>
                                Здравей,
                                <br />
                                {userName}
                            </span>
                            <Tooltip title="Към профила">
                                <Link to="/profile">
                                    <img src={authData.handleAuth()._get()._data()?.user?.image_url} />
                                </Link>
                            </Tooltip>
                            <Button
                                className="logout"
                                onClick={authData.handleAuth()._logout}
                            >
                                Изход
                            </Button>
                        </div>
                    </>
                    :
                    <div className="user">
                        <Button
                            className="logout"
                            onClick={() => {
                                navigate('/')
                            }}
                        >
                            Начало
                        </Button>
                    </div>
                }
            </div>
        </header>
    )
}

export default Header