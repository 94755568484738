/**
 * Изчислява размера на шрфита, който се използва в отговорите на теста, на база дължина на такст/свободно място
 * @param {Number} textLength 
 * @param {Number} answersCount 
 * @returns {String}
 */
export const getAnswersFontSize = (textLength, answersCount) => {
    let size = 18
    //TO DO
    return `${size}px`
}

/**
 * Изчислява размера на шрфита, който се използва във въпросите на теста
 * @param {Number} length 
 * @returns {String}
 */
export const getQuestionFontSize = length => {
    let size = 30
    //TO DO
    return `${size}px`
}