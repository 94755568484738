import { Link } from "react-router-dom"

//MUI 
import { Tooltip, Button } from '@mui/material'

//constants
import { GRADES } from './constants/grades'

//images
import correctIcon from 'assets/images/students/result/icons/correct.svg'
import incorrectIcon from 'assets/images/students/result/icons/incorrect.svg'
import timeIcon from 'assets/images/students/result/icons/time.svg'
import brandImage from 'assets/images/app/brand-white.svg'
import downloadIcon from 'assets/images/students/result/icons/download.svg'

//helpers
import { convertSecondsToMinutes } from 'helpers/convertSeconds'

//providers
import { useAuthDataContext } from "providers/Auth"

const Result = ({ data, topicId, educationId, setViewTest, certificate }) => {
    const authData = useAuthDataContext()

    const {
        correct_count,
        wrong_count,
        max_time,
        score,
        time_spent
    } = data
    const minutes = convertSecondsToMinutes(time_spent).fullMinutes
    const seconds = convertSecondsToMinutes(time_spent).fullSeconds

    /**
     * Определя дали оценката е приемлива или не
     * @returns {Boolean}
     */
    const isGradeGood = () => {
        if (Number(score) >= 4) {
            return true
        }

        return false
    }

    return (
        <section id="result">
            <div className="wrapper">
                <div className="header">
                    <Tooltip title="Към начало">
                        <Link to="/" className="brand">
                            <img src={brandImage} />
                        </Link>
                    </Tooltip>
                </div>
                <div className={`body ${isGradeGood() ? 'good' : 'bad'}`}>
                    <div className="holder">
                        <h6>
                            Оценка
                        </h6>
                        <h2>
                            {GRADES[score].name} <span>{score}</span>
                        </h2>
                    </div>
                    <ul>
                        <li>
                            <img src={correctIcon} />
                            <div className="info">
                                <b>
                                    {correct_count}
                                </b>
                                <span>
                                    Верни
                                </span>
                            </div>
                        </li>
                        <li>
                            <img src={incorrectIcon} />
                            <div className="info">
                                <b>
                                    {wrong_count}
                                </b>
                                <span>
                                    Грешни
                                </span>
                            </div>
                        </li>
                        {max_time ?
                            <li>
                                <img src={timeIcon} />
                                <div className="info">
                                    <b>
                                        {minutes}:{seconds}
                                    </b>
                                    <span>
                                        Време
                                    </span>
                                </div>
                            </li>
                            :
                            <>
                            </>
                        }
                    </ul>
                </div>
                <div className="footer">
                    <Button className="view-test">
                        <Link
                            to="#"
                            onClick={e => {
                                e.preventDefault()
                                setViewTest(true)
                            }}
                        >
                            Преглед на теста
                        </Link>
                    </Button>
                    <Button className="to-list">
                        <Link to={`/${!authData.handleAuth()._get()._isStudent() ? 'education/' : ''}tests/${educationId}/${topicId}`}>
                            Към тестовете
                        </Link>
                    </Button>
                    {certificate ?
                        <Button className="to-list certificate">
                            <a href={certificate.url} target="_blank">
                                <img src={downloadIcon} />
                                Свали сертификат
                            </a>
                        </Button>
                        :
                        <>
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default Result