import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'

//helpers
import { cutText } from 'helpers/test'

//MUI
import { Button, Tooltip } from '@mui/material'

//images
import editIcon from 'assets/images/admin/topic/icons/edit.svg'
import addIcon from 'assets/images/admin/topic/icons/add.svg'
import saveIcon from 'assets/images/admin/topic/icons/save.svg'
import closeIcon from 'assets/images/admin/topic/icons/close.svg'

//providers
import { useTopicDataContext } from '../_providers/Topic'

//modals
import CreateModal from '../../modals/topic/Create'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

const Header = () => {
    const params = useParams()
    const navigate = useNavigate()
    const createModalRef = useRef()
    const topicData = useTopicDataContext()
    const loadingHook = useLoadingHook()

    const handleSave = () => {
        loadingHook.handleLoading()._start()
        topicData.handleTopic()._save().finally(loadingHook.handleLoading()._stop)
    }

    return (
        <div className="header">
            {createPortal(<CreateModal ref={createModalRef} />, document.body)}
            <div className="wrapper">
                <div className="left">
                    <h1>
                        Тема №{topicData.no || '0'}
                    </h1>
                    <p>
                        <b>
                            Наименование:
                        </b>
                        {cutText(topicData.name || 'Неозаглавена тема', 30)}
                    </p>
                </div>
                <ul className="navigation">
                    <li>
                        <Button
                            className="text-out"
                            onClick={() => {
                                createModalRef.current.open()
                            }}
                        >
                            <i>
                                <img src={editIcon} />
                            </i>
                            Общи
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="text-in"
                            onClick={() => {
                                navigate(`/admin/test/${params.educationId}/${params.topicId}/${null}`)
                            }}
                        >
                            <img src={addIcon} />
                            Тест към темата
                        </Button>
                    </li>
                </ul>
                <div className="right">
                    <Button
                        className={`save ${loadingHook.handleLoading()._isLoading() ? 'loading' : ''}`}
                        onClick={handleSave}
                    >
                        <img src={saveIcon} />
                        Запази
                    </Button>
                    <Tooltip title="Затваряне на страницата">
                        <Button
                            className="close"
                            onClick={() => {
                                navigate('/')
                            }}
                        >
                            <img src={closeIcon} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default Header