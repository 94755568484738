//components
import Item from "./Item"

const List = ({ data }) => {
    return (
        <ul>
            {(data || []).map((d, i) =>
                <Item
                    key={`test-${d.id}`}
                    data={d}
                    index={i}
                />
            )}
        </ul>
    )
}

export default List