const MiniGallery = ({ images, single }) => {
    return (
        <ul className="gallery">
            {single ?
                <li>
                    <img src={images[0]} />
                </li>
                :
                images.map((image, i) =>
                    <li
                        key={`image-mini-${i}`}
                    >
                        <img src={image} />
                    </li>
                )}
        </ul>
    )
}

export default MiniGallery