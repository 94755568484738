import { createContext, useState, useContext } from "react"

const INITIAL_STATE = {
    class_id: '',
    form_id: '',
    name: '',
    image: ''
}
export const EducationModalDataContext = createContext({})
const EducationModalDataProvider = props => {
    const [state, setState] = useState(INITIAL_STATE)

    const handleModal = () => {
        const _change = e => {
            const { name, value } = e.target

            setState(prev => ({
                ...prev,
                [name]: value
            }))
        }

        const _set = data => {
            setState(prev => ({
                ...prev,
                ...data,
            }))
        }

        const _reset = () => {
            setState(INITIAL_STATE)
        }

        return {
            _change,
            _reset,
            _set
        }
    }

    /**
     * 
     * @returns {Boolean}
     */
    const isSecondStepAvailable = () => {
        if (state.name.length >= 1 && String(state.class_id || '').length && String(state.form_id || '').length) {
            return true
        }

        return false
    }

    /**
     * 
     * @returns {Boolean}
     */
    const isFinishAvaialble = () => {
        if (isSecondStepAvailable() && state.image.length) {
            return true
        }

        return false
    }

    const data = {
        state,
        setState,
        isSecondStepAvailable,
        isFinishAvaialble,
        handleModal
    }
    return <EducationModalDataContext.Provider value={data} {...props} />
}

export const useEducationModalDataContext = () => useContext(EducationModalDataContext)

export default EducationModalDataProvider
