import { useRef, useEffect } from 'react'

//helpers
import { useQuery } from 'helpers/url'

//components
import CreateNew from "../components/CreateNew"
import List from "./components/List"
import Head from './components/Head'

//modals
import RemoveModal from "components/modals/Remove"

//providers
import { useEducationDataContext } from '../_providers/Education'

const Education = () => {
    const removeModalRef = useRef()
    const query = useQuery()
    // const currentTab = query.get('tab') || 1
    const educationData = useEducationDataContext()

    /**
     * 
     * @param {Number} index 
     * @returns {Boolean}
     */
    // const isTabActive = index => {
    //     if (Number(currentTab) === index + 1) {
    //         return true
    //     }

    //     return false
    // }

    useEffect(() => {
        if (Object.keys(educationData.remove).length) removeModalRef.current.open()
        else removeModalRef.current.close()
    }, [educationData.remove])

    return (
        <section id="teacher-education">
            <RemoveModal
                ref={removeModalRef}
                elementsContent={{
                    heading: `Искате ли да изтриете "${educationData.remove.name}"?`,
                    buttons: {
                        accept: 'Изтрий обучението'
                    }
                }}
                accept={async () => {
                    await educationData.handleEducation()._remove()._process()
                    removeModalRef.current.close()
                }}
                cancel={educationData.handleEducation()._remove()._reset}
            />
            <Head />
            <div className="wrapper">
                {/* <ul className="tabs">
                    {TABS.map((t, i) =>
                        <li key={`tab-${i}`}>
                            <Link
                                to={`${t.path}?tab=${i + 1}`}
                                className={isTabActive(i) ? 'active' : ''}
                            >
                                {t.name}
                            </Link>
                        </li>
                    )}
                </ul> */}
                <List />
            </div>
            <CreateNew mainIndex={0} />
        </section>
    )
}

export default Education