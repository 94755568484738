//MUI
import { Button, Tooltip } from "@mui/material"

//images
import clearFilterIcon from 'assets/images/admin/students/icons/clear.svg'

//providers
import { useAppContext } from "providers/App"
import { useEducationDataContext } from "components/teacher/_providers/Education"

const Head = () => {
    const educationData = useEducationDataContext()
    const app = useAppContext()
    const {
        class_id,
        form_id
    } = educationData.filter

    const handleChange = e => {
        const { name, value } = e.target
        educationData.handleEducation()._set()._filter(name, value)
    }

    return (
        <div className="head wrapper">
            <h1>
                Учебни предмети
            </h1>
            <div className="filter">
                <select
                    name="class_id"
                    value={class_id}
                    onChange={handleChange}
                >
                    <option value="">
                        Всички класове
                    </option>
                    {app.classesList.map(c =>
                        <option key={c.id} value={c.id}>
                            {c.name}
                        </option>
                    )}
                </select>
                <select
                    name="form_id"
                    value={form_id}
                    onChange={handleChange}
                >
                    <option value="">
                        Всички форми
                    </option>
                    {app.formsList.map(c =>
                        <option key={c.id} value={c.id}>
                            {c.name}
                        </option>
                    )}
                </select>
                <Tooltip title="Изчистване на филтъра">
                    <Button
                        className="clear"
                        onClick={educationData.handleEducation()._set()._clearFilter}
                    >
                        <img src={clearFilterIcon} />
                    </Button>
                </Tooltip>
            </div>
        </div>
    )
}

export default Head