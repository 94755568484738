import { useEffect, useRef } from 'react'

//components
import Row from "./Row"
import Pagination from "components/app/components/Pagination"

//providers
import { useTeachersDataContext } from "../_providers/Teachers"
import { useAppContext } from 'providers/App'

//modals
import Remove from "components/modals/Remove"

const Table = () => {
    const app = useAppContext()
    const removeStudentModalRef = useRef()
    const teachersData = useTeachersDataContext()
    const handlePage = page => {
        teachersData.handleTeachers()._set()._filter('page', String(page.selected + 1))
    }

    useEffect(() => {
        if (Object.keys(teachersData.remove).length) removeStudentModalRef.current.open()
        else removeStudentModalRef.current.close()
    }, [teachersData.remove])

    return (
        <>
            <Remove
                ref={removeStudentModalRef}
                elementsContent={{
                    heading: `Искате ли да премахнете "${teachersData.remove.name}"?`,
                    buttons: {
                        accept: 'Премахни преподавателя'
                    }
                }}
                accept={async () => {
                    await teachersData.handleTeachers()._remove()._process()
                    removeStudentModalRef.current.close()
                    teachersData.handleTeachers()._remove()._reset()
                    app.handleMessage()._success()._show('Преподавателя беше изтрит успешно!')
                }}
                cancel={teachersData.handleTeachers()._remove()._reset}
            />
            <table cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            Потребител
                        </th>
                        <th>
                            Е-поща
                        </th>
                        <th>
                            Роля
                        </th>
                        <th className="centered">
                            Опции
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {teachersData.handleTeachers()._get()._list().map((l, i) =>
                        <Row
                            key={`student-${l.id}`}
                            data={l}
                            index={i}
                        />
                    )}
                </tbody>
            </table>
            <Pagination
                page={teachersData.filter.page}
                pages={teachersData.data.pages}
                handlePage={handlePage}
            />
        </>
    )
}

export default Table