import { useState } from 'react'

const INITIAL_STATE = {
    all: false,
    fields: {}
}
const useFormValidationHook = () => {
    const [validations, setValidations] = useState(INITIAL_STATE)

    const handleValidations = () => {
        const _get = () => {
            /**
             * 
             * @returns {Array}
             */
            const _allKeys = () => Object.keys(validations.fields || {})

            /**
             * 
             * @returns {Array}
             */
            const _allValues = () => Object.values(validations.fields || {})


            /**
             * 
             * @returns {Boolean}
             */
            const _all = () => validations.all

            return {
                _allKeys,
                _allValues,
                _all
            }
        }

        /**
         * 
         * @param {String} key 
         * @returns {Boolean}
         */
        const _check = (key = '') => {
            if (_get()._allKeys().includes(key)) {
                return true
            }

            return false
        }

        /**
         * 
         * @param {String} key 
         * @returns {string}
         */
        const _getMessage = (key = '') => {
            if (_check(key)) {
                return validations.fields[key][0]
            }

            return ''
        }

        const _clear = () => {
            setValidations(INITIAL_STATE)
        }

        const _set = () => {
            /**
             * 
             * @param {Object} fields 
             */
            const _fields = fields => {
                setValidations(prev => ({
                    ...prev,
                    fields
                }))
            }

            const _all = () => {
                setValidations(prev => ({
                    ...prev,
                    all: true
                }))
            }

            return {
                _fields,
                _all
            }
        }

        return {
            _get,
            _set,
            _check,
            _clear,
            _getMessage
        }
    }

    return {
        validations,
        handleValidations
    }
}

export default useFormValidationHook