//MUI
import { Tooltip } from "@mui/material"

//images
import dsBrandImage from 'assets/images/app/ds-brand.svg'

const Brand = () => {
    return (
        <Tooltip title="Към сайта">
            <a href="https://danielstandard.com" target="_blank" className="software-partner">
                <img src={dsBrandImage} />
            </a>
        </Tooltip>
    )
}

export default Brand