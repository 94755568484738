import { useState } from 'react'

const useDisplayUploadedImageHook = () => {
    const [image, setImage] = useState(null)
    const handleChange = e => {
        const url = URL.createObjectURL(e.target.files[0])
        setImage(url)
    }

    return {
        handleChange,
        image,
        setImage
    }
}

export default useDisplayUploadedImageHook