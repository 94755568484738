import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

//constants
import { API_URL } from 'constants/apiUrls'

//helpers
import api from 'helpers/api'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//typeId:
//1 - Заглавие, текст
//2 - Заглавие, галерия, текст
//3 - Заглавие, изображение, текст
//4 - Галерия, текст
//5 - Текст
const DATA = [
    {
        id: 1,
        typeId: 1,
        timeToReadInSeconds: 60,
        heading: 'Тип на обучението',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        image: null,
        gallery: []
    }, {
        id: 2,
        typeId: 2,
        timeToReadInSeconds: 120,
        heading: 'Тип на обучението2',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        image: null,
        gallery: [{
            id: 1,
            image: 'https://aos.danielstandard.com/api/image/questions/3UrmHDdQMakkhqJjks00wGkJU9KslQn4ZhSn9y2H.jpeg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYW9zLmRhbmllbHN0YW5kYXJkLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY2NDE3NTA5NSwibmJmIjoxNjY0MTc1MDk1LCJqdGkiOiJORFVJSmxJNmx3Z09MRmpTIiwic3ViIjoxNjYxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.mNrFDMQAIaOzMgd_kywhEZOSXSHKwOxRcarnDXwBTOY'
        },
        {
            id: 2,
            image: 'https://aos.danielstandard.com/api/image/questions/3UrmHDdQMakkhqJjks00wGkJU9KslQn4ZhSn9y2H.jpeg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYW9zLmRhbmllbHN0YW5kYXJkLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY2NDE3NTA5NSwibmJmIjoxNjY0MTc1MDk1LCJqdGkiOiJORFVJSmxJNmx3Z09MRmpTIiwic3ViIjoxNjYxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.mNrFDMQAIaOzMgd_kywhEZOSXSHKwOxRcarnDXwBTOY'
        },
        {
            id: 3,
            image: 'https://aos.danielstandard.com/api/image/questions/3UrmHDdQMakkhqJjks00wGkJU9KslQn4ZhSn9y2H.jpeg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYW9zLmRhbmllbHN0YW5kYXJkLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY2NDE3NTA5NSwibmJmIjoxNjY0MTc1MDk1LCJqdGkiOiJORFVJSmxJNmx3Z09MRmpTIiwic3ViIjoxNjYxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.mNrFDMQAIaOzMgd_kywhEZOSXSHKwOxRcarnDXwBTOY'
        }]
    }, {
        id: 3,
        typeId: 3,
        timeToReadInSeconds: 360,
        heading: 'Тип на обучението1233',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        image: 'https://aos.danielstandard.com/api/image/questions/3UrmHDdQMakkhqJjks00wGkJU9KslQn4ZhSn9y2H.jpeg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYW9zLmRhbmllbHN0YW5kYXJkLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY2NDE3NTA5NSwibmJmIjoxNjY0MTc1MDk1LCJqdGkiOiJORFVJSmxJNmx3Z09MRmpTIiwic3ViIjoxNjYxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.mNrFDMQAIaOzMgd_kywhEZOSXSHKwOxRcarnDXwBTOY',
        gallery: []
    }, {
        id: 4,
        typeId: 4,
        timeToReadInSeconds: 120,
        heading: null,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        image: null,
        gallery: [{
            id: 1,
            image: 'https://aos.danielstandard.com/api/image/questions/3UrmHDdQMakkhqJjks00wGkJU9KslQn4ZhSn9y2H.jpeg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYW9zLmRhbmllbHN0YW5kYXJkLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY2NDE3NTA5NSwibmJmIjoxNjY0MTc1MDk1LCJqdGkiOiJORFVJSmxJNmx3Z09MRmpTIiwic3ViIjoxNjYxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.mNrFDMQAIaOzMgd_kywhEZOSXSHKwOxRcarnDXwBTOY'
        },
        {
            id: 2,
            image: 'https://aos.danielstandard.com/api/image/questions/3UrmHDdQMakkhqJjks00wGkJU9KslQn4ZhSn9y2H.jpeg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYW9zLmRhbmllbHN0YW5kYXJkLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY2NDE3NTA5NSwibmJmIjoxNjY0MTc1MDk1LCJqdGkiOiJORFVJSmxJNmx3Z09MRmpTIiwic3ViIjoxNjYxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.mNrFDMQAIaOzMgd_kywhEZOSXSHKwOxRcarnDXwBTOY'
        },
        {
            id: 3,
            image: 'https://aos.danielstandard.com/api/image/questions/3UrmHDdQMakkhqJjks00wGkJU9KslQn4ZhSn9y2H.jpeg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYW9zLmRhbmllbHN0YW5kYXJkLmNvbVwvYXBpXC9sb2dpbiIsImlhdCI6MTY2NDE3NTA5NSwibmJmIjoxNjY0MTc1MDk1LCJqdGkiOiJORFVJSmxJNmx3Z09MRmpTIiwic3ViIjoxNjYxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.mNrFDMQAIaOzMgd_kywhEZOSXSHKwOxRcarnDXwBTOY'
        }]
    }, {
        id: 5,
        typeId: 5,
        timeToReadInSeconds: 360,
        heading: 'Тип12 на обучението1233',
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        image: null,
        gallery: []
    }
]

const useTopicHook = () => {
    const loadingHook = useLoadingHook()
    const params = useParams()
    const { id } = params

    const [state, setState] = useState({
        activeSlideIndex: 0,
        data: [],
        topic: {},
        localyReadyIds: []
    })

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.post(API_URL.SHARED.TOPICS, {
            topic_id: id
        })
            .then(res => {
                const { items, topic } = res.data
                let data = items

                if (topic.tests_count) {
                    data = [
                        ...data,
                        {
                            id: new Date().getTime(),
                            typeId: 'end',
                            name: 'Тестове',
                        }
                    ]
                }

                setState(prev => ({
                    ...prev,
                    data,
                    topic
                }))
            })
            .catch(() => {
                if (counter <= 3) fetchData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    /**
     * задава активния въпрос
     * @param {Number} activeSlideIndex 
     */
    const setActiveSlide = activeSlideIndex => {
        if (Number(activeSlideIndex) === Number(state.activeSlideIndex)) return

        setState(prev => ({
            ...prev,
            activeSlideIndex
        }))
    }

    /**
     * 
     * @param {Number} index 
     * @returns {Boolean}
     */
    const isActiveSlide = index => {
        if (Number(index) === Number(state.activeSlideIndex)) {
            return true
        }

        return false
    }

    /**
     * 
     * @returns {Array}
     */
    const getData = () => state.data || []

    /**
     * 
     * @returns {Object}
     */
    const getTopic = () => state.topic || {}

    const handleLocalyReadyIds = () => {
        /**
         * 
         * @param {Number} id 
         */
        const _set = id => {
            setState(prev => ({
                ...prev,
                localyReadyIds: [...state.localyReadyIds, id]
            }))
        }

        /**
         * 
         * @returns {Array}
         */
        const _get = () => state.localyReadyIds || []

        return {
            _set,
            _get
        }
    }

    return ({
        state,
        setActiveSlide,
        isActiveSlide,
        getData,
        getTopic,
        handleLocalyReadyIds
    })
}

export default useTopicHook