import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//components
import Aside from "./components/Aside"
import Header from "./components/Header"
import Main from "./components/Main"

//modals
import Create from "../modals/test/Create"

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//helpers
import api from 'helpers/api'

//constants
import { API_URL } from 'constants/apiUrls'

//providers
import { useTestDataContext } from './_providers/Test'

const Test = () => {
    const params = useParams()
    const createModalRef = useRef()
    const testId = Number(params.testId)
    const loadingHook = useLoadingHook()
    const testData = useTestDataContext()

    useEffect(() => {
        if (!testId) createModalRef.current.open()
        else getData()
    }, [])

    const getData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.post(`${API_URL.TESTS.SHOW}`, {
            id: testId
        })
            .then(res => {
                const { name, topic, max_points, questions, max_time, scoring } = res.data
                testData.handleTest()._set(name, topic.name, topic.id, max_points, questions.length, max_time, scoring)
                testData.handleTest()._setSlides(questions)
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <section id="admin-test">
            <Create ref={createModalRef} />
            {loadingHook.handleLoading()._isLoading() ?
                <div className="full-screen">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton className="big" />
                </div>
                :
                <>
                    <Header />
                    <div className="content">
                        <Aside />
                        <Main />
                    </div>
                </>
            }
        </section>
    )
}

export default Test