export const convertSecondsToMinutes = sec => {
    let minutes = Math.floor((Number(sec) / 60)) || 0
    let seconds = Number(sec) % 60 || 0
    if (minutes < 0) {
        minutes = 0
    }
    if (seconds < 0) {
        seconds = 0
    }
    return {
        minutes,
        fullMinutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds,
        fullSeconds: seconds < 10 ? `0${seconds}` : seconds
    }
}