import { useNavigate } from 'react-router-dom'

//MUI
import { Menu, MenuItem } from '@mui/material'

//providers
import { useEducationDataContext } from '../../../_providers/Education'

//hooks
import useMenuHook from 'hooks/useMenuHook'

//images
import editIcon from 'assets/images/teachers/education/icons/edit.svg'
import deleteIcon from 'assets/images/teachers/education/icons/delete.svg'


const TestMenu = ({ educationId, topicId, testId, data }) => {
    const navigate = useNavigate()
    const educationData = useEducationDataContext()
    const testController = educationData.handleTest()
    const {
        opened,
        anchorEl,
        setAnchorEl
    } = useMenuHook()

    const handleOpenMenu = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        if (anchorEl) setAnchorEl(null)
    }

    const handleEdit = () => {
        // testController._edit()
        navigate(`/admin/test/${educationId}/${topicId}/${testId}`)
        handleCloseMenu()
    }

    const handleDelete = () => {
        testController._remove()._prepare(data)
        handleCloseMenu()
    }

    const handleAddTest = () => {
        testController._addTest()
        handleCloseMenu()
    }

    const handleAddTopic = () => {
        testController._addSubtopic()
        handleCloseMenu()
    }

    return (
        <>
            <span
                className="education-menu"
                onClick={handleOpenMenu}
            >

            </span>
            <Menu
                anchorEl={anchorEl}
                open={opened}
                onClose={handleCloseMenu}
                className="education-menu"
            >
                <MenuItem
                    onClick={handleEdit}
                >
                    <img src={editIcon} />
                    Редакция
                </MenuItem>
                <hr />
                <MenuItem
                    onClick={handleDelete}
                    className="delete"
                >
                    <img src={deleteIcon} />
                    Изтриване на теста
                </MenuItem>
            </Menu>
        </>
    )
}

export default TestMenu