//MUI
import { Button, Tooltip } from "@mui/material"

//images
import clearFilterIcon from 'assets/images/admin/students/icons/clear.svg'

//providers
import { useAppContext } from "providers/App"
import { useStudentsDataContext } from "../_providers/Students"

let filterTimeout
const Filter = () => {
    const app = useAppContext()
    const studentsData = useStudentsDataContext()
    const {
        search,
        class_id,
        form_id
    } = studentsData.filter
    const handleChange = e => {
        const { name, value } = e.target
        clearTimeout(filterTimeout)
        filterTimeout = setTimeout(() => {
            studentsData.handleStudents()._set()._filter(name, value)
        }, name === 'search' ? 300 : 0)
    }

    return (
        <div className="filter">
            <input
                type="text"
                placeholder="Търсене..."
                onChange={handleChange}
                name="search"
                defaultValue={search}
            />
            <select
                name="class_id"
                value={class_id}
                onChange={handleChange}
            >
                <option value="">
                    Всички класове
                </option>
                {app.classesList.map(c =>
                    <option key={c.id} value={c.id}>
                        {c.name}
                    </option>
                )}
                <option value="0">
                    Завършили
                </option>
            </select>
            <select
                name="form_id"
                value={form_id}
                onChange={handleChange}
            >
                <option value="">
                    Всички форми
                </option>
                {app.formsList.map(c =>
                    <option key={c.id} value={c.id}>
                        {c.name}
                    </option>
                )}
            </select>
            <Tooltip title="Изчистване на филтъра">
                <Button
                    className="clear"
                    onClick={studentsData.handleStudents()._set()._clearFilter}
                >
                    <img src={clearFilterIcon} />
                </Button>
            </Tooltip>
        </div>
    )
}

export default Filter