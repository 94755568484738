//providers
import { useTestDataContext } from "../_providers/Test"

//constants
import { MAX_ALLOWED_TABLE_VALUES } from "constants/maxValues"

const Table = () => {
    const {
        handleTable
    } = useTestDataContext()

    return (
        <>
            <table cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th className="empty">
                            <i>
                                Консум.
                            </i>
                            <hr />
                            <i>
                                Произв.
                            </i>
                        </th>
                        {handleTable()._get()._cols().map((c, i) =>
                            <th key={`table-head-col-${i}`}>
                                B{i + 1}
                            </th>
                        )}
                        <th>
                            <div className="var">
                                a<span>i</span>
                                <i>
                                    Налич.
                                </i>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {handleTable()._get()._rows().map((_, i) =>
                        <tr key={`table-body-row-${i}`}>
                            <td>
                                A{i + 1}
                            </td>
                            {handleTable()._get()._cols().map((_, j) =>
                                <td key={`table-body-col-${i}-${j}`}>
                                    <input
                                        name={`${i}-${j}`}
                                        onChange={handleTable()._set()._data}
                                        value={handleTable()._get()._data(`${i}-${j}`)}
                                    />
                                </td>
                            )}
                            <td>
                                <input
                                    name={`${i}-m`}
                                    onChange={handleTable()._set()._data}
                                    value={handleTable()._get()._data(`${i}-m`)}
                                />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <div className="var">
                                b<span>j</span>
                                <i>
                                    Потреб.
                                </i>
                            </div>
                        </td>
                        {handleTable()._get()._cols().map((_, i) =>
                            <td key={`table-body-col-${i}-${i}`}>
                                <input
                                    name={`n-${i}`}
                                    onChange={handleTable()._set()._data}
                                    value={handleTable()._get()._data(`n-${i}`)}
                                />
                            </td>
                        )}
                        <td>

                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            {handleTable()._get()._rows().length >= MAX_ALLOWED_TABLE_VALUES.rows ?
                <p className="message">
                    <span>*</span>Максималния позволен брой редове е {MAX_ALLOWED_TABLE_VALUES.rows}.
                </p>
                :
                <>
                </>
            }
            {handleTable()._get()._cols().length >= MAX_ALLOWED_TABLE_VALUES.cols ?
                <p className="message">
                    <span>*</span>Максималния позволен брой колони е {MAX_ALLOWED_TABLE_VALUES.cols}.
                </p>
                :
                <>
                </>
            }
        </>
    )
}

export default Table