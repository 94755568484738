import { forwardRef, useImperativeHandle, useState } from 'react'

//MUI
import { Button, Select, Tooltip, MenuItem, Checkbox, Radio, FormLabel, FormControlLabel } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'

//providers
import { useTestDataContext } from 'components/admin/test/_providers/Test'
import { convertSecondsToMinutes } from 'helpers/convertSeconds'

const Timer = ({ }, ref) => {
    // const moment = moment()
    const testData = useTestDataContext()
    const timeInSeconds = testData.timeInSeconds
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()
    const [state, setState] = useState({
        minutes: '',
        seconds: ''
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            setTime()
            handleModal()._show()
        },
        close: () => {
            handleModal()._hide()
        }
    }))

    const setTime = () => {
        setState(prev => ({
            ...prev,
            minutes: convertSecondsToMinutes(timeInSeconds).minutes,
            seconds: convertSecondsToMinutes(timeInSeconds).seconds
        }))
    }

    const handleChange = e => {
        let { name, value } = e.target

        if ((name === 'seconds') && (value > 59)) {
            value = 59
        }
        if ((name === 'minutes') && (value > 60)) {
            value = 60
        }

        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSave = () => {
        testData.handleTest()._setTimer(timeInSeconds ? state.minutes * 60 + state.seconds : null)
        handleModal()._hide()
    }

    const handleSwitch = e => {
        testData.handleTest()._setTimer(e.target.value == 1 ? '0' : null)
    }

    return (
        <div className={`overlay timer ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <div className={`modal ${modal.box ? 'show' : ''}`}>
                <div className="body">
                    <b>
                        Таймер на теста
                    </b>
                    <div className="row">
                        <FormControlLabel
                            checked={timeInSeconds === null}
                            name="max_time"
                            value={0}
                            control={<Radio />}
                            label="Изключен"
                            onChange={handleSwitch}
                        />
                    </div>
                    <div className="row">
                        <FormControlLabel
                            checked={timeInSeconds}
                            name="max_time"
                            value={1}
                            control={<Radio />}
                            label="Включен"
                            onChange={handleSwitch}
                        />
                    </div>
                    {timeInSeconds ?
                        <div className="row">
                            <input
                                type="number"
                                placeholder="ММ"
                                min="1"
                                max="60"
                                step="1"
                                name="minutes"
                                value={state.minutes}
                                onChange={handleChange}
                            />
                            <span className="dots">
                                :
                            </span>
                            <input
                                type="number"
                                placeholder="СС"
                                min="1"
                                max="59"
                                step="1"
                                name="seconds"
                                value={state.seconds}
                                onChange={handleChange}
                            />
                        </div>
                        :
                        <>
                        </>
                    }
                </div>
                <div className="footer">
                    <Button
                        className="secondary"
                        onClick={handleModal()._hide}
                    >
                        Отказ
                    </Button>
                    <Button
                        onClick={handleSave}
                    >
                        Запази
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Timer)