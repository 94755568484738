export const SORT_TYPE = {
    1: {
        sample: 'А, Б, В,...',
        code: ['А', 'Б', 'В', 'Г', 'Д']
    },
    2: {
        sample: '1, 2, 3,...',
        code: ['1', '2', '3', '4', '5']
    },
    3: {
        sample: 'I, II, III,...',
        code: ['I', 'II', 'III', 'IV', 'V']
    }
}