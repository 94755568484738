import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//components
import Head from "./components/Head"
import Table from "./components/Table"

//providers
import { useTeachersDataContext } from "./_providers/Teachers"

//components
import NoResultsFound from 'components/app/components/NoResultsFound'

const Teachers = () => {
    const location = useLocation()
    const teachersData = useTeachersDataContext()

    useEffect(() => {
        teachersData.handleTeachers()._fetch()
    }, [location.search, teachersData.refresh])

    return (
        <section id="admin-students">
            <div className="wrapper">
                <Head />
                {teachersData.isLoading ?
                    <>
                        <Skeleton className="thead" />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
                    :
                    teachersData.handleTeachers()._get()._list().length ?
                        <Table />
                        :
                        <NoResultsFound
                            text="Не бяха открити преподаватели в списъка."
                        />
                }
            </div>
        </section>
    )
}

export default Teachers