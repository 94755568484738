import { useRef } from 'react'
import { createPortal } from "react-dom"

//MUI
import { Button, Tooltip } from "@mui/material"

//providers
import { useAuthDataContext } from "providers/Auth"

//images
import changePasswordIcon from 'assets/images/students/profile/icons/change-password.svg'
import cameraIcon from 'assets/images/students/profile/icons/camera.svg'

//modals
import ChangePassword from "components/shared/modals/ChangePassword"

//providers
import { useAppContext } from 'providers/App'

//helpers
import api from 'helpers/api'

//constants
import { API_URL } from 'constants/apiUrls'

//hooks
import useFormValidationHook from 'hooks/useFormValidationHook'
import useDisplayUploadedImageHook from 'hooks/useDisplayUploadedImageHook'

let autoSaveTimeout
const Profile = () => {
    const {
        image,
        handleChange
    } = useDisplayUploadedImageHook()
    const validationHook = useFormValidationHook()
    const formRef = useRef()
    const imageFieldRef = useRef()
    const app = useAppContext()
    const changePasswordModalRef = useRef()
    const authData = useAuthDataContext()
    const { user } = authData.handleAuth()._get()._data()
    const {
        name,
        email,
        image_url,
    } = user || {}

    const handleSubmit = target => {
        const data = new FormData(target)

        api.post(API_URL.AUTH.TEACHERS.UPDATE, data)
            .then(() => {
                app.handleMessage()._success()._show('Данните Ви бяха запазени автоматично!')
            })
            .catch(err => {
                const { errors } = err.response.data
                validationHook.handleValidations()._set()._fields(errors)
                app.handleMessage()._error()._show('Данните Ви не бяха запазени. Моля, попълнете правилно всички полета!')
            })
    }

    const handleChangeField = e => {
        clearTimeout(autoSaveTimeout)
        autoSaveTimeout = setTimeout(() => {
            handleSubmit(formRef.current)
        }, 1000)

        handleChange(e)
    }

    return (
        <section id="student-profile">
            {createPortal(
                <ChangePassword
                    ref={changePasswordModalRef}
                    url={API_URL.AUTH.TEACHERS.CHANGE_PASSWORD}
                />,
                document.body)}
            <div className="wrapper">
                <div className="holder">
                    <div className="col">
                        <b>
                            Основна информация
                        </b>
                        <form
                            className="row user-main"
                            ref={formRef}
                            onChange={handleChangeField}
                        >
                            <input
                                type="file"
                                hidden
                                ref={imageFieldRef}
                                onChange={handleChangeField}
                                name="image"
                            />
                            <Tooltip title="Смяна на снимката">
                                <picture
                                    onClick={() => {
                                        imageFieldRef.current.click()
                                    }}
                                >
                                    <img src={image || image_url} />
                                    <span>
                                        <img src={cameraIcon} />
                                    </span>
                                </picture>
                            </Tooltip>
                            <div className="col">
                                <div className="field email">
                                    <input
                                        type="text"
                                        name="email"
                                        defaultValue={email}
                                        // onChange={handleChangeField}
                                        className={validationHook.handleValidations()._check('email') ? 'validate' : ''}
                                    />
                                </div>
                                <div className="field name">
                                    <input
                                        type="text"
                                        name="name"
                                        defaultValue={name}
                                        // onChange={handleChangeField}
                                        className={validationHook.handleValidations()._check('name') ? 'validate' : ''}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col">
                        <b>
                            Настройки
                        </b>
                        <div className="group change-pw">
                            <h6>
                                Смяна на паролата
                            </h6>
                            <p>
                                От тук може да смениш паролата си за достъп.
                            </p>
                            <Button
                                className="btn-primary"
                                onClick={() => {
                                    changePasswordModalRef.current.open()
                                }}
                            >
                                <img src={changePasswordIcon} />
                                Смени паролата
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Profile