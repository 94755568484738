import { Link } from 'react-router-dom'

//images
import testsIcon from 'assets/images/students/topics/icons/tests.svg'
import topicsIcon from 'assets/images/students/topics/icons/topics.svg'
import testIcon from 'assets/images/students/topics/icons/test.svg'
import topicIcon from 'assets/images/students/topics/icons/topic.svg'

//components
import Visibility from '../../components/Visibility'
import TopicMenu from 'components/teacher/components/TopicMenu'

const Item = ({ data }) => {
    const {
        id,
        image_url,
        name,
        no,
        total_lectures_count,
        total_tests_count,
        subject_id
    } = data

    /**
     * 
     * @returns {String|Null}
     */
    const getType = () => {
        if (Number(total_lectures_count) && !Number(total_tests_count)) {
            return 'Тема'
        } else if (!Number(total_lectures_count) && Number(total_tests_count)) {
            return 'Тестове'
        } else if (Number(total_lectures_count) && Number(total_tests_count)) {
            return 'Тема + тестове'
        }

        return null
    }
    return (
        <li>
            <i className="number">
                {no}
            </i>
            <img src={image_url} className="main" />
            {getType() ?
                <span className="included">
                    {getType()}
                </span>
                :
                <>
                </>
            }
            <p>
                {name}
            </p>
            <div className="footer">
                <span>
                    <i>
                        <img src={testsIcon} />
                    </i>
                    {total_lectures_count} Слайда
                </span>
                <span>
                    <i>
                        <img src={topicsIcon} />
                    </i>
                    {total_tests_count} Теста
                </span>
            </div>
            <div className="hover">
                <TopicMenu data={data} />
                <ul>
                    {Number(total_lectures_count) ?
                        <li className="topic">
                            <Link to={`/topic/${id}`}>
                                <span>
                                    <img src={topicIcon} />
                                </span>
                                Обучение
                            </Link>
                        </li>
                        :
                        <>
                        </>
                    }
                    {Number(total_tests_count) ?
                        <li className="test">
                            <Link to={`/education/tests/${subject_id}/${id}`}>
                                <span>
                                    <img src={testIcon} />
                                </span>
                                Тестове
                            </Link>
                        </li>
                        :
                        <>
                        </>
                    }
                </ul>
                <Visibility currentStatus={true} />
            </div>
        </li>
    )
}

export default Item