import { useState } from 'react'

const useTabsHook = () => {
    const [value, setValue] = useState(0)

    const handleTabs = () => {
        /**
         * 
         * @param {*} _ 
         * @param {any} newValue 
         */
        const _change = (_, newValue) => {
            setValue(newValue)
        }

        /**
         * 
         * @returns {undefined|VoidFunction}
         */
        const _prevTab = () => {
            if (value === 0) return

            setValue(value - 1)
        }

        const _nextTab = () => {
            setValue(value + 1)
        }

        const _resetValue = () => {
            _change('', 0)
        }

        return {
            _prevTab,
            _nextTab,
            _change,
            _resetValue
        }
    }

    return {
        value,
        setValue,
        handleTabs
    }
}

export default useTabsHook