//MUI
import { MenuItem, Select, Tooltip } from '@mui/material'

//hooks
import useChangeCourseHook from 'components/student/topics/hooks/useChangeCourseHooks'

const ChangeCoures = ({ data, selectedId }) => {
    const {
        handleChange,
        courseId
    } = useChangeCourseHook()
    return (
        <Tooltip title="Промяна на обучението" placement="right-end">
            <Select
                value={courseId || selectedId || data[0]?.id || null}
                onChange={handleChange}
                name="course"
            >
                {data.map(d =>
                    <MenuItem
                        key={`education-${d.id}`}
                        value={d.id}
                    >
                        {d.name}
                    </MenuItem>
                )}
            </Select>
        </Tooltip>
    )
}

export default ChangeCoures