//plugins
import { CKEditor } from '@ckeditor/ckeditor5-react'

//providers
import { useTestDataContext } from '../_providers/Test'

//MUI
import { Button, Tooltip } from '@mui/material'

//images
import addIcon from 'assets/images/admin/test/icons/add.svg'
import checkIcon from 'assets/images/admin/test/icons/check.svg'
import deleteIcon from 'assets/images/admin/topic/icons/delete.svg'

const Answers = () => {
    const topicData = useTestDataContext()
    const {
        handleActiveSlide,
        handleAnswer
    } = topicData
    const { answers } = handleActiveSlide()._get()._data().content

    /**
     * 
     * @param {Object} editor 
     * @param {Number} index
     */
    const handleChange = (editor, index) => {
        if (document.activeElement.classList.contains('ck-content')) changeAction(editor, index)
    }

    const changeAction = (editor, index) => {
        const value = editor.getData() || ''

        handleAnswer()._setContent(value, index)
    }


    /**
     * Дали да блокира бутона за добавяне на отоговори
     * @returns {Boolean}
     */
    const isAddDisabled = () => {
        if (answers.length >= 5) {
            return true
        }

        return false
    }

    /**
     * 
     * @returns {Boolean}
     */
    const isAnswerDeletable = () => {
        if (answers.length > 1) {
            return true
        }

        return false
    }

    /**
     * Показва дали има поне един отговор, който да е маркиран като грешен
     * @returns {Boolean}
     */
    const hasIncorrectAnswer = () => {
        let hasIncorrect = true
        const correctAnswer = answers.find(a => !a.isCorrect)

        if (!correctAnswer) {
            hasIncorrect = false
        }

        return hasIncorrect
    }

    return (
        <>
            {/*[!] Добавянето на клию, който да актуализира кода е решение на проблема с CKEditor.
        Проблема беше, че след предмахване на отговор, a.text се променя преди да е актуализира answers масива,
        оето от викаше onChange и създаваше копие на елемена, който е след изтрития. Поради тази причина,
        ако се изтрие последния елемент от списъка, нямаше проблем. [!]*/}
            <ul className="answers" key={answers.length}>
                {answers.map((a, i) =>
                    <li key={`answer-${i}`}>
                        <span>
                            {handleActiveSlide()._get()._sortType(i)}.
                        </span>
                        <CKEditor
                            editor={window.BalloonEditor}
                            data={a.text || ''}
                            config={{
                                placeholder: 'Въведете отговор...',
                            }}
                            onChange={(_, editor) => {
                                handleChange(editor, i)
                            }}
                            onBlur={(_, editor) => {
                                changeAction(editor, i)
                            }}
                        />
                        <div className="answer-options">
                            <Tooltip title={`Отбележи като ${a.isCorrect ? 'грешен' : 'верен'}`}>
                                <Button
                                    className={`value ${a.isCorrect ? 'green' : ''}`}
                                    onClick={() => {
                                        handleAnswer()._setValue(!a.isCorrect, i)
                                    }}
                                >
                                    <img src={checkIcon} />
                                </Button>
                            </Tooltip>
                            {isAnswerDeletable() ?
                                <Tooltip title="Премахване на отговора">
                                    <Button
                                        className="delete"
                                        onClick={() => {
                                            handleAnswer()._delete(i)
                                        }}
                                    >
                                        <img src={deleteIcon} />
                                    </Button>
                                </Tooltip>
                                :
                                <>
                                </>
                            }
                        </div>
                    </li>
                )}
            </ul>
            {isAddDisabled() ?
                <p className="message">
                    <span>*</span>Максималния позволен брой отговори е 5.
                </p>
                :
                <>
                </>
            }

            {!hasIncorrectAnswer() ?
                <p className="message">
                    <span>*</span>Всички отговори са посочени като верни!
                </p>
                :
                <>
                </>
            }
            <Button
                onClick={handleAnswer()._add}
                disabled={isAddDisabled()}
                className="add-answer"
            >
                <img src={addIcon} />
                Добави отговор
            </Button>
        </>
    )
}

export default Answers