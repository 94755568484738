import { forwardRef, useImperativeHandle, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//MUI
import { Button, Select, Tooltip, MenuItem } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'
import useLoadingHook from 'hooks/useLoadingHook'

//images
import closeIcon from 'assets/images/admin/modals/education/icons/close.svg'

//providers
import { useTestDataContext } from 'components/admin/test/_providers/Test'

//helpers
import api from 'helpers/api'

//constants
import { API_URL } from 'constants/apiUrls'

const Success = ({ }, ref) => {
    const params = useParams()
    const navigate = useNavigate()
    const loadingHook = useLoadingHook()
    const testId = Number(params.testId)
    const testData = useTestDataContext()
    const educationId = Number(params.educationId)

    const {
        modal,
        handleModal,
    } = useDisplayModalHook()
    const [state, setState] = useState({
        name: '',
        topicId: params.topicId,
        topicsList: []
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            handleModal()._show()
            if (!state.topicsList.length) getTopics()
            if (testData.name.length && (Number(testData.topicId) || 0) > 0) setData(testData.name, testData.topicId)
        },
        close: () => {
            handleModal()._hide()
        }
    }))

    /**
     * 
     * @param {String} name 
     * @param {Number} topicId 
     */
    const setData = (name, topicId) => {
        setState(prev => ({
            ...prev,
            name,
            topicId
        }))
    }

    const getTopics = () => {
        api.get(`${API_URL.TOPICS.GET}?subject_id=${educationId}`)
            .then(res => {
                const { items } = res.data
                setState(prev => ({
                    ...prev,
                    topicsList: items
                }))
            })
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (testId) {
            const { topicId } = params
            const data = new FormData(e.target)
            loadingHook.handleLoading()._start()
            data.append('subject_id', topicId)
            if (testId) data.append('test_id', testId)

            api.post(API_URL.TESTS.SIMPLE_EDIT, data)
                .then(res => {
                    if (testId) {
                        const { name, no, image_url } = res.data
                        // testData.handleTest()._set(name, no, image_url)
                        handleModal()._hide()
                    }
                })
                .finally(() => {
                    loadingHook.handleLoading()._stop()
                })
        } else {
            navigate(`/admin/test/${educationId}/${Number(state.topicId)}/null`)
            testData.handleTest()._set(state.name, state.topicsList.find(t => Number(t.id) === Number(state.topicId)).name || '', Number(state.topicId))
            handleModal()._hide()
        }
    }

    const handleChange = e => {
        const { name, value } = e.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    /**
     * Дали потребителя може да съхрани данните в provider
     * @returns {Boolean}
     */
    const isCreateAvailable = () => {
        if (state.name.length && Number(state.topicId)) {
            return true
        }

        return false
    }

    return (
        <div className={`overlay create-test ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <form className={`modal ${modal.box ? 'show' : ''}`} onSubmit={handleSubmit}>
                <div className="head">
                    <Button
                        className="close"
                        onClick={() => {
                            if (testId || testData.name.length) handleModal()._hide()
                            else navigate(-1)
                        }}
                    >
                        <img src={closeIcon} />
                    </Button>
                </div>
                <div className="body">
                    <b>
                        {testId ? 'Редакция' : 'Създаване'} на Тест
                    </b>
                    <p>
                        За да създадете тест, въведете име на теста в полето по долу.
                    </p>
                    <div className="col">
                        <label htmlFor="name">
                            Име на теста
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Въедете име..."
                            autoFocus={true}
                            onChange={handleChange}
                            value={state.name}
                        />
                    </div>
                    <Tooltip title="Темата не може да бъде променена">
                        {/* Темата тярбва да е избрана сама,
                        когато се кликне създаване на тест от някоя тема.
                        Да се вземе от GET параметър */}
                        <Select
                            value={!Boolean(Number(params.topicId)) && !Number(state.topicId) ? 't' : state.topicId}
                            onChange={handleChange}
                            name="topicId"
                            disabled={Boolean(Number(params.topicId))}
                        // className="disabled"
                        >
                            {!Boolean(Number(params.topicId)) ?
                                <MenuItem
                                    disabled
                                    value="t"
                                >
                                    Изберете тема
                                </MenuItem>
                                :
                                <>
                                </>
                            }
                            {(state.topicsList || []).map(t =>
                                <MenuItem
                                    key={`topic=${t.id}`}
                                    value={t.id}
                                >
                                    {t.name}
                                </MenuItem>
                            )}
                        </Select>
                    </Tooltip>
                </div>
                <div className="footer">
                    <Button
                        disabled={!isCreateAvailable()}
                        className={`${!isCreateAvailable() ? 'disabled' : ''} ${loadingHook.handleLoading()._isLoading() ? 'loading' : ''}`}
                        type="submit"
                    >
                        {testId ? 'Запазване' : 'Напред'}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(Success)