import { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'

//MUI
import { Button, Tooltip, Skeleton } from '@mui/material'

//components
import Timeline from './components/Timeline'
import Result from '../result'
import Table from './components/Table'
import Timer from './components/Timer'

//images
import backIcon from 'assets/images/students/test/icons/left-arrow.svg'

//helpers
import { getAnswersFontSize, getQuestionFontSize } from './helpers/fontSize'
import api from 'helpers/api'

//hooks
import useQuestionsAndAnswersHook from './hooks/useQuestionsAndAnswersHook'
import useLoadingHook from 'hooks/useLoadingHook'

//constants
import { API_URL } from 'constants/apiUrls'
import { SORT_TYPE } from 'components/admin/test/_constants/sortType'

//modals
import Question from 'components/modals/Question'

const Test = () => {
    const questionModalRef = useRef()
    const loadingHook = useLoadingHook()
    const params = useParams()
    const navigate = useNavigate()
    const testId = params.id
    const [test, setTest] = useState({
        questions: [],
        result: null,
        viewTest: true
    })

    const {
        state,
        setActiveQuestion,
        isActiveQuestion,
        handleAnswer,
        getAnswered,
        isAnswered,
        getFormatedQuestions,
        getParsedLocalStoraget,
        removeTestFromLocalStoraget
    } = useQuestionsAndAnswersHook(areResultsAvailable())

    useEffect(() => {
        getData()
        return () => {
            if (areResultsAvailable()) removeTestFromLocalStoraget()
        }
    }, [])

    useEffect(() => {
        if (test.questions?.length) {
            document.querySelector('.footer .active').scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
                block: 'start'
            })
        }
    }, [test.questions?.length, state.activeQuestionIndex])

    const getData = (counter = 1) => {
        api.get(`${API_URL.SHARED.TESTS_SHOW}?test_id=${testId}`)
            .then(res => {
                setTest(prev => ({
                    ...prev,
                    ...res.data.set,
                    topic: res.data.topic,
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
    }

    /**
     * 
     * @param {Number} typeId 
     * @returns {String}
     */
    const getQuestionType = typeId => {
        let type = ''
        switch (typeId) {
            case 2:
                type = 'text-image'
                break
            case 3:
                type = 'image-text'
                break
            default:
                type = 'text-text'
                break
        }

        return type
    }

    /**
     * 
     * @returns {Boolean}
     */
    const isNextDisabled = () => {
        if (test.questions.length - 1 === state.activeQuestionIndex) {
            return true
        }

        return false
    }

    /**
     * 
     * @returns boolean
     */
    const isPrevDisabled = () => {
        if (state.activeQuestionIndex === 0) {
            return true
        }

        return false
    }

    /**
     * 
     * @returns {Number}
     */
    const getAnswerdCount = () => Object.keys(state.answered).length

    /**
     * 
     * @returns {Boolean}
     */
    const areAllAnswered = () => {
        if (Object.keys(state.answered).length === test.questions.length) {
            return true
        }

        return false
    }

    const handleGiveTest = () => {
        if (areResultsAvailable()) {
            setViewTest(false)

            return
        }
        if (!areAllAnswered()) questionModalRef.current.open()
        else giveAction()
    }

    const giveAction = () => {
        if (areResultsAvailable()) return

        loadingHook.handleLoading()._start()
        api.post(API_URL.SHARED.TESTS_GIVE, {
            test_id: testId,
            questions: getFormatedQuestions()
        })
            .then(res => {
                const { set, result, topic_id, subject_id, topic, certificate } = res.data
                setTest(() => ({
                    ...set,
                    result,
                    viewTest: false,
                    topic_id,
                    subject_id,
                    topic,
                    certificate,
                    localStorage: getParsedLocalStoraget()
                }))
                removeTestFromLocalStoraget()
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    /**
     * 
     * @param {Number} typeId 
     * @returns {Boolean}
     */
    const isTable = typeId => {
        if (typeId === 3) {
            return true
        }

        return false
    }

    /**
     * 
     * @param {Boolean} viewTest 
     */
    const setViewTest = viewTest => {
        setTest(prev => ({
            ...prev,
            viewTest
        }))
    }

    /**
     * Дали теста е предаден и резултатие могат да бъдат видяни
     * @returns {Boolean}
     */
    function areResultsAvailable() {
        if (test.result) {
            return true
        }

        return false
    }

    return (
        <>
            {createPortal(
                <Question
                    ref={questionModalRef}
                    elementsContent={{
                        heading: 'Имате неотговорени въпроси! Сигурни ли сте, че искате да продължите?',
                        buttons: {
                            accept: 'Продължи'
                        }
                    }}
                    accept={giveAction}
                    cancel={() => {

                    }}
                />,
                document.body
            )}
            {!test.questions.length ?
                <div className="full-screen">
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton className="big" />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
                :
                areResultsAvailable() && !test.viewTest ?
                    <Result
                        data={test.result}
                        topicId={test.topic_id}
                        educationId={test.subject_id}
                        setViewTest={setViewTest}
                        certificate={test.certificate}
                    />
                    :
                    <section id="test">
                        <div className="header">
                            <div className="wrapper">
                                <div className="left">
                                    <Tooltip title="Назад">
                                        <Button
                                            variant="text"
                                            className="back"
                                            onClick={() => {
                                                navigate(-1)
                                            }}
                                        >
                                            <img src={backIcon} />
                                        </Button>
                                    </Tooltip>
                                    <div className="heading">
                                        Тема {test.topic?.no || 0}
                                    </div>
                                    <p>
                                        {test.name}
                                    </p>
                                </div>
                                {/* Когато остават по-малко от 10 сек. да се добави className="timeout" */}
                                {test.timeleft && !areResultsAvailable() ?
                                    <Timer
                                        totalTimeInSeconds={test.timeleft}
                                    />
                                    :
                                    <>
                                    </>
                                }
                                <div className="right">
                                    <div className="answered">
                                        Отговорени:
                                        <b>
                                            {getAnswerdCount()} от {test.questions.length}
                                        </b>
                                    </div>
                                    <Button
                                        className={`${areAllAnswered() ? 'ready' : ''} ${loadingHook.handleLoading()._isLoading() ? 'loading' : ''}`}
                                        onClick={handleGiveTest}
                                    >
                                        {areResultsAvailable() ?
                                            'РЕЗУЛТАТ'
                                            :
                                            'ПРЕДАЙ'
                                        }
                                    </Button>
                                </div>
                            </div>
                            {test.timeleft && !loadingHook.handleLoading()._isLoading() && !areResultsAvailable() ?
                                <Timeline
                                    totalTimeInSeconds={test.timeleft}
                                    giveAction={giveAction}
                                />
                                :
                                <>
                                </>
                            }
                        </div>
                        <div className="main">
                            <div className="wrapper">
                                <Button
                                    className={`changer prev ${isPrevDisabled() ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (!isPrevDisabled()) setActiveQuestion(state.activeQuestionIndex - 1)
                                    }}
                                >

                                </Button>
                                <ul className={`container ${loadingHook.handleLoading()._isLoading() ? 'disabled' : ''}`}>
                                    {test.questions.map((q, i) =>
                                        <li
                                            className={`${isActiveQuestion(i) ? 'active' : ''} ${getQuestionType(q.typeId)} ${getAnswered(q.id, q.correct).status ? 'answered' : ''}`}
                                            key={`q - id - ${q.id} `}
                                        >
                                            <div className="question">
                                                <ul className="info">
                                                    <li>
                                                        Отговори: {q.answers.length || 0}
                                                    </li>
                                                    <li>
                                                        Верни: {q.correct_answers_count || 0}
                                                    </li>
                                                    <li>
                                                        Точки: {q.points || 0}
                                                    </li>
                                                </ul>
                                                <h1 style={{ fontSize: getQuestionFontSize((q.question || '').length) }} dangerouslySetInnerHTML={{ __html: q.contents[0].value.question || q.contents[0].value || '' }} />
                                            </div>
                                            <div className="answers">
                                                {getQuestionType(q.typeId) === 'image-text' ?
                                                    <picture style={{ backgroundImage: `url(${q.image})` }}>

                                                    </picture>
                                                    :
                                                    <>
                                                    </>
                                                }
                                                {isTable(q.template_id) ?
                                                    <Table
                                                        data={q}
                                                    />
                                                    :
                                                    <>
                                                    </>
                                                }
                                                <ul className="type-list">
                                                    {q.answers.map((a, i) =>
                                                        // answered, correct, incorrect
                                                        <li
                                                            key={`a - id - ${q.id} -${a.id} `}

                                                            className={`answer ${isAnswered(q.id, a.id, a.is_correct) ? 'answered' : ''} ${areResultsAvailable() ? a.is_correct ? 'correct' : 'incorrect' : ''} `}
                                                            onClick={() => {
                                                                if (areResultsAvailable()) return
                                                                handleAnswer(q.id, a.id, a.is_correct)
                                                            }}
                                                            style={{
                                                                backgroundImage: getQuestionType(q.typeId) === 'text-image' ? `url(${a.image})` : ''
                                                            }}
                                                        >
                                                            <span className="code">
                                                                {SORT_TYPE[q.list_style_type_id].code[i]})
                                                            </span>
                                                            {getQuestionType(q.typeId) === 'text-image' ?
                                                                <>
                                                                </>
                                                                :
                                                                <p style={{ fontSize: getAnswersFontSize((a.name || '').length, q.length) }} dangerouslySetInnerHTML={{ __html: a.name || '' }} />
                                                            }
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                                <Button
                                    className={`changer next ${isNextDisabled() ? 'disabled' : ''} `}
                                    onClick={() => {
                                        if (!isNextDisabled()) setActiveQuestion(state.activeQuestionIndex + 1)
                                    }}
                                >

                                </Button>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="wrapper">
                                <ul>
                                    {test.questions.map((q, i) =>
                                        <li
                                            key={`q - id - mini - ${q.id} `}
                                            onClick={() => {
                                                setActiveQuestion(i)
                                            }}
                                            className={`${areResultsAvailable() ? q.is_correct ? 'correct' : 'incorrect' : ''} ${getQuestionType(q.typeId)} ${isActiveQuestion(i) ? 'active' : ''} `}
                                        >
                                            <b>
                                                {i + 1}
                                            </b>
                                            <div className="answers">
                                                {getQuestionType(q.typeId) === 'image-text' ?
                                                    <span>
                                                        <img src={q.image} />
                                                    </span>
                                                    :
                                                    q.answers.map(a =>
                                                        <span key={`a - mini - id - ${q.id} -${a.id} `}>
                                                            {getQuestionType(q.typeId) === 'text-image' ?
                                                                <img src={a.image} />
                                                                :
                                                                <>
                                                                </>
                                                            }
                                                        </span>
                                                    )}
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </section>}
        </>
    )
}

export default Test