import { Link } from "react-router-dom"

//images
import correctIcon from 'assets/images/students/tests/icons/correct.svg'
import incorrectIcon from 'assets/images/students/tests/icons/incorrect.svg'
import testIcon from 'assets/images/teachers/education/icons/test.svg'
import timeIcon from 'assets/images/students/tests/icons/time.svg'

//helpers
import { convertSecondsToMinutes } from "helpers/convertSeconds"
import { cutText } from "helpers/test"

const Item = ({ data, index }) => {
    const {
        name,
        time_spent,
        questions_count,
        id,
        result
    } = data
    
    return (
        <li>
            <Link to={`/test/${id}`}>
                <div className="left-part">
                    <b>
                        Тест {index + 1}
                    </b>
                    <div className={`test-icon ${result ? result.score > 2 ? 'good' : 'bad' : ''}`}>

                    </div>
                </div>
                <div className="right-part">
                    <p>
                        {cutText(name, 25)}
                    </p>
                    <ul className="results">
                        <li>
                            <span>
                                <img src={testIcon} />
                            </span>
                            {questions_count}
                        </li>
                        {result ?
                            <>
                                <li>
                                    <span>
                                        <img src={correctIcon} />
                                    </span>
                                    {result.correct_count}
                                </li>
                                <li>
                                    <span>
                                        <img src={incorrectIcon} />
                                    </span>
                                    {result.wrong_count}
                                </li>
                            </>
                            :
                            <>
                            </>
                        }
                        {result?.time_spent ?
                            <li>
                                <span>
                                    <img src={timeIcon} />
                                </span>
                                {convertSecondsToMinutes(result.time_spent).fullMinutes}:{convertSecondsToMinutes(result.time_spent).fullSeconds} мин.
                            </li>
                            :
                            <>
                            </>
                        }
                    </ul>
                </div>
            </Link>
        </li>
    )
}

export default Item