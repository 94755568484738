import { useEffect, useState } from 'react'

//helpers
import { toggleBodyOverflow } from 'helpers/overflow'

const useDisplayModalHook = () => {
    const [modal, setModal] = useState({
        overlay: false,
        box: false
    })

    const enableScroll = () => {
        const overlay = document.querySelector('.overlay.show')
        if(!overlay) return
        
        const modal = overlay?.querySelector('.modal')

        if (window.innerHeight <= modal.clientHeight) overlay.classList.add('scrollable')
        else overlay.classList.remove('scrollable')
    }

    //Ако се върне, да се направи проверка на местата, където не е позволено да се затваря модала
    //преди да се направи дадено действие.
    // useEffect(() => {
    //     document.body.addEventListener('keydown', e => {
    //         if (e.key === 'Escape') handleModal()._hide()
    //     })
    // }, [])

    const handleModal = () => {
        const _show = () => {
            setModal(prev => ({
                ...prev,
                overlay: true
            }))
            toggleBodyOverflow(true)

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    box: true
                }))
                enableScroll()
            }, 50)
        }

        const _hide = () => {
            setModal(prev => ({
                ...prev,
                box: false
            }))
            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    overlay: false
                }))
                toggleBodyOverflow(false)
            }, 100)
        }

        return {
            _show,
            _hide
        }
    }

    return ({
        modal,
        setModal,
        handleModal
    })
}

export default useDisplayModalHook