import { Link } from "react-router-dom"

const NotFound = () => {
    return (
        <section id="not-found">
            <div className="wrapper">
                <h1>
                    404
                </h1>
                <p>
                Страницата, която търсите не беше открита. Моля, върнете се в началото.
                </p>
                <Link to="/">
                    Към начало
                </Link>
            </div>
        </section>
    )
}

export default NotFound