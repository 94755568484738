//images
import { Tooltip } from '@mui/material'
import dsBrandImage from 'assets/images/app/ds-brand-white.svg'

const Footer = () => {
    return (
        <footer>
            <div className="wrapper">
                <p>
                    {document.title}. Всички права запазени.
                </p>
                <div className="software-partner">
                    <Tooltip title="Към сайта">
                        <a href="https://danielstandard.com" target="_blank" className="software-partner">
                            <img src={dsBrandImage} />
                        </a>
                    </Tooltip>
                </div>
            </div>
        </footer>
    )
}

export default Footer