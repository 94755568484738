import { useState, useEffect } from 'react'

//MUI
import { Skeleton } from '@mui/material'

//components
import List from './components/List'
import StudentStats from '../components/StudentStats'

//helpers
import api from 'helpers/api'

//constants
import { API_URL } from 'constants/apiUrls'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//providers
import { useStudentsEducationContext } from '../_providers/Education'

const Topics = () => {
    const sutndetsEducationData = useStudentsEducationContext()
    const selectedEducationId = sutndetsEducationData.handleEducation()._get()._selectedId()
    const loadingHook = useLoadingHook()
    const [state, setState] = useState({
        data: [],
        results: {}
    })
    useEffect(() => {
        if (selectedEducationId) getData()
    }, [selectedEducationId])

    const getData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.post(`${API_URL.STUDENTS.TOPICS}`, {
            subject_id: selectedEducationId
        })
            .then(res => {
                const { items, results } = res.data
                setState(prev => ({
                    ...prev,
                    data: items,
                    results
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <section id="student-topics">
            {loadingHook.handleLoading()._isLoading() || !sutndetsEducationData.handleEducation()._get()._count() ?
                <>
                    <div className="top-skeleton">
                        <Skeleton />
                    </div>
                    <div className="wrapper">
                        <div className="bottom">
                            <ul>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </ul>
                        </div>
                    </div>
                </>
                :
                <div className="wrapper">
                    <StudentStats
                        data={state.results}
                    />
                    <div className="bottom">
                        <List
                            data={state.data}
                        />
                    </div>
                </div>
            }
        </section>
    )
}

export default Topics