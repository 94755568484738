import { forwardRef, useImperativeHandle, useState } from 'react'
import { Link } from 'react-router-dom'

//MUI
import { Button } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'

//images
import successImage from 'assets/images/admin/modals/education/success.svg'
import closeIcon from 'assets/images/admin/modals/education/icons/close.svg'

const Success = ({ }, ref) => {
    const [state, setState] = useState({
        name: '',
        id: null
    })
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()

    useImperativeHandle(ref, () => ({
        open: data => {
            handleModal()._show()
            setState(data)
        },
        close: () => {
            handleModal()._hide()
        }
    }))

    return (
        <div className={`overlay create-education-success ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <div className={`modal ${modal.box ? 'show' : ''}`}>
                <div className="head">
                    <Button
                        className="close"
                        onClick={handleModal()._hide}
                    >
                        <img src={closeIcon} />
                    </Button>
                </div>
                <div className="body">
                    <img src={successImage} className="main" />
                    <b>
                        “{state.name}”
                    </b>
                    <h5>
                        е създадено успешно!
                    </h5>
                    <p>
                        Може да продължите със създаването на първата тема към Вашето обучение!
                    </p>
                </div>
                <div className="footer">
                    <Button>
                        <Link to={`/admin/topic/${state.id}/${null}`}>
                            Създаване на първа тема
                        </Link>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Success)