import { useLocation } from "react-router-dom"

export const useQuery = (query = null) => {
    const location = useLocation()

    if (query === null) {
        query = location.search
    }

    return new URLSearchParams(query)
}