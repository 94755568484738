export const API_URL = {
    AUTH: {
        STUDENTS: {
            PROFILE: 'auth/students/profile',
            UPDATE: 'auth/students/update',
            CHANGE_PASSWORD: 'auth/students/change-password',
            LOGIN: 'auth/students/login',
            REGISTER: 'auth/students/register',
        },
        TEACHERS: {
            PROFILE: 'auth/teachers/profile',
            CHANGE_PASSWORD: 'auth/teachers/change-password',
            UPDATE: 'auth/teachers/update',
            LOGIN: 'auth/teachers/login',
        }
    },
    STATISTICS: {
        STUDENTS: {
            ADD: 'admin/students/add',
            EDIT: 'admin/students/edit',
            GET: 'admin/students/get',
            DELETE: 'admin/students/delete',
            MOVE_ALL: 'admin/students/move-all'
        },
        TEACHERS: {
            ADD: 'admin/teachers/add',
            EDIT: 'admin/teachers/edit',
            GET: 'admin/teachers/get',
            DELETE: 'admin/teachers/delete'
        }
    },
    SHARED: {
        TESTS: 'tests',
        TOPICS: 'lectures',
        TESTS_SHOW: 'tests/show',
        TESTS_GIVE: 'tests/give'
    },
    STUDENTS: {
        TOPICS: 'students/topics',
        SUBJECTS: 'students/subjectlist',
        MARK_TOPIC: 'lectures/mark-as-completed',
        CERTIFICATES: 'students/certificates',
        SUBJECTS2: 'students/subjects',
    },
    SUBJECTS: {
        GET: 'admin/subjects/get',
        ADD: 'admin/subjects/add',
        DELETE: 'admin/subjects/delete',
        EDIT: 'admin/subjects/edit'
    },
    TOPICS: {
        GET: 'admin/topics/get',
        DELETE: 'admin/topics/delete',
        ADD: 'admin/topics/add',
        SHOW: 'admin/topics/show',
        EDIT: 'admin/topics/edit'
    },
    LECTURES: {
        ADD: 'admin/lectures/add',
        EDIT: 'admin/lectures/edit',
        SHOW: 'admin/lectures/show'
    },
    TESTS: {
        SIMPLE_EDIT: 'admin/tests/simple-edit',
        ADD: 'admin/tests/add',
        EDIT: 'admin/tests/edit',
        SHOW: 'admin/tests/show',
        GET: 'admin/tests/get',
        DELETE: 'admin/tests/delete',
    },
    CLASSLIST: 'classlist',
    FORMSLIST: 'formlist'
}