import { createContext, useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

//helpers
import api from "helpers/api"
import { API_URL } from "constants/apiUrls"

//providers
import { useAppContext } from "./App"

export const AuthDataContext = createContext({})

const initialAuthData = {
    isLogged: false,
    data: {},
}

const AuthDataProvider = props => {
    const app = useAppContext()
    const navigate = useNavigate()
    const [authData, setAuthData] = useState(initialAuthData)
    const currentAuthData = JSON.parse(localStorage.getItem('auth')) || initialAuthData

    useEffect(() => {
        handleAuth()._init()
        if (handleAuth()._get()._isLogged()) loadUser()
        else finishedUserCheck()
    }, [])

    const handleAuth = () => {
        /**
         * 
         * @param {Object} data 
         */
        const _login = data => {
            _set(true, data)
            navigate('/')
        }

        /**
         * 
         * @param {Boolean} isLogged 
         * @param {Object} data 
         */
        const _set = (isLogged, data) => {
            setAuthData(prev => ({
                ...prev,
                isLogged,
                data
            }))

            if (isLogged) localStorage.setItem('auth', JSON.stringify({ isLogged, data }))
            else localStorage.removeItem('auth')
        }

        const _init = () => {
            _set(currentAuthData.isLogged, currentAuthData.data)
        }

        const _get = () => {
            /**
             * 
             * @returns {Object}
             */
            const _data = () => Object.keys(authData?.data).length ? authData?.data : JSON.parse(localStorage.getItem('auth'))?.data || {}

            /**
             * 
             * @returns {Boolean}
             */
            const _isLogged = () => authData.isLogged || currentAuthData.isLogged

            /**
             * 
             * @returns {String}
             */
            const _type = () => _data().user?.type

            /**
             * 
             * @returns {Boolean}
             */
            const _isStudent = () => _type() === 'student'

            /**
             * 
             * @returns {Boolean}
             */
            //TO DO - Когато учителя се раздели на главен и обикновен, да се напрви проверката по-долу
            const _isAdmin = () => _type() === 'teacher'

            return {
                _data,
                _isLogged,
                _type,
                _isStudent,
                _isAdmin
            }
        }

        const _logout = () => {
            _set(initialAuthData.isLogged, initialAuthData.data)
            navigate('/')
        }

        return {
            _set,
            _get,
            _logout,
            _init,
            _login
        }
    }

    const loadUser = () => {
        api.get(API_URL.AUTH[handleAuth()._get()._isStudent() ? 'STUDENTS' : 'TEACHERS'].PROFILE)
            .then(res => {
                handleAuth()._set(true, { ...currentAuthData.data, user: res.data })
            })
            .catch(() => {
                handleAuth()._logout()
            })
            .finally(() => {
                finishedUserCheck()
            })
    }

    const finishedUserCheck = () => {
        app.fillInitialData({ user: true })
    }

    const auth = {
        handleAuth
    }

    return <AuthDataContext.Provider value={auth} {...props} />
}

export const useAuthDataContext = () => useContext(AuthDataContext)

export default AuthDataProvider
