const MOBILE_WIDTH_IN_PIXELS = 760

/**
 * 
 * @returns {Boolean}
 */
export const isMobile = () => {
    let mobile = window.innerWidth <= MOBILE_WIDTH_IN_PIXELS
    window.addEventListener('resize', () => {
        if (window.innerWidth <= MOBILE_WIDTH_IN_PIXELS) {
            mobile = true
        }
    })

    return mobile
}