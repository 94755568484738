//MUI
import { Button, Menu, MenuItem } from '@mui/material'

//images
import optionsIcon from 'assets/images/admin/teachers/icons/options.svg'
import editIcon from 'assets/images/teachers/education/icons/edit.svg'
import deleteIcon from 'assets/images/teachers/education/icons/delete.svg'

//hooks
import useMenuHook from 'hooks/useMenuHook'

//providers
import { useTeachersDataContext } from '../_providers/Teachers'
import { useAuthDataContext } from 'providers/Auth'

const Row = ({ data, index }) => {
    const auth = useAuthDataContext()
    const teachersData = useTeachersDataContext()
    const {
        name,
        email,
        role
    } = data

    const {
        opened,
        anchorEl,
        setAnchorEl
    } = useMenuHook()

    const handleOptions = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        if (anchorEl) setAnchorEl(null)
    }

    const handleEdit = () => {
        teachersData.handleTeachers()._edit()._prepare(data)
        handleCloseMenu()
    }

    const handleDelete = () => {
        teachersData.handleTeachers()._remove()._prepare(data)
        handleCloseMenu()
    }

    return (
        <tr>
            <td>
                <div>
                    {index + 1}
                </div>
            </td>
            <td>
                <div>
                    <img src={data.image_url} className="profile-picture" />
                    {name}
                </div>
            </td>
            <td>
                <div>
                    {email}
                </div>
            </td>
            <td>
                <div>
                    <span className={`status ${role ? 'blue' : ''}`}>
                        {role?.name || 'Преподавател'}
                    </span>
                </div>
            </td>
            <td className="centered">
                <div>
                    <Button
                        onClick={handleOptions}
                    >
                        <img src={optionsIcon} />
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={opened}
                        onClose={handleCloseMenu}
                        className="education-menu"
                    >
                        <MenuItem
                            onClick={handleEdit}
                        >
                            <img src={editIcon} />
                            Редакция
                        </MenuItem>
                        <hr />
                        <MenuItem
                            className="delete"
                            onClick={handleDelete}
                        >
                            <img src={deleteIcon} />
                            Изтриване
                        </MenuItem>
                    </Menu>
                </div>
            </td>
        </tr>
    )
}

export default Row