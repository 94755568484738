import { createContext, useContext, useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

//plugins
import { cloneDeep } from 'lodash'

//constants
import { TOPIC_TEMPLATES } from 'constants/topicTemplates'
import { API_URL } from "constants/apiUrls"

//helpers
import api from "helpers/api"

//providers
import { useAppContext } from "providers/App"

export const TopicsDataContext = createContext({})

const SLIDE_EXAMPLE = {
    id: new Date().getTime(),
    typeId: 1,
    duplicated: false,
    activeTemplateId: Object.keys(TOPIC_TEMPLATES)[0],
    content: {
        images: [],
        heading: '',
        text: ''
    }
}
const TopicDataProvider = props => {
    const app = useAppContext()
    const navigate = useNavigate()
    const params = useParams()
    const topicId = Number(params.topicId)
    const [state, setState] = useState({
        name: '',
        no: '',
        image: '',
        imageBlob: '',
        slides: {
            list: [cloneDeep(SLIDE_EXAMPLE)],
            activeIndex: 0
        },
        newSlideAdded: null
    })

    useEffect(() => {
        if (state.newSlideAdded) {
            handleActiveSlide()._set(getClonedSlidesList().length - 1)
        }
    }, [state.newSlideAdded])

    const handleTopic = () => {
        const _setSlides = slides => {
            const list = []

            slides.map(s => {
                const getHeading = () => {
                    return s.contents.find(c => c.content_type_id === 1)?.value || ''
                }
                const getText = () => {
                    return s.contents.find(c => c.content_type_id === 2)?.value || ''
                }
                const getImages = () => {
                    return s.contents.find(c => c.content_type_id === 3)?.value || []
                }

                list.push({
                    id: s.id,
                    activeTemplateId: s.template_id,
                    content: {
                        images: getImages(),
                        heading: getHeading(),
                        text: getText(),
                    }
                })
            })

            handleSlide()._save(list)
        }

        const _set = (name, no, image) => {
            setState(prev => ({
                ...prev,
                name,
                no,
                image,
            }))
        }

        /**
         * 
         * @param {String} imageBlob 
         */
        const _setImageBlob = imageBlob => {
            setState(prev => ({
                ...prev,
                imageBlob
            }))
        }

        const _save = async () => {
            const slides = []
            getClonedSlidesList().map(s => {
                const contents = []
                if (s.content.heading.length) {
                    contents.push({
                        type_id: 1,
                        value: s.content.heading
                    })
                }

                if (s.content.text.length) {
                    contents.push({
                        type_id: 2,
                        value: s.content.text
                    })
                }

                if (s.content?.images?.length) {
                    contents.push({
                        type_id: 3,
                        value: s.content.images
                    })
                }
                if (contents.length) {
                    slides.push({
                        id: s.id,
                        template_id: s.activeTemplateId,
                        contents
                    })
                }
            })

            let data = {
                name: state.name,
                no: state.no,
                subject_id: params.educationId,
                slides
            }

            if (state.imageBlob) {
                data = {
                    ...data,
                    image: state.imageBlob
                }
            }
            if (topicId) {
                data = {
                    ...data,
                    topic_id: topicId
                }
            }
            await api.post(API_URL.LECTURES[topicId ? 'EDIT' : 'ADD'], data)
                .then(res => {
                    const { id } = res.data
                    navigate(`/admin/topic/${params.educationId}/${id}`)
                    app.handleMessage()._success()._show('Темата беше запазена успешно!')
                })
                .catch(() => {
                    app.handleMessage()._error()._show('Нещо се обърка!')
                })

        }

        return {
            _set,
            _save,
            _setSlides,
            _setImageBlob
        }
    }

    /**
     * Връща клониран списък на слайдовете.
     * Използва се, за да не се променя state при директна мнипулация със масива 
     * @returns {Array}
     */
    const getClonedSlidesList = () => cloneDeep(state.slides.list)

    /**
     * Всички опрации за слайдовете
     * @returns {Object}
     */
    const handleSlide = () => {
        /**
         * 
         * @param {Array} list 
         */
        const _save = list => {
            setState(prev => ({
                ...prev,
                slides: {
                    ...prev.slides,
                    list
                }
            }))
        }

        const _add = () => {
            setState(prev => ({
                ...prev,
                slides: {
                    ...prev.slides,
                    list: [
                        ...prev.slides.list,
                        {
                            ...cloneDeep(SLIDE_EXAMPLE),
                            //Да се махне, когато се работи с реални данни
                            // id: new Date().getTime()
                        }
                    ],
                },
                newSlideAdded: new Date().getTime()
            }))
        }

        /**
         * 
         * @param {Number} index 
         */
        const _delete = index => {
            handleActiveSlide()._set(index === 0 ? index : index - 1)
            const updatedList = getClonedSlidesList().filter((s, i) => Number(index) != Number(i))

            _save(updatedList)
        }

        /**
         * 
         * @param {Number} index 
         */
        const _duplicate = index => {
            //[!] Ако някога някъде се счпуи някой масив ДА СЕ ПРОВЕРИ ТУК [!]
            Array.prototype.insert = function (index, ...items) {
                this.splice(index, 0, ...items)
            }
            //[!] КРАЙ [!]

            const updatedList = getClonedSlidesList()
            const element = {
                ...updatedList[index],
                id: new Date().getTime(),
                duplicated: true
            }
            updatedList.insert(index + 1, element)

            _save(updatedList)
            handleActiveSlide()._set(index + 1)
        }

        /**
         * Запазва данните на слайда в контекст
         * @param {String} name 
         * @param {String} value 
         * @param {Number} index 
         */
        const _set = (name, value) => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (handleActiveSlide()._get()._index() === i) {
                    s.content[name] = value
                }

                return s
            })

            _save(updatedList)
        }

        return {
            _add,
            _delete,
            _duplicate,
            _set,
            _save
        }
    }

    /**
     * Всички опрации за активния слайд
     * @returns {Object}
     */
    const handleActiveSlide = () => {
        /**
         * Задава активния слайд
         * @param {Number} activeIndex 
         * @returns {undefined|VoidFunction}
         */
        const _set = activeIndex => {
            if (state.slides.activeIndex === activeIndex) return
            setState(prev => ({
                ...prev,
                slides: {
                    ...prev.slides,
                    activeIndex
                }
            }))
        }

        /**
         * Съдъжа _data и _index
         * @returns {Object}
         */
        const _get = () => {
            /**
             * Връща данните на активния слайд
             * @returns {Object}
             */
            const _data = () => getClonedSlidesList()[_index()]

            /**
             * Връща индекса на активняи слайд
             * @returns {Number}
             */
            const _index = () => state.slides.activeIndex

            return {
                _data,
                _index
            }
        }

        return {
            _set,
            _get,
        }
    }

    /**
     * 
     * @returns {Boolean}
     */
    const isSlideDeletable = () => {
        if (getClonedSlidesList().length > 1) {
            return true
        }

        return false
    }

    const handleActiveTemplate = () => {
        /**
        * @param {String|Number}
        * @returns {Object}
        */
        const _get = (index = handleActiveSlide()._get()._index()) => TOPIC_TEMPLATES[getClonedSlidesList()[index].activeTemplateId]

        /**
         * 
         * @param {Number} templateId 
         */
        const _set = templateId => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                if (handleActiveSlide()._get()._index() === i) {
                    s.activeTemplateId = templateId
                }

                return s
            })

            handleSlide()._save(updatedList)
        }

        return {
            _get,
            _set
        }
    }

    /**
     * 
     * @returns {Object}
     */
    const handleGallery = () => {
        const _delete = itemToDelete => {
            const updatedList = getClonedSlidesList().map((s, i) => {
                const { images } = s.content
                if (handleActiveSlide()._get()._index() === i) {
                    if (itemToDelete === 'all') {
                        s.content.images = []
                    } else {
                        s.content.images = images.filter((_, i) => i != itemToDelete)
                    }
                }

                return s
            })

            handleSlide()._save(updatedList)
        }

        return {
            _delete
        }
    }

    const exportedData = {
        ...state,
        handleSlide,
        handleActiveSlide,
        isSlideDeletable,
        handleActiveTemplate,
        handleGallery,
        handleTopic
    }

    return <TopicsDataContext.Provider value={exportedData} {...props} />
}

export const useTopicDataContext = () => useContext(TopicsDataContext)

export default TopicDataProvider