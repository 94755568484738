import { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//components
import CreateNew from "../components/CreateNew"
import TeacherStats from "../components/TeacherStats"
import List from "./components/List"

//helpers
import api from "helpers/api"
import { API_URL } from "constants/apiUrls"

//modals
import RemoveModal from "components/modals/Remove"
import RemoveTopicModal from "components/modals/Remove"

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//providers
import { useEducationDataContext } from '../_providers/Education'
import { useAppContext } from 'providers/App'

const Topics = () => {
    const app = useAppContext()
    const navigate = useNavigate()
    const removeModalRef = useRef()
    const removeTopicModalRef = useRef()
    const loadingHook = useLoadingHook()
    const params = useParams()
    const educationData = useEducationDataContext()
    const educationId = params.id
    const [state, setState] = useState({
        data: {}
    })

    useEffect(() => {
        getData()
    }, [educationData.refresh])

    useEffect(() => {
        if (Object.keys(educationData.remove).length) removeModalRef.current.open()
        else removeModalRef.current.close()
    }, [educationData.remove])

    useEffect(() => {
        if (Object.keys(educationData.topic.remove).length) removeTopicModalRef.current.open()
        else removeTopicModalRef.current.close()
    }, [educationData.topic.remove])

    const getData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.get(`${API_URL.TOPICS.GET}?subject_id=${educationId}`)
            .then(res => {
                const { data } = res

                setState(prev => ({
                    ...prev,
                    data
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
                else navigate('/')
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <section id="teacher-topics">
            <RemoveModal
                ref={removeModalRef}
                elementsContent={{
                    heading: `Искате ли да изтриете "${educationData.remove.name}"?`,
                    buttons: {
                        accept: 'Изтрий обучението'
                    }
                }}
                accept={async () => {
                    await educationData.handleEducation()._remove()._process()
                    removeModalRef.current.close()
                    navigate('/')
                    app.handleMessage()._success()._show('Обучението беше изтрито успешно!')
                }}
                cancel={educationData.handleEducation()._remove()._reset}
            />
            <RemoveTopicModal
                ref={removeTopicModalRef}
                elementsContent={{
                    heading: `Искате ли да изтриете "${educationData.topic.remove.name}"?`,
                    buttons: {
                        accept: 'Изтрий темата'
                    }
                }}
                accept={async () => {
                    await educationData.handleTopic()._remove()._process()
                    removeTopicModalRef.current.close()
                    app.handleMessage()._success()._show('Темата беше изтрита успешно!')
                }}
                cancel={educationData.handleTopic()._remove()._reset}
            />
            {loadingHook.handleLoading()._isLoading() ?
                <>
                    <div className="top-skeleton">
                        <Skeleton />
                    </div>
                    <div className="wrapper bottom">
                        <ul>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </ul>
                    </div>
                </>
                :
                <>
                    <TeacherStats
                        data={state.data}
                    />
                    <div className="wrapper bottom">
                        <List
                            data={state.data}
                        />
                    </div>
                </>
            }
            <CreateNew mainIndex={1} />
        </section>
    )
}

export default Topics