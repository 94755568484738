import ReactDOM from 'react-dom/client'
import App from 'components/app/App'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'

//providers
import AppProvider from 'providers/App'
import AuthDataProvider from 'providers/Auth'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AppProvider>
      <AuthDataProvider>
        <App />
      </AuthDataProvider>
    </AppProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
reportWebVitals()
