import { useRef } from 'react'

//MUI
import { Button, Tooltip } from '@mui/material'

//images
import imageImage from 'assets/images/admin/modals/education/image.svg'
import uploadIcon from 'assets/images/admin/modals/education/icons/upload.svg'

//hooks
import useDisplayUploadedImageHook from 'hooks/useDisplayUploadedImageHook'

//providers
import { useEducationModalDataContext } from '../_providers/EducationModal'

const Image = ({ value }) => {
    const educationModalData = useEducationModalDataContext()
    const {
        image,
        handleChange
    } = useDisplayUploadedImageHook()
    const inputFileRef = useRef()

    const imageUrl = educationModalData.state.image || ''

    return (
        <div className={`slide-${value + 1} ${value === 1 ? 'show' : ''}`}>
            <h6>
                Стъпка 2
            </h6>
            <b>
                Изображение на обучението
            </b>
            <p>
                С кликване върху полето може да смените изображението с избрана снимка от Вашият компютър.
            </p>
            <input
                type="file"
                ref={inputFileRef}
                hidden
                name="image"
                onChange={e => {
                    handleChange(e)
                    educationModalData.handleModal()._change(e)
                }}
                accept="image/*"
            />
            <Tooltip title={image ? 'Промяна на изображението' : 'Прикачване на изображение'}>
                <Button
                    className="image"
                    onClick={() => {
                        inputFileRef.current.click()
                    }}
                >
                    {!image && !imageUrl.length ?
                        <div className="upload">
                            <img src={imageImage} />
                            <span>
                                <img src={uploadIcon} />
                                Качи изображение
                            </span>
                        </div>
                        :
                        <img src={image || imageUrl} className="main" />
                    }
                </Button>
            </Tooltip>
        </div>
    )
}

export default Image