import { Routes, Route } from 'react-router-dom'
import { createPortal } from 'react-dom'

//styles
import 'assets/scss/app.scss'

//components (pages)
import StudentTopics from '../student/topics'
import Test from '../student/test'
import Tests from '../student/topic/tests'
import Topic from '../student/topic/slides'
import Result from 'components/student/result'
import Subtopics from 'components/student/topic/subtopics'
import StudentProfile from 'components/student/profile'

//
import TeacherEducation from '../teacher/education'
import TeacherTopics from '../teacher/topics'
import TeacherTests from '../teacher/topic/tests'
import TeacherSubtopics from '../teacher/topic/subtopics'
import TeacherStatistics from '../teacher/statistics'
import TeacherProfile from 'components/teacher/profile'
//
import AdminTopic from '../admin/topic'
import AdminTest from '../admin/test'
import AdminTeachers from '../admin/teachers'
import AdminStudents from '../admin/students'
//
import Chose from 'components/auth/login/Chose'
import Login from 'components/auth/login'
import Register from 'components/auth/register'

//providers
import { useAppContext } from 'providers/App'
import { useAuthDataContext } from 'providers/Auth'
import EducationDataProvider from 'components/teacher/_providers/Education'
import TopicDataProvider from 'components/admin/topic/_providers/Topic'
import TestDataProvider from 'components/admin/test/_providers/Test'
import StudentsEducationDataProvider from 'components/student/_providers/Education'
import StudentsDataProvider from 'components/admin/students/_providers/Students'
import TeachersDataProvider from 'components/admin/teachers/_providers/Teachers'

//components (partials)
import SuccessMessage from './components/SuccessMessage'
import ErrorMessage from './components/ErrorMessage'

//components
import Header from 'components/app/components/header'
import Footer from './components/footer'
import InitialScreen from './components/InitialScreen'
import NotFound from './components/404'

//hooks
import useExcludeHeaderHook from './_hooks/useExcludeHeaderHook'

const App = () => {
    const auth = useAuthDataContext()
    const app = useAppContext()

    const {
        excludeHeader
    } = useExcludeHeaderHook()

    const routes =
        <>
            {!excludeHeader(auth.handleAuth()._get()) ? <Header /> : <></>}
            <Routes key={auth.handleAuth()._get()._isLogged()}>
                {!auth.handleAuth()._get()._isLogged() ?
                    <>
                        <Route
                            path="/"
                            exact
                            element={<Chose />}
                        />
                        <Route path="/login" exact element={<Login />} />
                        <Route path="/register" exact element={<Register />} />
                    </>
                    :
                    <>
                        <Route
                            path="/"
                            exact
                            element={
                                auth.handleAuth()._get()._isStudent() ?
                                    <StudentsEducationDataProvider>
                                        <StudentTopics />
                                    </StudentsEducationDataProvider>
                                    :
                                    <EducationDataProvider>
                                        <TeacherEducation />
                                    </EducationDataProvider>
                            }
                        />
                        <Route
                            path="topic/:id"
                            element={<Topic />}
                        />
                        <Route
                            path="test/:id"
                            element={<Test />}
                        />
                        <Route
                            path="result/:testId"
                            element={<Result />}
                        />
                        <Route
                            path="profile"
                            element={!auth.handleAuth()._get()._isStudent() ? <TeacherProfile /> : <StudentProfile />}
                        />
                        {!auth.handleAuth()._get()._isStudent() ?
                            <>
                                <Route path="education">
                                    <Route
                                        path=":id"
                                        element={
                                            <EducationDataProvider>
                                                <TeacherTopics />
                                            </EducationDataProvider>
                                        }
                                    />
                                    <Route
                                        path="tests/:educationId/:topicId"
                                        element={
                                            <EducationDataProvider>
                                                <TeacherTests />
                                            </EducationDataProvider>
                                        }
                                    />
                                    <Route
                                        path="students"
                                        element={
                                            <StudentsDataProvider>
                                                <AdminStudents />
                                            </StudentsDataProvider>
                                        }
                                    />
                                    {/* <Route
                                path="subtopics/:id"
                                element={
                                    <EducationDataProvider>
                                        <TeacherSubtopics />
                                    </EducationDataProvider>
                                }
                            /> */}
                                    {/* <Route
                                path="statistics"
                                element={<TeacherStatistics />}
                            /> */}
                                </Route>
                                <Route path="admin">
                                    <Route
                                        path="topic/:educationId/:topicId"
                                        element={
                                            <TopicDataProvider>
                                                <AdminTopic />
                                            </TopicDataProvider>
                                        }
                                    />
                                    <Route
                                        path="test/:educationId/:topicId/:testId"
                                        element={
                                            <TestDataProvider>
                                                <AdminTest />
                                            </TestDataProvider>
                                        }
                                    />
                                    <Route
                                        path="teachers"
                                        element={
                                            <TeachersDataProvider>
                                                <AdminTeachers />
                                            </TeachersDataProvider>
                                        }
                                    />
                                </Route>

                            </>
                            :
                            <>
                                <Route
                                    path="tests/:educationId/:topicId"
                                    element={
                                        <StudentsEducationDataProvider>
                                            <Tests />
                                        </StudentsEducationDataProvider>
                                    }
                                />
                            </>
                        }
                    </>
                }
                <Route
                    path="*"
                    element={<NotFound />}
                />
            </Routes>
            {!excludeHeader(auth.handleAuth()._get()) ? <Footer /> : <></>}
        </>

    const messages = <>
        <SuccessMessage />
        <ErrorMessage />
    </>

    return (
        app.initialLoading ?
            <InitialScreen />
            :
            <>
                {routes}
                {createPortal(messages, document.body)}
            </>
    )
}

export default App