//images
import template1Image from 'assets/images/admin/test/icons/template-1.svg'
import template2Image from 'assets/images/admin/test/icons/template-2.svg'
import template3Image from 'assets/images/admin/test/icons/template-3.svg'

export const TEST_TEMPLATES = {
    1: {
        type: 'question-answers',
        icon: template1Image,
        description: 'Въпрос и отговори',
    },
    // 2: {
    //     type: 'question-open-answer',
    //     icon: template2Image,
    //     description: 'Въпрос с отворен отговор',
    // },
    3: {
        type: 'table-question-open-answer',
        icon: template3Image,
        description: 'Таблица с отворен отговор',
    },
}