//helpers
import { cutText } from "helpers/test"
import { useTestDataContext } from "../_providers/Test"

const MiniAnswers = ({ answers }) => {
    const testData = useTestDataContext()

    const { handleActiveSlide } = testData
    return (
        <ul className="answers">
            {answers.map((a, i) =>
                <li key={`mini-question-${a.id}-${i}`}>
                    <b>
                        {handleActiveSlide()._get()._sortType(i)}.
                    </b>
                    <p dangerouslySetInnerHTML={{ __html: a.text }} />
                </li>
            )}
        </ul>
    )
}

export default MiniAnswers