
//components
import EducationMenu from "../components/EducationMenu"
import EducationContentIndicator from "../education/components/EducationContentIndicator"
import TopicMenu from "./TopicMenu"

const TeacherStats = ({ data, inner }) => {
    const currentData = data.topic || data.subject || {}
    const {
        name,
        tests_count,
        topics_count,
        students_count,
        total_lectures_count,
        total_tests_count,
        subject
    } = currentData
    
    return (
        <div className="top">
            <div className="wrapper">
                <div className="left">
                    <EducationContentIndicator content="both" />
                    <div className="info">
                        <h1>
                            {name} <span>{currentData?.class?.short_name}{Number(currentData?.class?.short_name) > 8 ? '-ти' : '-ми'} </span> клас
                        </h1>
                        <p>
                            Обучение + Тестове
                        </p>
                    </div>
                </div>
                <div className="right">
                    <ul>
                        <li>
                            {topics_count || total_lectures_count || 0}
                            <span>
                                {topics_count ? 'Теми' : 'Слайда'}
                            </span>
                        </li>
                        <li>
                            {tests_count || total_tests_count || 0}
                            <span>
                                Теста
                            </span>
                        </li>
                        <li>
                            {students_count || subject?.students_count || 0}
                            <span>
                                Човека
                            </span>
                        </li>
                    </ul>
                    {inner ?
                        <TopicMenu data={data.topic || {}} />
                        :
                        <EducationMenu data={data.subject || {}} />
                    }
                </div>
            </div>
        </div>
    )
}

export default TeacherStats