import { Link } from "react-router-dom"

//MUI
import { Button } from "@mui/material"

//images
import teacherIcon from 'assets/images/auth/chose/icons/teacher.png'
import studentIcon from 'assets/images/auth/chose/icons/student.png'
import brandImage from 'assets/images/app/brand.svg'


//components
import Brand from "../components/Brand"

const Chose = () => {
    return (
        <section id="chose">
            <div className="wrapper">
                <div className="school">
                    <img src={brandImage} />
                    <h2>
                        ПГТСТ „Гоце Делчев“
                        <br />
                        гр. Пловдив
                    </h2>
                </div>
                <b>
                    ОБУЧЕНИЕ ПО
                </b>
                <h1>
                    Спедиция и Логистика
                </h1>
                <p>
                    Всички учебни материали по Спедиция и Логистика събрани на едно място. Преподавателите имат възможност да съставят слайдове по всяка тема от учебното съдържание, както и тестове за проверка на знанията, а учениците последователно се обучават и затвърждават знанията си.
                </p>
                <ul>
                    <li>
                        <Button>
                            <Link to="/login?type=1">
                                <img src={studentIcon} />
                                <h5>
                                    Вход за ученици
                                </h5>
                                <p>
                                    За да използвате системата е необходимо да влезете във Вашият профил.
                                </p>
                            </Link>
                        </Button>
                    </li>
                    <li>
                        <Button>
                            <Link to="/login?type=2">
                                <img src={teacherIcon} />
                                <h5>
                                    Вход за преподаватели
                                </h5>
                                <p>
                                    Вход в системата само за преподаватели на съответните предмети.
                                </p>
                            </Link>
                        </Button>
                    </li>
                </ul>
            </div>
            <Brand />
        </section>
    )
}

export default Chose