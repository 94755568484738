import { createContext, useContext, useEffect, useState } from "react"

//helpers
import api from "helpers/api"
import { API_URL } from "constants/apiUrls"

export const AppContext = createContext({})

const messages = {
    error: {
        show: false,
        text: ''
    },
    success: {
        show: false,
        text: ''
    },
}
const initialState = {
    initialData: {
        user: false,
        classesList: false
    },
    initialLoading: true,
    messages,
    classesList: [],
    formsList: []
}
let successMessageTimeout
let errorMessageTimeout
const AppProvider = props => {
    const [state, setState] = useState({
        ...initialState
    })

    useEffect(() => {
        handleClassesList()._get()._data()
        handleEducationFormsList()._get()._data()
    }, [])

    /**
     * 
     * @returns {Object}
     */
    const handleMessage = () => {
        /**
         * 
         * @param {Boolean} show 
         * @param {String} message 
         */
        const _toggle = (show, message, text) => {
            setState(prev => ({
                ...prev,
                messages: {
                    ...prev.messages,
                    [message]: {
                        ...prev.messages[message],
                        show,
                        text
                    }
                }
            }))
        }

        const _error = () => {
            const _show = text => {
                _success()._hide()
                _toggle(true, 'error', text)
                clearTimeout(errorMessageTimeout)
                errorMessageTimeout = setTimeout(_hide, 2000)
            }
            const _hide = () => {
                _toggle(false, 'error')
            }

            return {
                _show,
                _hide
            }
        }

        const _success = () => {
            const _show = text => {
                _error()._hide()
                _toggle(true, 'success', text)
                clearTimeout(successMessageTimeout)
                successMessageTimeout = setTimeout(() => {
                    _hide()
                }, 2000)
            }
            const _hide = () => {
                _toggle(false, 'success')
            }

            return {
                _show,
                _hide
            }
        }

        return {
            _error,
            _success
        }
    }

    /**
     * Зареждащ екран на приложението
     * @param {Boolean} initialLoading
     */
    const setInitialLoading = initialLoading => {
        setState(prev => ({
            ...prev,
            initialLoading
        }))
    }

    /**
     * След като всички стойности в state.initialData са true, зареждането спира и се визуализра страницата
     */
    useEffect(() => {
        let isEverithingLoaded = true
        Object.values(state.initialData).map(d => {
            if (isEverithingLoaded) {
                isEverithingLoaded = d
            }
        })

        if (isEverithingLoaded) {
            setInitialLoading(false)
        }
    }, [state.initialData])

    /**
     * Към тази функция се подават всички данни, които подлежат на проверка при зареждане на страницата.
     * Също така трябва да се добавят първоначлните им стойности - false в initialData
     * @param {Object} data 
     */
    const fillInitialData = data => {
        setState(prev => ({
            ...prev,
            initialData: {
                ...prev.initialData,
                ...data
            }
        }))
    }

    const handleClassesList = () => {
        const _get = () => {
            const _data = (counter = 1) => {
                api.get(API_URL.CLASSLIST)
                    .then(res => {
                        _set(res.data.items)
                        fillInitialData({ classesList: true })
                    })
                    .catch(() => {
                        if (counter <= 3) _data(counter + 1)
                    })
            }

            /**
             * 
             * @returns {Array}
             */
            const _list = () => state.classesList || []

            return {
                _data,
                _list
            }
        }

        /**
         * 
         * @param {Array} classesList 
         */
        const _set = classesList => {
            setState(prev => ({
                ...prev,
                classesList
            }))
        }

        return {
            _get
        }
    }

    const handleEducationFormsList = () => {
        const _get = () => {
            const _data = (counter = 1) => {
                api.get(API_URL.FORMSLIST)
                    .then(res => {
                        _set(res.data.items)
                        fillInitialData({ formsList: true })
                    })
                    .catch(() => {
                        if (counter <= 3) _data(counter + 1)
                    })
            }
            /**
             * 
             * @returns {Array}
             */
            const _list = () => state.formsList || []

            return {
                _data,
                _list
            }
        }
        /**
         * 
         * @param {Array} formsList 
         */
        const _set = formsList => {
            setState(prev => ({
                ...prev,
                formsList
            }))
        }

        return {
            _get
        }
    }

    const exportedData = {
        ...state,
        handleMessage,
        fillInitialData,
        handleEducationFormsList,
        handleClassesList
    }

    return <AppContext.Provider value={exportedData} {...props} />
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider