const Table = ({ data }) => {
    const tableData = data.contents[0].value
    const handleTable = () => {
        const _get = () => {
            /**
             * Преобразува броя добавени колони в масив с празни елементи, за да моге да бъде обходен в таблицата
             * @returns {Array}
             */
            const _cols = index => {
                let arr = []
                for (let i = 0; i < _colsCount(index); i++) {
                    arr = [...arr, '']
                }

                return arr
            }

            /**
             * Връща броя добавени колони
             * @returns {Number}
             */
            const _colsCount = () => tableData.cols.count || 0

            /**
             * Преобразува броя добавени редове в масив с празни елементи, за да моге да бъде обходен в таблицата
             * @returns {Array}
             */
            const _rows = index => {
                let arr = []
                for (let i = 0; i < _rowsCount(index); i++) {
                    arr = [...arr, '']
                }

                return arr
            }

            /**
             * Връща броя добавени редове
             * @returns {Number}
             */
            const _rowsCount = () => tableData.rows.count || 0

            /**
             * Връща данните за съответната клетка в таблицата
             * @param {String} key 
             * @returns {String}
             */
            const _data = key => tableData.data[key] || ''

            return {
                _cols,
                _colsCount,
                _rows,
                _rowsCount,
                _data
            }
        }


        return {
            _get,
        }
    }

    return (
        <div className="table">
            <table cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        <th className="empty">
                            <i>
                                Консум.
                            </i>
                            <hr />
                            <i>
                                Произв.
                            </i>
                        </th>
                        {handleTable()._get()._cols().map((c, i) =>
                            <th key={`table-head-col-${i}`}>
                                B{i + 1}
                            </th>
                        )}
                        <th>
                            <div className="var">
                                a<span>i</span>
                                <i>
                                    Налич.
                                </i>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {handleTable()._get()._rows().map((_, i) =>
                        <tr key={`table-body-row-${i}`}>
                            <td>
                                A{i + 1}
                            </td>
                            {handleTable()._get()._cols().map((_, j) =>
                                <td key={`table-body-col-${i}-${j}`}>
                                    {handleTable()._get()._data(`${i}-${j}`)}
                                </td>
                            )}
                            <td>
                                {handleTable()._get()._data(`${i}-m`)}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td>
                            <div className="var">
                                b<span>j</span>
                                <i>
                                    Потреб.
                                </i>
                            </div>
                        </td>
                        {handleTable()._get()._cols().map((_, i) =>
                            <td key={`table-body-col-${i}-${i}`}>
                                {handleTable()._get()._data(`n-${i}`)}
                            </td>
                        )}
                        <td>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Table