import { useRef, useEffect } from 'react'
import { createPortal } from "react-dom"

//components
import Filter from "./Filter"
import MoveStudents from './MoveStudents'

//MUI
import { Button } from "@mui/material"

//images
import addIcon from 'assets/images/admin/teachers/icons/add.svg'

//modals
import AddEditStudent from "components/admin/modals/AddEditStudent"

//providers
import { useStudentsDataContext } from '../_providers/Students'

const Head = () => {
    const studentsData = useStudentsDataContext()
    const addEditStudentsModalRef = useRef()

    useEffect(() => {
        if (Object.keys(studentsData.edit).length) addEditStudentsModalRef.current.open()
        else addEditStudentsModalRef.current.close()
    }, [studentsData.edit])

    return (
        <div className="head">
            {createPortal(<AddEditStudent ref={addEditStudentsModalRef} />, document.body)}
            <h1>
                Ученици
            </h1>
            <Filter />
            <MoveStudents />
            <Button
                onClick={() => {
                    addEditStudentsModalRef.current.open()
                }}
                className="add"
            >
                <img src={addIcon} />
                Добави нов
            </Button>
        </div>
    )
}

export default Head