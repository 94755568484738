import { createPortal } from 'react-dom'
import { forwardRef, useImperativeHandle, useRef } from 'react'
// import { useNavigate } from 'react-router-dom'

//MUI
import { Button, Tabs, Tab } from "@mui/material"

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'
import useTabsHook from 'hooks/useTabsHook'
import useLoadingHook from 'hooks/useLoadingHook'

//images
import backIcon from 'assets/images/admin/modals/education/icons/left-arrow.svg'
import closeIcon from 'assets/images/admin/modals/education/icons/close.svg'
import forwardIcon from 'assets/images/admin/modals/education/icons/right-arrow.svg'

//components
import Name from './components/Name'
import Image from './components/Image'

//modals
import Success from './Success'

//providers
import { useEducationModalDataContext } from './_providers/EducationModal'
import { useAppContext } from 'providers/App'
import { useEducationDataContext } from 'components/teacher/_providers/Education'

//helpers
import api from 'helpers/api'
import { API_URL } from 'constants/apiUrls'

const TABS = ['Име', 'Изображение']
const AddEdit = ({ cancel }, ref) => {
    const loadingHook = useLoadingHook()
    const educationData = useEducationDataContext()
    const educationModalData = useEducationModalDataContext()
    const successModalRef = useRef()
    const app = useAppContext()

    const {
        value,
        handleTabs
    } = useTabsHook()
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()

    useImperativeHandle(ref, () => ({
        open: () => {
            handleModal()._show()
            if (value !== 0) handleTabs()._change('', 0)
            if (isEditMode()) {
                const { form_id, class_id, image_url, name } = educationData.edit

                educationModalData.handleModal()._set({
                    form_id: form_id || '',
                    class_id: class_id || null,
                    image: image_url || '',
                    name: name || ''
                })
            }
        },
        close: () => {
            handleModal()._hide()
            educationModalData.handleModal()._reset()
        }
    }))

    /**
     * 
     * @returns {Boolean}
     */
    const isLastTab = () => {
        if (value === TABS.length - 1) {
            return true
        }

        return false
    }

    const handleSubmit = e => {
        e.preventDefault()
        loadingHook.handleLoading()._start()
        const data = new FormData(e.target)

        if (isEditMode()) data.append('id', educationData.edit.id)

        api.post(API_URL.SUBJECTS[isEditMode() ? 'EDIT' : 'ADD'], data)
            .then(res => {
                const { name, id } = res.data
                if (!isEditMode()) {
                    successModalRef.current.open({
                        name,
                        id,
                    })
                } else educationData.handleEducation()._edit()._reset()
                
                educationData.handleEducation()._refresh()
                handleModal()._hide()
                educationModalData.handleModal()._reset()
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    /**
     * 0/1
     * @returns {Boolean}
     */
    const isEditMode = () => Object.keys(educationData.edit).length

    const handleCancel = () => {
        handleModal()._hide()
        educationModalData.handleModal()._reset()
        cancel()
    }

    return (
        <>
            {createPortal(<Success ref={successModalRef} />, document.body)}
            <div className={`overlay create-education ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
                <form
                    className={`modal ${modal.box ? 'show' : ''}`}
                    onSubmit={handleSubmit}
                >
                    <div className="head">
                        {value !== 0 ?
                            <Button
                                className="back"
                                onClick={handleTabs()._prevTab}
                            >
                                <img src={backIcon} />
                            </Button>
                            :
                            <div className="dummy">
                            </div>
                        }
                        <Tabs
                            value={value}
                            onChange={handleTabs()._change}
                        >
                            {TABS.map((t, i) =>
                                <Tab
                                    label={t}
                                    key={`tab-${i}`}
                                    disabled={!educationModalData.isSecondStepAvailable() && i === 1}
                                    className={!educationModalData.isSecondStepAvailable() && i === 1 ? 'disabled' : ''}
                                />
                            )}
                        </Tabs>
                        <Button
                            className="close"
                            onClick={handleCancel}
                        >
                            <img src={closeIcon} />
                        </Button>
                    </div>
                    <div className="body">
                        <Name
                            value={value}
                            isEditMode={isEditMode()}
                        />
                        <Image
                            value={value}
                            isEditMode={isEditMode()}
                        />
                    </div>
                    <div className="footer">
                        {isLastTab() ?
                            <Button
                                type="submit"
                                disabled={!educationModalData.isFinishAvaialble()}
                                className={`${loadingHook.handleLoading()._isLoading() ? 'loading' : ''} ${!educationModalData.isFinishAvaialble() ? 'disabled' : ''}`}
                            >
                                {isEditMode() ? 'Запазване' : 'Готово'}
                            </Button>
                            :
                            <Button
                                onClick={e => {
                                    e.preventDefault()
                                    handleTabs()._nextTab()
                                }}
                                disabled={!educationModalData.isSecondStepAvailable()}
                                className={!educationModalData.isSecondStepAvailable() ? 'disabled' : ''}
                            >
                                Напред
                                <img src={forwardIcon} />
                            </Button>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default forwardRef(AddEdit)