import { useLocation } from "react-router-dom"

const useActiveMenuItemHook = () => {
    const location = useLocation()

    /**
     * Отбелязва активната връзка в навигацията на хедъра, на база url адреса
     * @param {String} path 
     * @returns {Boolean}
     */
    const isActiveMenuItem = path => {
        if (path === location.pathname) {
            return true
        }

        return false
    }

    return ({
        isActiveMenuItem
    })
}

export default useActiveMenuItemHook