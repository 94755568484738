import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

//MUI
import { Skeleton } from '@mui/material'

//helpers
import api from 'helpers/api'

//components
import Item from './Item'
import NoResultsFound from 'components/app/components/NoResultsFound'

//constants
import { API_URL } from 'constants/apiUrls'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

//providers
import { useEducationDataContext } from 'components/teacher/_providers/Education'

const List = () => {
    const educationData = useEducationDataContext()
    const loadingHook = useLoadingHook()
    const location = useLocation()
    const [state, setState] = useState({
        data: []
    })
    useEffect(() => {
        getData()
    }, [educationData.refresh, location.search])

    const getData = (counter = 1) => {
        loadingHook.handleLoading()._start()
        api.get(`${API_URL.SUBJECTS.GET}${location.search}`)
            .then(res => {
                const { data } = res
                setState(prev => ({
                    ...prev,
                    data: data.items
                }))
            })
            .catch(() => {
                if (counter <= 3) getData(counter + 1)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <ul className="educations">
            {loadingHook.handleLoading()._isLoading() ?
                <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </>
                :
                state.data.length ?
                    state.data.map(d =>
                        <Item
                            key={`education-${d.id}`}
                            data={d}
                        />
                    )
                    :
                    <NoResultsFound text="Не бяха открити учебни предмети в списъка." />
            }
        </ul>
    )
}

export default List