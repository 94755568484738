import { useEffect } from 'react'
import { Link } from 'react-router-dom'

//MUI
import { Button } from '@mui/material'

//images
import studentIcon from 'assets/images/auth/login/icons/student.png'
import teacherIcon from 'assets/images/auth/login/icons/teacher.png'

//helpers
import { useQuery } from 'helpers/url'
import api from 'helpers/api'

//hooks
import useFormValidationHook from 'hooks/useFormValidationHook'
import useLoadingHook from 'hooks/useLoadingHook'

//constants
import { API_URL } from 'constants/apiUrls'

//providers
import { useAuthDataContext } from 'providers/Auth'

//components
import Brand from '../components/Brand'
import { useAppContext } from 'providers/App'

const Login = () => {
    const auth = useAuthDataContext()
    const query = useQuery()
    const validationHook = useFormValidationHook()
    const loadingHook = useLoadingHook()
    const app = useAppContext()

    /**
     * Дали формата е за ученик или не
     * @returns {Boolean}
     */
    const isStudentLogin = () => {
        if (query.get('type') === '2') {
            return false
        }

        return true
    }

    useEffect(() => {
        validationHook.handleValidations()._clear()
    }, [isStudentLogin()])

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.target)
        validationHook.handleValidations()._clear()
        loadingHook.handleLoading()._start()

        api.post(API_URL.AUTH[isStudentLogin() ? 'STUDENTS' : 'TEACHERS'].LOGIN, data)
            .then(res => {
                auth.handleAuth()._login(res.data)
            })
            .catch(() => {
                validationHook.handleValidations()._set()._all()
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <section id="login">
            <div className="wrapper">
                <form
                    className="with-inputs"
                    autoComplete="off"
                    role="presentation"
                    onSubmit={handleSubmit}
                >
                    <ul className="tabs">
                        <li className={isStudentLogin() ? 'active' : ''}>
                            <Button>
                                <Link to="/login?type=1">
                                    <img src={studentIcon} />
                                    <div className="right">
                                        <span>
                                            Вход
                                        </span>
                                        <b>
                                            За ученици
                                        </b>
                                    </div>
                                </Link>
                            </Button>
                        </li>
                        <li className={!isStudentLogin() ? 'active' : ''}>
                            <Button>
                                <Link to="/login?type=2">
                                    <img src={teacherIcon} />
                                    <div className="right">
                                        <span>
                                            Вход
                                        </span>
                                        <b>
                                            За учители
                                        </b>
                                    </div>
                                </Link>
                            </Button>
                        </li>
                    </ul>
                    <h1>
                        Здравейте отново!
                    </h1>
                    <p>
                        Моля, въведете Вашите данни за вход в системата
                    </p>
                    <div className="fields">
                        <div className="col">
                            <input
                                type="text"
                                name="email"
                                placeholder="Е-поща"
                                autoComplete="off"
                                className={validationHook.handleValidations()._get()._all() ? 'validate' : ''}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="password"
                                name="password"
                                placeholder="Парола"
                                autoComplete="off"
                                role="presentation"
                                className={validationHook.handleValidations()._get()._all() ? 'validate' : ''}
                            />
                        </div>
                        <div className="row">
                            <div className="remember">
                                <input
                                    type="checkbox"
                                    name="remember"
                                    id="remember"
                                />
                                <label htmlFor="remember">
                                    Запомни ме
                                </label>
                            </div>
                            <Link to="forgotten-password">
                                Забравена парола
                            </Link>
                        </div>
                        <Button
                            type="submit"
                            className={loadingHook.handleLoading()._isLoading() ? 'loading' : ''}
                        >
                            Вход
                        </Button>
                    </div>
                    <div className="no-profile">
                        <span>
                            <Link to="/register">Регистрация като ученик</Link>
                        </span>
                    </div>
                </form>
            </div>
            <Brand />
        </section>
    )
}

export default Login