import { forwardRef, useImperativeHandle } from 'react'

//MUI
import { Button } from '@mui/material'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'

//images
import changePasswordIcon from 'assets/images/shared/modals/icons/change-password.svg'
import api from 'helpers/api'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'
import useFormValidationHook from 'hooks/useFormValidationHook'

//providers
import { useAppContext } from 'providers/App'

const ChangePassword = ({ url }, ref) => {
    const app = useAppContext()
    const loadingHook = useLoadingHook()
    const validationHook = useFormValidationHook()
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()

    useImperativeHandle(ref, () => ({
        open: () => {
            handleModal()._show()
        },
        close: () => {
            handleModal()._hide()
        }
    }))
    
    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.target)
        loadingHook.handleLoading()._start()
        validationHook.handleValidations()._clear()

        api.post(url, data)
            .then(() => {
                handleModal()._hide()
                app.handleMessage()._success()._show('Паролата Ви беше сменена успешно!')
            })
            .catch(err => {
                const { errors } = err.response.data
                validationHook.handleValidations()._set()._fields(errors)
            })
            .finally(() => {
                loadingHook.handleLoading()._stop()
            })
    }

    return (
        <div className={`overlay change-password ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            <form
                className={`modal ${modal.box ? 'show' : ''}`}
                onSubmit={handleSubmit}
            >
                <div className="body with-inputs">
                    <img src={changePasswordIcon} className="main" />
                    <b>
                        Смяна на парола
                    </b>
                    <p>
                        Сменете своята текуща парола с нова.
                    </p>
                    <div className="col">
                        <label>
                            Текуща парола
                        </label>
                        <input
                            type="password"
                            // autoFocus
                            name="old_password"
                            className={validationHook.handleValidations()._check('old_password') ? 'validate' : ''}
                        />
                    </div>
                    <div className="col">
                        <label>
                            Нова парола
                        </label>
                        <input
                            type="password"
                            name="new_password"
                            className={validationHook.handleValidations()._check('new_password') ? 'validate' : ''}
                        />
                    </div>
                    <div className="col">
                        <label>
                            Повтори новата парола
                        </label>
                        <input
                            type="password"
                            name="new_password_confirmation"
                            className={validationHook.handleValidations()._check('new_password') ? 'validate' : ''}
                        />
                    </div>
                </div>
                <div className="footer">
                    <Button
                        onClick={handleModal()._hide}
                        className={`secondary ${loadingHook.handleLoading()._isLoading() ? 'disabled' : ''}`}
                    >
                        Отказ
                    </Button>
                    <Button
                        type="submit"
                        className={loadingHook.handleLoading()._isLoading() ? 'loading' : ''}
                    >
                        Продължи
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(ChangePassword)