//plugins
import { CKEditor } from '@ckeditor/ckeditor5-react'

//providers
import { useTopicDataContext } from "../_providers/Topic"

const Text = () => {
    const topicData = useTopicDataContext()
    const {
        handleActiveSlide,
        handleSlide
    } = topicData

    const handleChange = (_, editor) => {
        if (document.activeElement.classList.contains('ck-content')) changeAction(editor)
    }

    const changeAction = editor => {
        const { name } = editor.config._config
        const value = editor.getData() || ''
        handleSlide()._set(name, value)
    }

    return (
        <CKEditor
            editor={window.BalloonEditor}
            data={handleActiveSlide()._get()._data()?.content?.text || ''}
            config={{
                placeholder: 'Въведете текст...',
                name: 'text'
            }}
            onChange={handleChange}
            onBlur={(_, editor) => {
                changeAction(editor)
            }}
        />
    )
}

export default Text