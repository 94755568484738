/**
 * Реже стрингове и добавя многоточие след тях, ако превишават посочения брой символи
 * @param {string} text 
 * @param {number} numberOfLetters 
 * @returns {string}
 */
export const cutText = (text = '', numberOfLetters = 1) => {
    if(!text || !numberOfLetters) return
    if (text.length >= numberOfLetters) {
        return `${text.substring(0, numberOfLetters)}...`
    }

    return text
}