export const GRADES = {
    2: {
        name: 'Слаб',
    },
    3: {
        name: 'Среден',
    },
    4: {
        name: 'Добър',
    },
    5: {
        name: 'Мн. добър',
    },
    6: {
        name: 'Отличен',
    },
}