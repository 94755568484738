import { useRef, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//MUI
import { Button, Menu, MenuItem } from '@mui/material'

//images
import addIcon from 'assets/images/teachers/education/icons/add.svg'
import downArrowIcon from 'assets/images/teachers/education/icons/down-arrow.svg'

//constants
import { MENU_ITEMS } from '../education/constants/createNewMenuItems'

//hooks
import useMenuHook from 'hooks/useMenuHook'

//modals
import AddEditEducation from 'components/admin/modals/education/AddEdit'
import { createPortal } from 'react-dom'

//providers
import EducationModalDataProvider from 'components/admin/modals/education/_providers/EducationModal'
import { useEducationDataContext } from '../_providers/Education'

const CreateNew = ({ mainIndex = 0 }) => {
    const params = useParams()
    const navigate = useNavigate()
    const educationData = useEducationDataContext()
    const educationId = Number(params.id) || Number(params.educationId)
    const topicId = Number(params.topicId)
    const addEditEducationRef = useRef()
    const {
        opened,
        anchorEl,
        setAnchorEl
    } = useMenuHook()
    const defaultOption = MENU_ITEMS[mainIndex]

    const handleClickNew = e => {
        if (e.target.classList.contains('more')) setAnchorEl(e.currentTarget)
        else handleCreate(defaultOption.typeId)
    }

    const handleCloseMenu = () => {
        if (anchorEl) setAnchorEl(null)
    }

    /**
     * 
     * @param {Number} typeId 
     */
    const handleCreate = typeId => {
        handleCloseMenu()
        switch (typeId) {
            case 1:
                addEditEducationRef.current.open()
                break
            case 2:
                navigate(`/admin/topic/${educationId}/${null}`)
                break
            case 3:
                navigate(`/admin/test/${educationId}/${topicId}/${null}`)
                break
        }
    }

    useEffect(() => {
        if (Object.keys(educationData.edit).length) addEditEducationRef.current.open()
        else addEditEducationRef.current.close()
    }, [educationData.edit])

    const getMenuItems = useMemo(() => {
        return MENU_ITEMS.filter(m => !(!educationId && ((m.typeId === 3) || (m.typeId === 2))))
    }, [params])

    return (
        <>
            {createPortal(
                <EducationModalDataProvider>
                    <AddEditEducation
                        ref={addEditEducationRef}
                        cancel={educationData.handleEducation()._edit()._reset}
                    />
                </EducationModalDataProvider>
                , document.body
            )}
            <Button
                className="create-new"
                onClick={handleClickNew}
                style={getMenuItems.length === 1 ? { paddingRight: 0 } : {}}
            >
                <img src={addIcon} />
                {defaultOption.name}
                {getMenuItems.length > 1 ?
                    <span className="more">
                        <img src={downArrowIcon} />
                    </span>
                    :
                    <>
                    </>
                }
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={opened}
                onClose={handleCloseMenu}
            >
                {getMenuItems.map((m, i) =>
                    (mainIndex !== i) && <MenuItem
                        onClick={() => {
                            handleCreate(m.typeId)
                        }}
                        key={`menu-item-${i}`}
                    >
                        {m.name}
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default CreateNew