import { useRef, useState, useEffect } from 'react'
import { createPortal } from "react-dom"
import { useLocation } from 'react-router-dom'

//MUI
import { Button, Tooltip } from "@mui/material"

//providers
import { useAuthDataContext } from "providers/Auth"

//images
import changePasswordIcon from 'assets/images/students/profile/icons/change-password.svg'
import cameraIcon from 'assets/images/students/profile/icons/camera.svg'
import userIcon from 'assets/images/mobile/profile/icons/user.svg'
import certificateIcon from 'assets/images/mobile/profile/icons/certificate.svg'
import changePasswordIcon2 from 'assets/images/mobile/profile/icons/change-password.svg'
import educationsIcon from 'assets/images/mobile/profile/icons/educations.svg'
import logoutIcon from 'assets/images/mobile/profile/icons/logout.svg'

//modals
import ChangePassword from "components/shared/modals/ChangePassword"

//providers
import { useAppContext } from 'providers/App'

//helpers
import api from 'helpers/api'
import { isMobile } from 'helpers/mobile'

//constants
import { API_URL } from 'constants/apiUrls'

//hooks
import useFormValidationHook from 'hooks/useFormValidationHook'
import useDisplayUploadedImageHook from 'hooks/useDisplayUploadedImageHook'
import CertificatesList from './components/CertificatesList'
import EducationsList from './components/EducationsList'

const MOBILE_OPTIONS_KEYWORDS = {
    1: 'personal-data',
    2: 'educations',
    3: 'certificates',
}
let autoSaveTimeout
const Profile = () => {
    const location = useLocation()
    const [state, setState] = useState({
        sectionOnMobile: null
    })
    const {
        image,
        handleChange
    } = useDisplayUploadedImageHook()
    const validationHook = useFormValidationHook()
    const formRef = useRef()
    const imageFieldRef = useRef()
    const app = useAppContext()
    const changePasswordModalRef = useRef()
    const authData = useAuthDataContext()
    const { user } = authData.handleAuth()._get()._data()
    const {
        name,
        email,
        image_url,
    } = user || {}

    useEffect(() => {
        if (isMobile() && state.sectionOnMobile) setSectionOnMobile()
    }, [location])

    const handleSubmit = target => {
        const data = new FormData(target)

        api.post(API_URL.AUTH.STUDENTS.UPDATE, data)
            .then(() => {
                app.handleMessage()._success()._show('Данните Ви бяха запазени автоматично!')
            })
            .catch(err => {
                const { errors } = err.response.data
                validationHook.handleValidations()._set()._fields(errors)
                app.handleMessage()._error()._show('Данните Ви не бяха запазени. Моля, попълнете правилно всички полета!')
            })
    }

    const handleChangeField = e => {
        clearTimeout(autoSaveTimeout)
        autoSaveTimeout = setTimeout(() => {
            handleSubmit(formRef.current)
        }, 1000)

        handleChange(e)
    }

    const setSectionOnMobile = (sectionOnMobile = null) => {
        setState(prev => ({
            ...prev,
            sectionOnMobile
        }))
    }

    return (
        <section id="student-profile">
            {createPortal(
                <ChangePassword
                    ref={changePasswordModalRef}
                    url={API_URL.AUTH.STUDENTS.CHANGE_PASSWORD}
                />,
                document.body)}
            <div className="wrapper">
                {isMobile() && !state.sectionOnMobile ?
                    <div className="mobile-menu">
                        <b>
                            Моят профил
                        </b>
                        <ul>
                            <li>
                                <Button
                                    className="forward"
                                    onClick={() => {
                                        setSectionOnMobile(MOBILE_OPTIONS_KEYWORDS[1])
                                    }}
                                >
                                    <img src={userIcon} />
                                    Лични данни
                                </Button>
                            </li>
                            <li>
                                <Button
                                    onClick={() => {
                                        changePasswordModalRef.current.open()
                                    }}
                                >
                                    <img src={changePasswordIcon2} />
                                    Смяна на парола
                                </Button>
                            </li>
                            <li>
                                <Button
                                    className="forward"
                                    onClick={() => {
                                        setSectionOnMobile(MOBILE_OPTIONS_KEYWORDS[2])
                                    }}
                                >
                                    <img src={educationsIcon} />
                                    Моите обучения
                                </Button>
                            </li>
                            <li>
                                <Button
                                    className="forward"
                                    onClick={() => {
                                        setSectionOnMobile(MOBILE_OPTIONS_KEYWORDS[3])
                                    }}
                                >
                                    <img src={certificateIcon} />
                                    Моите сертификати
                                </Button>
                            </li>
                        </ul>
                        <Button
                            className="logout"
                            onClick={authData.handleAuth()._logout}
                        >
                            <img src={logoutIcon} />
                            Изход
                        </Button>
                    </div>
                    :
                    <>
                    </>
                }
                {state.sectionOnMobile || !isMobile() ?
                    <>
                        {(state.sectionOnMobile === MOBILE_OPTIONS_KEYWORDS[1]) || !isMobile() ?
                            <div className="holder">
                                <div className={`col ${state.sectionOnMobile === MOBILE_OPTIONS_KEYWORDS[1] ? 'show' : ''}`}>
                                    <b>
                                        Лични данни
                                    </b>
                                    <form
                                        className="row user-main"
                                        ref={formRef}
                                        onChange={handleChangeField}
                                    >
                                        <input
                                            type="file"
                                            hidden
                                            ref={imageFieldRef}
                                            onChange={handleChangeField}
                                            name="image"
                                        />
                                        <Tooltip title="Смяна на снимката">
                                            <picture
                                                onClick={() => {
                                                    imageFieldRef.current.click()
                                                }}
                                            >
                                                <img src={image || image_url} />
                                                <span>
                                                    <img src={cameraIcon} />
                                                </span>
                                            </picture>
                                        </Tooltip>
                                        <div className="col">
                                            <div className="field email">
                                                <input
                                                    type="text"
                                                    name="email"
                                                    defaultValue={email}
                                                    // onChange={handleChangeField}
                                                    className={validationHook.handleValidations()._check('email') ? 'validate' : ''}
                                                />
                                            </div>
                                            <div className="field name">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    defaultValue={name}
                                                    // onChange={handleChangeField}
                                                    className={validationHook.handleValidations()._check('name') ? 'validate' : ''}
                                                />
                                            </div>

                                            <div className="field class">
                                                <select
                                                    name="class_id"
                                                    onChange={handleChangeField}
                                                    defaultValue={user.class.id}
                                                    disabled
                                                >
                                                    {app.classesList.map(c =>
                                                        <option key={c.id} value={c.id}>
                                                            {c.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className={`col ${state.sectionOnMobile === MOBILE_OPTIONS_KEYWORDS[1] ? 'show' : ''}`}>
                                    <b>
                                        Настройки
                                    </b>
                                    <div className="group change-pw">
                                        <h6>
                                            Смяна на паролата
                                        </h6>
                                        <p>
                                            От тук може да смениш паролата си за достъп.
                                        </p>
                                        <Button
                                            className="btn-primary"
                                            onClick={() => {
                                                changePasswordModalRef.current.open()
                                            }}
                                        >
                                            <img src={changePasswordIcon} />
                                            Смени паролата
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                            </>
                        }
                        <div className="holder">
                            <div className={`col ${state.sectionOnMobile === MOBILE_OPTIONS_KEYWORDS[2] ? 'show' : ''}`}>
                                <b>
                                    Моите обучения
                                </b>
                                <div className="holder">
                                    <EducationsList />
                                </div>
                            </div>
                            <div className={`col ${state.sectionOnMobile === MOBILE_OPTIONS_KEYWORDS[3] ? 'show' : ''}`}>
                                <b>
                                    Моите сертификати
                                </b>
                                <div className="holder">
                                    <CertificatesList />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                    </>
                }
            </div>
        </section>
    )
}

export default Profile