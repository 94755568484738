//images
import brandImage from 'assets/images/app/brand.svg'

const InitialScreen = () => {
    return (
        <section id="initial-screen">
            <div className="wrapper">
                <picture>
                    <img src={brandImage} />
                </picture>
            </div>
        </section>
    )
}

export default InitialScreen