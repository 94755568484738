import { useRef, useEffect } from 'react'
import { createPortal } from "react-dom"

//components
import Filter from "./Filter"

//MUI
import { Button } from "@mui/material"

//images
import addIcon from 'assets/images/admin/teachers/icons/add.svg'

//modals
import AddEditStudent from "components/admin/modals/AddEditStudent"

//providers
import { useTeachersDataContext } from '../_providers/Teachers'
import AddEditTeacher from 'components/admin/modals/AddEditTeacher'

const Head = () => {
    const teachersData = useTeachersDataContext()
    const addEditTeachersModalRef = useRef()

    useEffect(() => {
        if (Object.keys(teachersData.edit).length) addEditTeachersModalRef.current.open()
        else addEditTeachersModalRef.current.close()
    }, [teachersData.edit])

    return (
        <div className="head">
            {createPortal(<AddEditTeacher ref={addEditTeachersModalRef} />, document.body)}
            <h1>
                Преподаватели
            </h1>
            <Filter />
            <Button
                className="add left-auto"
                onClick={() => {
                    addEditTeachersModalRef.current.open()
                }}
            >
                <img src={addIcon} />
                Добави нов
            </Button>
        </div>
    )
}

export default Head