import { forwardRef, useImperativeHandle, useState, useEffect } from 'react'

//hooks
import useDisplayModalHook from 'hooks/useDisplayModalHook'
import { Tooltip } from '@mui/material'

const INITIAL_STATE = {
    data: null,
    activeIndex: 0
}
const Gallery = ({ }, ref) => {
    const [state, setState] = useState(INITIAL_STATE)
    const {
        modal,
        handleModal,
    } = useDisplayModalHook()

    useImperativeHandle(ref, () => ({
        open: data => {
            handleData()._set(data)
            handleModal()._show()
        },
        close: () => {
            handleClose()
        }
    }))

    const handleClose = () => {
        handleModal()._hide()
        setState(INITIAL_STATE)
    }

    const handleData = () => {
        /**
         * 
         * @param {Any} data 
         */
        const _set = data => {
            if (data && !Array.isArray(data)) {
                data = [data]
            }

            setState(prev => ({
                ...prev,
                data
            }))
        }

        const _clear = () => {
            _set(null)
        }

        const _get = () => {
            /**
             * 
             * @returns {Array}
             */
            const _list = () => state.data || []
            /**
             * 
             * @returns {String}
             */
            const _activeImage = () => _list()[state.activeIndex]

            return {
                _list,
                _activeImage
            }
        }

        return {
            _set,
            _clear,
            _get
        }
    }

    const handleNext = () => {
        setState(prev => ({
            ...prev,
            activeIndex: handleData()._get()._list().length - 1 > Number(prev.activeIndex) ? Number(prev.activeIndex) + 1 : 0
        }))
    }

    const handlePrev = () => {
        setState(prev => ({
            ...prev,
            activeIndex: Number(prev.activeIndex) <= 0 ? handleData()._get()._list().length - 1 : Number(prev.activeIndex) - 1
        }))
    }

    return (
        <div className={`overlay gallery ${modal.overlay ? 'show' : ''}`} key={modal.overlay}>
            {handleData()._get()._list().length > 1 ?
                <>
                    <Tooltip title="Предишно изображение">
                        <span
                            className="prev"
                            onClick={handlePrev}
                        >
                        </span>
                    </Tooltip>
                    <Tooltip title="Следващо изображение">
                        <span
                            className="next"
                            onClick={handleNext}
                        >
                        </span>
                    </Tooltip>
                </>
                :
                <>
                </>
            }
            <Tooltip title="Затваряне">
                <span
                    className="close"
                    onClick={handleClose}
                >

                </span>
            </Tooltip>
            <div className={`modal ${modal.box ? 'show' : ''}`}>
                <img src={handleData()._get()._activeImage()} className="main" />
            </div>
        </div >
    )
}

export default forwardRef(Gallery)