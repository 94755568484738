import { createPortal } from 'react-dom'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

//helpers
import { cutText } from 'helpers/test'

//MUI
import { Button, Tooltip } from '@mui/material'

//images
import editIcon from 'assets/images/admin/topic/icons/edit.svg'
import gradesIcon from 'assets/images/admin/test/icons/grades.svg'
import timerIcon from 'assets/images/admin/test/icons/timer.svg'
import saveIcon from 'assets/images/admin/topic/icons/save.svg'
import closeIcon from 'assets/images/admin/topic/icons/close.svg'

//providers
import { useTestDataContext } from '../_providers/Test'

//modals
import CreateModal from 'components/admin/modals/test/Create'
import TimerModal from 'components/admin/modals/test/Timer'
import GradeModal from 'components/admin/modals/test/Grade'

//hooks
import useLoadingHook from 'hooks/useLoadingHook'

const Header = () => {
    const navigate = useNavigate()
    const createModalRef = useRef()
    const gradeModalRef = useRef()
    const testData = useTestDataContext()
    const loadingHook = useLoadingHook()
    const timerModalRef = useRef()

    const handleSave = () => {
        if (testData.handleTest()._get()._areGradesMissing()) gradeModalRef.current.open(true)
        else {
            loadingHook.handleLoading()._start()
            testData.handleTest()._save().finally(loadingHook.handleLoading()._stop)
        }
    }

    return (
        <div className="header">
            {createPortal(
                <>
                    <TimerModal ref={timerModalRef} />
                    <CreateModal ref={createModalRef} />
                    <GradeModal ref={gradeModalRef} />
                </>, document.body)}
            <div className="wrapper">
                <div className="left">
                    <h1>
                        Тест
                    </h1>
                    <p>
                        <b>
                            Тема:
                        </b>
                        {cutText(testData.topic, 30)}
                    </p>
                </div>
                <ul className="navigation">
                    <li>
                        <Button
                            className="text-out"
                            onClick={() => {
                                createModalRef.current.open()
                            }}
                        >
                            <i>
                                <img src={editIcon} />
                            </i>
                            Общи
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="text-out"
                            onClick={() => {
                                gradeModalRef.current.open()
                            }}
                        >
                            <i>
                                <img src={gradesIcon} />
                            </i>
                            Оценка
                        </Button>
                    </li>
                    <li>
                        <Button
                            className="text-out"
                            onClick={() => {
                                timerModalRef.current.open()
                            }}
                        >
                            <i>
                                <img src={timerIcon} />
                            </i>
                            Таймер
                        </Button>
                    </li>
                    <li>
                        <p>
                            Брой въпроси: {testData.handleTest()._get()._questionsCount()}
                        </p>
                        <p>
                            Общо точки: {testData.handleTest()._get()._pointsCount()}
                        </p>
                    </li>
                </ul>
                <div className="right">
                    <Button
                        className={`save ${loadingHook.handleLoading()._isLoading() ? 'loading' : ''}`}
                        onClick={handleSave}
                    >
                        <img src={saveIcon} />
                        Запази
                    </Button>
                    <Tooltip title="Затваряне на страницата">
                        <Button
                            className="close"
                            onClick={() => {
                                navigate('/')
                            }}
                        >
                            <img src={closeIcon} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default Header