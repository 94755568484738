//providers
import { useTeachersDataContext } from "../_providers/Teachers"

let filterTimeout
const Filter = () => {
    const teachersData = useTeachersDataContext()
    const {
        search
    } = teachersData.filter
    const handleChange = e => {
        const { name, value } = e.target
        clearTimeout(filterTimeout)
        filterTimeout = setTimeout(() => {
            teachersData.handleTeachers()._set()._filter(name, value)
        }, 300)
    }
    return (
        <div className="filter">
            <input
                type="text"
                placeholder="Търсене..."
                onChange={handleChange}
                name="search"
                defaultValue={search}
            />
        </div>
    )
}

export default Filter