import { createContext, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

//helpers
import api from "helpers/api"
import { useQuery } from "helpers/url"

//constants
import { API_URL } from "constants/apiUrls"

//hooks
import useSetParamsHook from "hooks/useSetParamsHook"

export const EducationDataContext = createContext({})

const EducationDataProvider = props => {
    const navigate = useNavigate()
    const query = useQuery()
    const useSetParams = useSetParamsHook()

    const [state, setState] = useState({
        filter: {
            form_id: query.get('form_id') || '',
            class_id: query.get('class_id') || '',
        },
        refresh: null,
        remove: {},
        edit: {},
        topic: {
            remove: {},
            edit: {}
        },
        test: {
            remove: {}
        }
    })

    useEffect(() => {
        useSetParams.apply(state.filter)
    }, [state.filter, state.page])

    /**
     * Всички операции към обучението
     * @returns {Object}
     */
    const handleEducation = () => {
        const _set = () => {
            const _filter = (name, value) => {
                setState(prev => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        [name]: value
                    }
                }))
            }

            const _clearFilter = () => {
                setState(prev => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        form_id: '',
                        class_id: '',
                    }
                }))
            }

            return {
                _filter,
                _clearFilter
            }
        }

        const _edit = () => {
            /**
             * Данните на конкретно обучение
             * @param {Object} edit 
             */
            const _prepare = edit => {
                setState(prev => ({
                    ...prev,
                    edit
                }))
            }

            const _reset = () => {
                _prepare({})
            }

            return {
                _prepare,
                _reset
            }
        }

        const _remove = () => {
            /**
             * Данните на конкретно обучение
             * @param {Object} remove 
             */
            const _prepare = remove => {
                setState(prev => ({
                    ...prev,
                    remove
                }))
            }

            const _process = async () => {
                const { id } = state.remove
                await api.post(API_URL.SUBJECTS.DELETE, {
                    id
                })
                    .then(() => {
                        _refresh()
                    })
            }

            const _reset = () => {
                _prepare({})
            }

            return {
                _prepare,
                _process,
                _reset
            }
        }

        const _refresh = () => {
            setState(prev => ({
                ...prev,
                refresh: new Date().getTime()
            }))
        }

        return {
            _edit,
            _remove,
            _refresh,
            _set
        }
    }

    /**
     * Всички операции към темите
     * @returns {Object}
     */
    const handleTopic = () => {
        const _edit = (educationId, topicId) => {
            navigate(`/admin/topic/${educationId}/${topicId}`)
        }

        const _remove = () => {
            const _prepare = remove => {
                setState(prev => ({
                    ...prev,
                    topic: {
                        ...prev.topic,
                        remove
                    }
                }))
            }

            const _process = async () => {
                const { id } = state.topic.remove
                await api.post(API_URL.TOPICS.DELETE, {
                    id
                })
                    .then(() => {
                        handleEducation()._refresh()
                    })
            }

            const _reset = () => {
                _prepare({})
            }

            return {
                _prepare,
                _remove,
                _process,
                _reset
            }
        }

        const _addSubtopic = () => {
            alert('topic add subtopic')
        }

        const _addTest = (educationId, topicId = null) => {
            navigate(`/admin/test/${educationId}/${topicId}/${null}`)
        }

        return {
            _edit,
            _remove,
            _addSubtopic,
            _addTest
        }
    }

    /**
     * Всички операции към тестовете
     * @returns {Object}
    */
    const handleTest = () => {
        const _edit = () => {
            alert('test edit')
        }

        const _remove = () => {
            const _prepare = remove => {
                setState(prev => ({
                    ...prev,
                    test: {
                        ...prev.test,
                        remove
                    }
                }))
            }

            const _process = async () => {
                const { id } = state.test.remove
                await api.post(API_URL.TESTS.DELETE, {
                    id
                })
                    .then(() => {
                        handleEducation()._refresh()
                    })
            }

            const _reset = () => {
                _prepare({})
            }

            return {
                _prepare,
                _remove,
                _process,
                _reset
            }
        }

        return {
            _edit,
            _remove,
        }
    }

    const exportedData = {
        ...state,
        handleEducation,
        handleTopic,
        handleTest
    }

    return <EducationDataContext.Provider value={exportedData} {...props} />
}

export const useEducationDataContext = () => useContext(EducationDataContext)

export default EducationDataProvider