//components
import Item from "./Item"

const List = ({ data }) => {
    const { items } = data

    return (
        <ul>
            {(items || []).map(i =>
                <Item
                    key={`topic-${i.id}`}
                    data={i}
                />
            )}
        </ul>
    )
}

export default List