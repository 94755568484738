import { useState } from 'react'

const useMenuHook = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const opened = Boolean(anchorEl)

    return ({
        opened,
        anchorEl,
        setAnchorEl
    })
}

export default useMenuHook