import { useLocation, useNavigate } from "react-router-dom"
const useSetParamsHook = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const apply = paramsObj => {
        let params = '?'
        let hasAnyValues = false
        const keys = Object.keys(paramsObj)
        keys.map((f, i) => {
            const values = Object.values(paramsObj)
            params += `${f}=${values[i]}${keys.length > i + 1 ? '&' : ''}`
            if (values[i].length) {
                hasAnyValues = true
            }
        })

        if (!hasAnyValues) {
            params = ''
        }

        navigate(`${location.pathname}${params}`)
    }
    return {
        apply
    }
}

export default useSetParamsHook