//components
import Item from "./Item"

const List = ({ data }) => {
    return (
        <ul>
            {(data || []).map(d =>
                <Item
                    key={`topic-${d.id}`}
                    data={d}
                />
            )}
        </ul>
    )
}

export default List