//MUI
import { Menu, MenuItem } from '@mui/material'

//providers
import { useEducationDataContext } from '../_providers/Education'

//hooks
import useMenuHook from 'hooks/useMenuHook'

//images
import editIcon from 'assets/images/teachers/education/icons/edit.svg'
import topicIcon from 'assets/images/teachers/education/icons/topic.svg'
import testIcon from 'assets/images/teachers/education/icons/test.svg'
import deleteIcon from 'assets/images/teachers/education/icons/delete.svg'

const TopicMenu = ({ data }) => {
    const educationData = useEducationDataContext()
    const topicController = educationData.handleTopic()
    const {
        opened,
        anchorEl,
        setAnchorEl
    } = useMenuHook()

    const handleOpenMenu = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        if (anchorEl) setAnchorEl(null)
    }

    const handleEdit = () => {
        topicController._edit(data.subject_id, data.id)
        handleCloseMenu()
    }
    const handleDelete = () => {
        topicController._remove()._prepare(data)
        handleCloseMenu()
    }

    const handleAddTest = () => {
        topicController._addTest(data.subject_id, data.id)
        handleCloseMenu()
    }

    const handleAddTopic = () => {
        topicController._addSubtopic()
        handleCloseMenu()
    }

    return (
        <>
            <span
                className="education-menu"
                onClick={handleOpenMenu}
            >

            </span>
            <Menu
                anchorEl={anchorEl}
                open={opened}
                onClose={handleCloseMenu}
                className="education-menu"
            >
                <MenuItem
                    onClick={handleEdit}
                >
                    <img src={editIcon} />
                    Редакция
                </MenuItem>
                {/* <MenuItem
                    onClick={handleAddTopic}
                >
                    <img src={topicIcon} />
                    Добави подтема
                </MenuItem> */}
                <MenuItem
                    onClick={handleAddTest}
                >
                    <img src={testIcon} />
                    Добави тест към темата
                </MenuItem>
                <hr />
                <MenuItem
                    onClick={handleDelete}
                    className="delete"
                >
                    <img src={deleteIcon} />
                    Изтриване на темата
                </MenuItem>
            </Menu>
        </>
    )
}

export default TopicMenu