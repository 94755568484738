//MUI
import { Button, Tooltip } from '@mui/material'

//providers
import { useTopicDataContext } from "../_providers/Topic"

//constants
import { TOPIC_TEMPLATES } from 'constants/topicTemplates'

//images
import viewIcon from 'assets/images/admin/topic/icons/view.svg'
import duplicateIcon from 'assets/images/admin/topic/icons/duplicate-dark.svg'
import deleteIcon from 'assets/images/admin/topic/icons/delete.svg'

//components
import Heading from './Heading'
import Text from './Text'
import Gallery from './Gallery'

const Main = () => {
    const topicData = useTopicDataContext()
    const {
        handleActiveSlide,
        isSlideDeletable,
        handleActiveTemplate,
        handleSlide,
    } = topicData

    /**
     * Връща всички шаблони като масив
     * @returns {Array}
     */
    const getTopicTemplats = () => Object.values(TOPIC_TEMPLATES)

    const getTemplate = () => {
        switch (handleActiveTemplate()._get().type) {
            case 'heading-text':
                return <>
                    <div className="heading">
                        <Heading />
                    </div>
                    <div className="text">
                        <Text />
                    </div>
                </>

            case 'heading-images-text':
                return <>
                    <div className="heading">
                        <Heading />
                    </div>
                    <Gallery />
                    <div className="text">
                        <Text />
                    </div>
                </>

            case 'heading-image-text':
                return <>
                    <Gallery single={true} />
                    <div className="holder">
                        <div className="heading">
                            <Heading />
                        </div>
                        <div className="text">
                            <Text />
                        </div>
                    </div>
                </>

            case 'images-text':
                return <>
                    <Gallery />
                    <div className="text">
                        <Text />
                    </div>
                </>

            case 'text':
                return <>
                    <div className="text">
                        <Text />
                    </div>
                </>
        }
    }

    return (
        <div className="main">
            <div className="options">
                <div className="left">
                    <p>
                        Шаблони на слайда
                    </p>
                    <ul className="templates">
                        {getTopicTemplats().map((t, i) =>
                            <li key={`template-mini-${i}`}>
                                <Tooltip title={t.description}>
                                    <Button
                                        className={handleActiveTemplate()._get()?.type === t?.type ? 'active' : ''}
                                        onClick={() => {
                                            handleActiveTemplate()._set(Object.keys(TOPIC_TEMPLATES)[i])
                                        }}
                                    >
                                        <img src={t.icon} />
                                    </Button>
                                </Tooltip>
                            </li>
                        )}
                    </ul>
                </div>
                <ul className="right">
                    {/* <li>
                        <Tooltip title="Бърз преглед">
                            <Button>
                                <img src={viewIcon} />
                            </Button>
                        </Tooltip>
                    </li> */}
                    <li>
                        <Tooltip title="Дублиране на слайда">
                            <Button
                                onClick={() => {
                                    handleSlide()._duplicate(handleActiveSlide()._get()._index())
                                }}
                            >
                                <img src={duplicateIcon} />
                            </Button>
                        </Tooltip>
                    </li>
                    {isSlideDeletable() ?
                        <li>
                            <Tooltip title="Премахване на слайда">
                                <Button
                                    className="delete"
                                    onClick={() => {
                                        handleSlide()._delete(handleActiveSlide()._get()._index())
                                    }}
                                >
                                    <img src={deleteIcon} />
                                </Button>
                            </Tooltip>
                        </li>
                        :
                        <>
                        </>
                    }
                </ul>
            </div>
            <div className={`content ${handleActiveTemplate()._get().type}`}>
                {getTemplate()}
            </div>
        </div>
    )
}

export default Main