import { createContext, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

//helpers
import api from "helpers/api"

//constants
import { API_URL } from "constants/apiUrls"

export const StudentsEducationContext = createContext({})

const StudentsEducationProvider = props => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        education: {
            data: [],
            selectedId: localStorage.getItem('selectedId') || null
        }
    })

    useEffect(() => {
        if (!handleEducation()._get()._count()) getEducation()
    }, [])

    const handleEducation = () => {
        /**
         * 
         * @param {Array} data 
         */
        const _set = () => {
            const _data = data => {
                setState(prev => ({
                    ...prev,
                    education: {
                        ...prev.education,
                        data,
                        selectedId: _get()._selectedId() || data[0]?.id || null
                    }
                }))
            }

            const _selectedId = (selectedId = state.education.data[0].id || null) => {
                setState(prev => ({
                    ...prev,
                    education: {
                        ...prev.education,
                        selectedId
                    }
                }))
            }

            return {
                _data,
                _selectedId
            }
        }


        const _get = () => {
            /**
             * 
             * @returns {Array}
             */
            const _all = () => state.education.data || []

            /**
             * 
             * @returns {Number}
             */
            const _count = () => _all().length || 0

            const _selectedId = () => state.education.selectedId || null

            return {
                _all,
                _count,
                _selectedId
            }
        }

        return {
            _set,
            _get
        }
    }

    const getEducation = () => {
        api.get(API_URL.STUDENTS.SUBJECTS)
            .then(res => {
                handleEducation()._set()._data(res.data.items)
            })
    }

    const exportedData = {
        ...state,
        handleEducation
    }

    return <StudentsEducationContext.Provider value={exportedData} {...props} />
}

export const useStudentsEducationContext = () => useContext(StudentsEducationContext)

export default StudentsEducationProvider