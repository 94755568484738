import { useRef, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

//MUI
import { Button, Tooltip, Skeleton } from '@mui/material'

//images
import backIcon from 'assets/images/students/test/icons/left-arrow.svg'
import forwardIcon from 'assets/images/students/topic/icons/right-arrow.svg'
import testImage from 'assets/images/students/topic/test.svg'

//hooks
import useTopicHook from './hooks/useTopicHook'

//components
import Timeline from '../../../shared/Timeline'


//helpers
import { getSlideType } from 'helpers/slide'

//providers
import { useAuthDataContext } from 'providers/Auth'
import { createPortal } from 'react-dom'
import GalleryModal from './modals/Gallery'

let pos = { top: 0, left: 0, x: 0, y: 0 }
const Topic = () => {
    const authData = useAuthDataContext()
    const navigationRef = useRef()
    const galleryModalRef = useRef()
    const params = useParams()
    const navigate = useNavigate()
    const { id } = params

    const {
        state,
        setActiveSlide,
        isActiveSlide,
        getData,
        getTopic,
        handleLocalyReadyIds
    } = useTopicHook()

    useEffect(() => {
        const slidesNavigation = document.querySelector('.navigation')
        const slidesLi = slidesNavigation?.querySelectorAll('.navigation ul li') || []
        if (slidesLi[0]) {
            slidesLi[state.activeSlideIndex].scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
                block: 'start'
            })
        }


    }, [state.activeSlideIndex])

    /**
     * 
     * @returns {Boolean}
     */
    const isNextDisabled = () => {
        if (getData().length - 1 === state.activeSlideIndex) {
            return true
        }

        return false
    }

    /**
     * 
     * @returns {Boolean}
     */
    const isPrevDisabled = () => {
        if (state.activeSlideIndex === 0) {
            return true
        }

        return false
    }

    const mouseDownHandler = function (e) {
        const el = navigationRef.current

        pos = {
            left: el.scrollLeft,
            top: el.scrollTop,

            x: e.clientX,
            y: e.clientY,
        }

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)

        el.style.cursor = 'grabbing'
        el.style.userSelect = 'none'
    }

    const mouseMoveHandler = function (e) {
        const el = navigationRef.current

        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y

        el.scrollTop = pos.top - dy
        el.scrollLeft = pos.left - dx
    }

    const mouseUpHandler = function () {
        const el = navigationRef.current

        document.removeEventListener('mousemove', mouseMoveHandler)
        document.removeEventListener('mouseup', mouseUpHandler)

        el.style.cursor = 'grab'
        el.style.removeProperty('user-select')
    }

    /**
     * Дали списъка със слайдове има скрол или не
     * @returns {Boolean}
     */
    const navigationHasScroll = () => {
        const el = navigationRef.current
        if (el?.querySelector('ul').offsetWidth >= el?.offsetWidth) {
            return true
        }

        return false
    }

    const getHeading = (contents = []) => {
        return contents.find(c => c.content_type_id === 1)?.value || ''
    }
    const getText = (contents = []) => {
        return contents.find(c => c.content_type_id === 2)?.value || ''
    }
    const getImages = (contents = []) => {
        return contents.find(c => c.content_type_id === 3)?.value || []
    }

    /**
     * 
     * @param {String} heading 
     * @param {String} text 
     * @returns 
     */
    const calcTimeToRead = (heading = '', text = '') => (heading.length + text.length) / 30

    return (
        !getData().length ?
            <div className="full-screen">
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton className="big" />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
            :
            <section id="topic">
                {createPortal(<GalleryModal ref={galleryModalRef} />, document.body)}
                <div className="header">
                    <div className="wrapper">
                        <div className="left">
                            <Tooltip title="Назад">
                                <Button
                                    variant="text"
                                    className="back"
                                    onClick={() => {
                                        navigate('/')
                                    }}
                                >
                                    <img src={backIcon} />
                                </Button>
                            </Tooltip>
                            <div className="heading">
                                Тема {getTopic().no}
                            </div>
                            <p>
                                {getTopic().name}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="navigation-holder">
                    <div
                        className={`navigation ${navigationHasScroll() ? 'grab' : ''}`}
                        onMouseDownCapture={e => {
                            if (navigationHasScroll()) mouseDownHandler(e)
                        }}
                        ref={navigationRef}
                    >
                        <ul>
                            {getData().map((slide, i) =>
                                <li
                                    key={`slide-nav-${slide.id}-${i}`}
                                    className={isActiveSlide(i) ? 'active' : ''}
                                    onClick={() => {
                                        setActiveSlide(i)
                                    }}
                                >
                                    <span style={(slide.ready || handleLocalyReadyIds()._get().includes(slide.id)) && !isActiveSlide(i) ? { color: '#3bc870' } : {}}>
                                        {slide.typeId === 'end' ? slide.name : i + 1}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="main">
                    <div className="wrapper">
                        <Button
                            className={`changer prev ${isPrevDisabled() ? 'disabled' : ''}`}
                            onClick={() => {
                                if (!isPrevDisabled()) setActiveSlide(state.activeSlideIndex - 1)
                            }}
                        >

                        </Button>
                        <ul className="container">
                            {getData().map((slide, i) =>
                                <li
                                    className={`${getSlideType(slide.template_id)} ${isActiveSlide(i) ? 'active' : ''}`}
                                    key={`slide-main-${slide.id}-${i}`}
                                >
                                    {slide.template_id === 1 ?
                                        <>
                                            <h1 className="child" dangerouslySetInnerHTML={{ __html: getHeading(slide.contents) }} />
                                            <p className="child" dangerouslySetInnerHTML={{ __html: getText(slide.contents) }} />
                                        </>
                                        :
                                        slide.template_id === 2 ?
                                            <>
                                                <h1 className="child" dangerouslySetInnerHTML={{ __html: getHeading(slide.contents) }} />
                                                <ul className="gallery">
                                                    {getImages(slide.contents).map((image, i) =>
                                                        <li key={`image-${i}`}>
                                                            <img
                                                                src={image}
                                                                onClick={() => {
                                                                    galleryModalRef.current.open(getImages(slide.contents))
                                                                }}
                                                            />
                                                        </li>
                                                    )}
                                                </ul>
                                                <p className="child" dangerouslySetInnerHTML={{ __html: getText(slide.contents) }} />
                                            </>
                                            :
                                            slide.template_id === 3 ?
                                                <>
                                                    <img
                                                        src={getImages(slide.contents)[0]}
                                                        onClick={() => {
                                                            galleryModalRef.current.open(getImages(slide.contents)[0])
                                                        }}
                                                    />
                                                    <div className="holder">
                                                        <h1 className="child" dangerouslySetInnerHTML={{ __html: getHeading(slide.contents) }} />
                                                        <p className="child" dangerouslySetInnerHTML={{ __html: getText(slide.contents) }} />
                                                    </div>
                                                </>
                                                :
                                                slide.template_id === 4 ?
                                                    <>
                                                        <ul className="gallery">
                                                            {getImages(slide.contents).map((image, i) =>
                                                                <li key={`image-${i}`}>
                                                                    <img
                                                                        src={image}
                                                                        onClick={() => {
                                                                            galleryModalRef.current.open(getImages(slide.contents))
                                                                        }}
                                                                    />
                                                                </li>
                                                            )}
                                                        </ul>
                                                        <p className="child" dangerouslySetInnerHTML={{ __html: getText(slide.contents) }} />
                                                    </>
                                                    :
                                                    slide.template_id === 5 ?
                                                        <p className="child" dangerouslySetInnerHTML={{ __html: getText(slide.contents) }} />
                                                        :
                                                        slide.typeId === 'end' ?
                                                            <div className="last-slide">
                                                                <img src={testImage} />
                                                                <h1 className="child">
                                                                    Премини към тестовете<br /> за проверка на знанията
                                                                </h1>
                                                                <p className="child">
                                                                    След успешното завършване на темата е време<br /> да провериш и затвърдиш знанията си
                                                                </p>
                                                                <Button>
                                                                    <Link to={`/${!authData.handleAuth()._get()._isStudent() ? 'education/' : ''}tests/${getTopic().subject_id}/${id}`}>
                                                                        Към тестовете
                                                                        <img src={forwardIcon} />
                                                                    </Link>
                                                                </Button>
                                                            </div>
                                                            :
                                                            <>
                                                            </>
                                    }
                                    {i === state.activeSlideIndex ?
                                        <Timeline
                                            totalTimeInSeconds={calcTimeToRead(getHeading(slide?.contents), getText(slide?.contents))}
                                            id={slide.id}
                                            ready={slide.ready || handleLocalyReadyIds()._get().includes(slide.id)}
                                            setLocalyReadyIds={handleLocalyReadyIds()._set}
                                        />
                                        :
                                        <>
                                        </>
                                    }
                                </li>
                            )}
                        </ul>
                        <Button
                            className={`changer next ${isNextDisabled() ? 'disabled' : ''}`}
                            onClick={() => {
                                if (!isNextDisabled()) setActiveSlide(state.activeSlideIndex + 1)
                            }}
                        >

                        </Button>
                    </div>
                </div>
            </section>
    )
}

export default Topic