//providers
import { useTestDataContext } from "../_providers/Test"

const MiniTable = ({ index }) => {
    const testData = useTestDataContext()
    const {
        handleTable
    } = testData

    const hasThreeOrMoreCols = () => handleTable()._get()._cols(index).length >= 3
    const hasThreeOrMoreRows = () => handleTable()._get()._rows(index).length >= 3

    return (
        handleTable()._get()._cols(index).length ?
            <table cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th>

                        </th>
                        {!hasThreeOrMoreCols() ?
                            handleTable()._get()._cols(index).map((_, i) =>
                                i <= 1 ?
                                    <th key={`table-head-col-${i}`}>
                                        {`B${i + 1}`}
                                    </th>
                                    :
                                    ''
                            )
                            :
                            <>
                                <th>
                                    B1
                                </th>
                                <th>
                                    ...
                                </th>
                                <th>
                                    B{handleTable()._get()._cols(index).length}
                                </th>
                            </>
                        }
                        <th>
                            <div className="var">
                                a<span>i</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasThreeOrMoreRows() ?
                        <>
                            <tr>
                                <td>
                                    A1
                                </td>
                                {handleTable()._get()._cols(index).map((_, i) =>
                                    i <= 2 ?
                                        <td key={`table-head-row-${i}-1`}>
                                            {handleTable()._get()._data(`0-${i}`, index)}
                                        </td>
                                        :
                                        ''
                                )}
                                <td>
                                    {handleTable()._get()._data(`0-m`, index)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        .
                                    </div>
                                    <div>
                                        .
                                    </div>
                                    <div>
                                        .
                                    </div>
                                </td>
                                {handleTable()._get()._cols(index).map((_, i) =>
                                    i < 3 ?
                                        <td key={`table-head-col-${i}-2`}>
                                            <div>
                                                .
                                            </div>
                                            <div>
                                                .
                                            </div>
                                            <div>
                                                .
                                            </div>
                                        </td>
                                        :
                                        ''
                                )}
                                <td>
                                    <div>
                                        .
                                    </div>
                                    <div>
                                        .
                                    </div>
                                    <div>
                                        .
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    A{handleTable()._get()._rows(index).length}
                                </td>
                                {handleTable()._get()._cols(index).map((_, i) =>
                                    i < 3 ?
                                        <td key={`table-head-col-${i}-3`}>
                                            {handleTable()._get()._data(`${handleTable()._get()._rows(index).length - 1}-${i}`, index)}
                                        </td>
                                        :
                                        ''
                                )}
                                <td>
                                    {handleTable()._get()._data(`${handleTable()._get()._rows(index).length - 1}-m`, index)}
                                </td>
                            </tr>
                        </>
                        :
                        handleTable()._get()._rows(index).map((_, i) =>
                            <tr key={`table-head-row-${i}-main`}>
                                <td>
                                    A{i + 1}
                                </td>
                                {handleTable()._get()._cols(index).map((_, j) =>
                                    j < 3 ?
                                        <td key={`table-head-col-${j}-4`}>
                                            {handleTable()._get()._data(`${i}-${j}`, index)}
                                        </td>
                                        :
                                        ''
                                )}
                                <td>
                                    {handleTable()._get()._data(`${i}-m`, index)}
                                </td>
                            </tr>
                        )
                    }
                    <tr>
                        <td>
                            <div className="var">
                                b<span>j</span>
                            </div>
                        </td>
                        {!hasThreeOrMoreCols() ?
                            handleTable()._get()._cols(index).map((_, i) =>
                                i <= 1 ?
                                    <td key={`table-head-col-${i}`}>
                                        {handleTable()._get()._data(`n-${i}`, index)}
                                    </td>
                                    :
                                    ''
                            )
                            :
                            <>
                                <td>
                                    {handleTable()._get()._data(`n-0`, index)}
                                </td>
                                <td>
                                    ...
                                </td>
                                <td>
                                    {handleTable()._get()._data(`n-${handleTable()._get()._cols(index).length - 1}`, index)}
                                </td>
                            </>
                        }
                        <td>

                        </td>
                    </tr>
                </tbody>
            </table >
            :
            <>
            </>
    )
}

export default MiniTable