import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

//providers
import { useAuthDataContext } from 'providers/Auth'

const useQuestionsAndAnswersHook = areResultsAvailable => {
    const authData = useAuthDataContext()
    const params = useParams()
    const userId = authData.handleAuth()._get()._data()?.user.id || null
    const tag = `test-${params.id}-${userId}`

    /**
     * 
     * @returns {Object|Null}
     */
    const getParsedLocalStoraget = () => JSON.parse(localStorage.getItem(tag))

    const removeTestFromLocalStoraget = () => {
        localStorage.removeItem(tag)
    }

    const initialState = getParsedLocalStoraget() || {
        activeQuestionIndex: 0,
        answered: {}
    }
    const [state, setState] = useState(initialState)
    useEffect(() => {
        if (!areResultsAvailable) localStorage.setItem(tag, JSON.stringify(state))
    }, [state])
    /**
     * задава активния въпрос
     * @param {Number} activeQuestionIndex 
     */
    const setActiveQuestion = activeQuestionIndex => {
        if (Number(activeQuestionIndex) === Number(state.activeQuestionIndex)) return

        setState(prev => ({
            ...prev,
            activeQuestionIndex
        }))
    }

    /**
     * 
     * @param {Number} index 
     * @returns {Boolean}
     */
    const isActiveQuestion = index => {
        if (Number(index) === Number(state.activeQuestionIndex)) {
            return true
        }

        return false
    }

    /**
     * Маркира/отмаркира отговори в теста
     * @param {Number} questionId 
     * @param {Number} answerId 
     * @param {Boolean} isCorrect 
     */
    const handleAnswer = (questionId, answerId, isCorrect) => {
        if (state.answered[questionId]?.answers[answerId]) {
            if (Object.values(state.answered[questionId]?.answers).length === 1) {
                //когато се отмаркира последния въпрос да изтрива цялата информация, която е записана повреме на маркирането на въпроса
                delete state.answered[questionId]
            } else {
                //да изтрива само информацията за маркирания отговор
                delete state.answered[questionId]?.answers[answerId]
            }

            setState(prev => ({
                ...prev
            }))
        } else {
            setState(prev => ({
                ...prev,
                answered: {
                    ...prev.answered,
                    [questionId]: {
                        ...prev.answered[questionId],
                        answers: {
                            ...prev.answered[questionId]?.answers,
                            [answerId]: isCorrect ? '1' : '0'
                        },
                    }
                }
            }))
        }
    }

    /**
     * Връща информация за всеки въпрос
     * @param {Number} questionId 
     * @param {Number} correctCount 
     * @returns {Object}
     */
    const getAnswered = (questionId, correctCount) => {
        let status = false
        const currentQuestion = state.answered[questionId]
        const answerKeys = Object.keys(currentQuestion?.answers || [])
        const answerValues = Object.values(currentQuestion?.answers || [])

        if (answerValues.length >= correctCount && !answerValues.includes('false')) {
            status = true
        }

        return {
            status,
            answerKeys
        }
    }

    /**
    * Дали въпроса е отговорен
    * @param {Number} questionId 
    * @param {Number} answerId 
    * @param {Number} correctCount 
    * @returns {Boolean}
    */
    const isAnswered = (questionId, answerId, isCorrect) => getAnswered(questionId, answerId, isCorrect).answerKeys.includes(String(answerId))

    /**
     * Връща отговорените въпроси във вид готов за изпращане към backend
     * @returns {Object}
     */
    const getFormatedQuestions = () => {
        const answered = getParsedLocalStoraget()?.answered || {}
        const questionIds = Object.keys(answered)
        let data = {}
        questionIds.map(q => {
            data = {
                ...data,
                [q]: Object.keys(answered[q].answers) || []
            }
        })

        return data
    }

    return ({
        state,
        setActiveQuestion,
        isActiveQuestion,
        handleAnswer,
        getAnswered,
        isAnswered,
        getFormatedQuestions,
        getParsedLocalStoraget,
        removeTestFromLocalStoraget
    })
}

export default useQuestionsAndAnswersHook